import { Divider, SxProps } from '@mui/material';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DeviceConfigDTO } from '../../../../../api/api';
import { ReactComponent as AlertIcon } from '../../../../../assets/icons/alert-icon.svg';
import { ReactComponent as CleaningIcon } from '../../../../../assets/icons/cleaning-icon.svg';
import { ReactComponent as TimeIcon } from '../../../../../assets/icons/time-icon.svg';
import { localized } from '../../../../../i18n/i18n';
import { setDeviceConfig } from '../../../../../state/features/device-setup-wizard/device-setup-wizard-slice';
import { AppState } from '../../../../../state/store';
import { sideBarDividerColor } from '../../../../../styles/color-constants';
import { LargeSwitch } from '../../large-switch';
import { LargeTextField } from '../../large-text-field';
import { SideBarStack } from '../../side-bar-stack';
import { GeneralUnitConfigRow } from '../unit-config/general-unit-config-row';
import { ConnectAndAddNewUnitStepTargets } from '../../../../../utilities/enums/tutorial-steps-target';

const width100: SxProps = {
  width: '100px',
};

export const UnitSettingsStep: FC = React.memo(() => {
  const { device } = useSelector((store: AppState) => store.deviceSetupWizardReducer);
  const deviceConfig = useMemo(() => device?.deviceConfig ?? ({} as DeviceConfigDTO), [device]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setDeviceConfig({
        id: deviceConfig.id ?? 0,
        prolongedUseAlertSound: true,
        usagesBetweenCleaning: 1,
        prolongedUseAlertTimeoutMinutes: 60,
      }),
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleUsageTimesChange = useCallback(
    (event) => {
      deviceConfig.usagesBetweenCleaning = +event.currentTarget.value;
      dispatch(setDeviceConfig(deviceConfig));
    },
    [deviceConfig, dispatch],
  );

  const handleProlongedUseAlertTimeoutChange = useCallback(
    (event) => {
      deviceConfig.prolongedUseAlertTimeoutMinutes = +event.currentTarget.value;
      dispatch(setDeviceConfig(deviceConfig));
    },
    [deviceConfig, dispatch],
  );

  const handleAlertToggle = useCallback(
    (_, checked: boolean) => {
      const localConfig = { ...deviceConfig };
      localConfig.prolongedUseAlertSound = checked;
      dispatch(setDeviceConfig(localConfig));
    },
    [deviceConfig, dispatch],
  );

  return (
    <>
      <SideBarStack>
        <GeneralUnitConfigRow
          icon={<CleaningIcon />}
          description={localized('CleaningTimesDescription')}
          input={
            <LargeTextField
              name={'usageTimes'}
              sx={width100}
              label={localized('Times')}
              onBlur={handleUsageTimesChange}
              defaultValue={1}
              type={'number'}
              inputProps={{ min: 1 }}
            />
          }
          id={ConnectAndAddNewUnitStepTargets.Step8}
        />
        <GeneralUnitConfigRow
          icon={<TimeIcon />}
          description={localized('ProlongedUseTimeoutDescription')}
          input={
            <LargeTextField
              name={'prolongedUseAlertTimeout'}
              sx={width100}
              label={localized('Minutes')}
              onBlur={handleProlongedUseAlertTimeoutChange}
              defaultValue={60}
              type={'number'}
              inputProps={{ min: 30 }}
            />
          }
          id={ConnectAndAddNewUnitStepTargets.Step9}
        />
        <GeneralUnitConfigRow
          icon={<AlertIcon />}
          description={localized('ProlongedUseToggleDescription')}
          input={<LargeSwitch onChange={handleAlertToggle} defaultChecked />}
          id={ConnectAndAddNewUnitStepTargets.Step10}
        />
      </SideBarStack>
      <Divider sx={{ backgroundColor: sideBarDividerColor, marginY: '20px' }} />
    </>
  );
});
