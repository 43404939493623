import React, { FC } from 'react';
import { localized } from '../../../i18n/i18n';
import { PageHeader } from '../../components/default-components/page-header.tsx/page-header';
import { PageTitle } from '../../components/default-components/page-title/page-title';
import { MainPageContainer } from '../../components/specific-components/containers/main-page-container';
import { RightSideBar } from '../../components/specific-components/right-side-bar';
import { FaqList, IFaqList } from '../../components/specific-components/table-list/faq/faq-list';

const faqs: IFaqList[] = [
  {
    topic: '',
    faqs: [
      {
        question: localized('FAQ1Question'),
        answer: localized('FAQ1Answer'),
      },
      {
        question: localized('FAQ2Question'),
        answer: localized('FAQ2Answer'),
      },
      {
        question: localized('FAQ3Question'),
        answer: localized('FAQ3Answer'),
      },
      {
        question: localized('FAQ4Question'),
        answer: localized('FAQ4Answer'),
      },
      {
        question: localized('FAQ5Question'),
        answer: localized('FAQ5Answer'),
      },
      {
        question: localized('FAQ6Question'),
        answer: localized('FAQ6Answer'),
      },
      {
        question: localized('FAQ7Question'),
        answer: localized('FAQ7Answer'),
      },
      {
        question: localized('FAQ8Question'),
        answer: localized('FAQ8Answer'),
      },
      {
        question: localized('FAQ9Question'),
        answer: localized('FAQ9Answer'),
      },
      {
        question: localized('FAQ10Question'),
        answer: localized('FAQ10Answer'),
      },
      {
        question: localized('FAQ11Question'),
        answer: localized('FAQ11Answer'),
      },
      {
        question: localized('FAQ12Question'),
        answer: localized('FAQ12Answer'),
      },
      {
        question: localized('FAQ13Question'),
        answer: localized('FAQ13Answer'),
      },
      {
        question: localized('FAQ14Question'),
        answer: localized('FAQ14Answer'),
      },
      {
        question: localized('FAQ15Question'),
        answer: localized('FAQ15Answer'),
      },
    ],
  },
];

export const HelpFAQ: FC = React.memo(() => {
  return (
    <RightSideBar>
      <PageTitle title={localized('FrequentlyAskedQuestions')}>
        <PageHeader area="helpFAQ" title={localized('FrequentlyAskedQuestions')}></PageHeader>
        <MainPageContainer>
          {faqs.map((faq, key) => (
            <FaqList faqList={faq} key={key} />
          ))}
        </MainPageContainer>
      </PageTitle>
    </RightSideBar>
  );
});
