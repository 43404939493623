import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { ReactComponent as AdministratorIcon } from '../../../assets/icons/administrator-icon.svg';
import { ReactComponent as MaintenanceIcon } from '../../../assets/icons/maintenance-icon.svg';
import { ReactComponent as ManagerIcon } from '../../../assets/icons/manager-icon.svg';
import { useAuth } from '../../../auth/auth-provider';
import { localized } from '../../../i18n/i18n';
import { getOrganizations } from '../../../state/features/organization/operation';
import { selectOrganization } from '../../../state/features/settings/settings-slice';
import { getUsers } from '../../../state/features/users/operation';
import { routes } from '../../../state/routes';
import { FlexRow } from '../../components/default-components/flex-row';
import { PageHeader } from '../../components/default-components/page-header.tsx/page-header';
import { PageTitle } from '../../components/default-components/page-title/page-title';
import { BasicSpinner } from '../../components/default-components/spinner/basic-spinner';
import { LargeButton } from '../../components/specific-components/buttons/large-button';
import { MainPageContainer } from '../../components/specific-components/containers/main-page-container';
import { RightSideBar } from '../../components/specific-components/right-side-bar';
import { useTutorial } from '../../../utilities/providers/tutorial-provider';
import { Box } from '@mui/material';
import { AddAndManageUsersStepTargets } from '../../../utilities/enums/tutorial-steps-target';

const iconHeight: number = 50;
const iconWidth: number = 80;
export const userTableMaxHeight = 'calc(100vh - 500px)';

export const Users = React.memo(() => {
  const { isAdmin, isManager, loading } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    setTutorialState,
    tutorialState: { tourActive, stepIndex },
  } = useTutorial();
  const selectedOrganization = useSelector(selectOrganization);
  const locationBaseString = location.pathname.split('/')[2];

  useEffect(() => {
    if (locationBaseString === undefined) {
      if (isAdmin) {
        navigate(routes.usersAdministrators, { replace: true });
      } else if (isManager) {
        navigate(routes.usersManagers, { replace: true });
      }
    }
  }, [locationBaseString, isAdmin, isManager, navigate]);

  useEffect(() => {
    if (!tourActive) return;

    let continueStatus = true;
    if (location.pathname !== routes.usersMaintenanceStaff) {
      continueStatus = isAdmin;
    }

    setTutorialState({
      stepIndex: 1,
      buttonStatus: {
        showBack: false,
        showContinue: continueStatus,
      },
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdmin, setTutorialState, tourActive, location.pathname]);

  useEffect(() => {
    dispatch(getOrganizations());
    if (selectedOrganization) {
      dispatch(getUsers(selectedOrganization.id));
    }
  }, [dispatch, selectedOrganization]);

  if (loading) {
    return <BasicSpinner size={100} />;
  }

  return (
    <RightSideBar>
      <PageTitle title={localized('Users')}>
        <PageHeader area="users" title={localized('Users')}></PageHeader>
        <MainPageContainer>
          <FlexRow marginTop={'30px'} marginBottom={'25px'}>
            <Box display="flex" id={AddAndManageUsersStepTargets.Step2}>
              {isAdmin && (
                <LargeButton
                  linkTo={routes.usersAdministrators}
                  focus={locationBaseString === routes.usersAdministrators.split('/')[2]}
                  icon={<AdministratorIcon height={iconHeight} width={iconWidth} />}
                  text={localized('Administrators')}
                />
              )}
              <LargeButton
                linkTo={routes.usersManagers}
                focus={locationBaseString === routes.usersManagers.split('/')[2]}
                icon={<ManagerIcon height={iconHeight} width={iconWidth} />}
                text={localized('Management')}
              />
              <LargeButton
                linkTo={routes.usersMaintenanceStaff}
                focus={locationBaseString === routes.usersMaintenanceStaff.split('/')[2]}
                icon={<MaintenanceIcon height={iconHeight} width={iconWidth} />}
                text={localized('Maintenance')}
              />
            </Box>
          </FlexRow>
          <Outlet />
        </MainPageContainer>
      </PageTitle>
    </RightSideBar>
  );
});
