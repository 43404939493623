import { Box, SxProps, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ILocationDTO } from '../../../api/api';
import { localized } from '../../../i18n/i18n';
import { getDevicesFull } from '../../../state/features/devices/operation';
import { selectLocations } from '../../../state/features/locations/locations-slice';
import { getLocationsByOrganizationId } from '../../../state/features/locations/operations';
import { getOrganizations } from '../../../state/features/organization/operation';
import { selectOrganization } from '../../../state/features/settings/settings-slice';
import { primaryTextColor } from '../../../styles/color-constants';
import { theme } from '../../../styles/theme';
import { DownloadReportStepTargets } from '../../../utilities/enums/tutorial-steps-target';
import { locationToString } from '../../../utilities/helpers/location-helper';
import { isDateValid } from '../../../utilities/platform-helpers/date-helpers';
import { useTutorial } from '../../../utilities/providers/tutorial-provider';
import { PageHeader } from '../../components/default-components/page-header.tsx/page-header';
import { PageTitle } from '../../components/default-components/page-title/page-title';
import { MainPageContainer } from '../../components/specific-components/containers/main-page-container';
import { DateRangePicker } from '../../components/specific-components/date-picker/date-range-picker';
import { DownloadReportButton } from '../../components/specific-components/insights/download-report-button';
import { BasicLocationSelect } from '../../components/specific-components/locations/basic-location-select';
import { RightSideBar } from '../../components/specific-components/right-side-bar';
import { DownloadCompleteReportButton } from '../../components/specific-components/insights/download-complete-report-button';

const styles: { [key: string]: SxProps } = {
  select: {
    width: '700px',
  },
  spinnerOnButton: {
    color: primaryTextColor,
  },
  metaText: {
    fontSize: theme.typography.body1,
  },
  container: {
    width: '700px',
    display: 'flex',
    gap: '20px',
  },
};

export const Insights = React.memo(() => {
  const dispatch = useDispatch();
  const selectedOrganization = useSelector(selectOrganization);
  const {
    setTutorialState,
    tutorialState: { tourActive, stepIndex },
  } = useTutorial();
  const locations = useSelector(selectLocations);
  const [toDate, setToDate] = useState<Date | undefined>(undefined);
  const [fromDate, setFromDate] = useState<Date | undefined>(undefined);
  const [selectedLocation, setSelectedLocation] = useState<ILocationDTO>();
  const [locationIdMap, setLocationIdMap] = useState<Map<string, number> | undefined>(undefined);

  useEffect(() => {
    if (!tourActive) return;
    setTimeout(() => {
      setTutorialState({ stepIndex: 1 });
    }, 200);
  }, [setTutorialState, tourActive]);

  useEffect(() => {
    if (selectedOrganization) {
      dispatch(getLocationsByOrganizationId(selectedOrganization.id));
    }
  }, [selectedOrganization, dispatch]);

  useEffect(() => {
    dispatch(getOrganizations());
    if (selectedOrganization) {
      dispatch(getDevicesFull(selectedOrganization.id));
    }
  }, [dispatch, selectedOrganization]);

  // Create relation between roomId and locations
  useEffect(() => {
    const ids = new Map<string, number>();
    locations.forEach((location) => {
      ids.set(locationToString(location), location.id);
    });
    setLocationIdMap(ids);
  }, [locations]);

  const handleSelectedLocation = useCallback(
    (location: ILocationDTO) => {
      setSelectedLocation(location);
      if (!tourActive) return;

      setTimeout(() => {
        setTutorialState({ stepIndex: 3 });
      }, 500);
    },
    [setTutorialState, tourActive],
  );

  const dateFromChanged = useCallback(
    (event: Date) => {
      setFromDate(event);
      if (!tourActive && !isDateValid(event)) return;

      setTimeout(() => {
        setTutorialState({ stepIndex: 5 });
      }, 300);
    },
    [setTutorialState, tourActive],
  );

  const dateToChanged = useCallback(
    (event: Date) => {
      setToDate(event);
      if (!tourActive && !isDateValid(event)) return;
      setTimeout(() => {
        setTutorialState({ stepIndex: 7 });
      }, 300);
    },
    [setTutorialState, tourActive],
  );

  const disabled = useMemo(
    () => !(selectedLocation !== undefined && fromDate !== undefined && toDate !== undefined),
    [selectedLocation, fromDate, toDate],
  );

  const onSelectorOpen = useCallback(() => {
    if (!tourActive) return;
    switch (stepIndex) {
      case 1:
        setTimeout(() => {
          setTutorialState({ stepIndex: 2 });
        }, 500);
        break;
      case 3:
        setTimeout(() => {
          setTutorialState({ stepIndex: 4 });
        }, 300);
        break;
      case 5:
        setTimeout(() => {
          setTutorialState({ stepIndex: 6 });
        }, 300);
        break;
    }
  }, [setTutorialState, stepIndex, tourActive]);

  const onDateSelectorClose = useCallback(() => {
    if (!tourActive) return;
    switch (stepIndex) {
      case 2:
        setTimeout(() => {
          setTutorialState({ stepIndex: 3 });
        }, 500);
        break;
      case 4:
        setTimeout(() => {
          setTutorialState({ stepIndex: 5 });
        }, 300);
        break;
      case 6:
        setTimeout(() => {
          setTutorialState({ stepIndex: 7 });
        }, 300);
        break;
    }
  }, [setTutorialState, stepIndex, tourActive]);

  return (
    <RightSideBar>
      <PageTitle title={localized('Insights')}>
        <PageHeader area="insights" title={localized('Insights')}></PageHeader>
        <MainPageContainer>
          <Typography sx={styles.metaText} whiteSpace={'pre-line'} marginBottom={1}>
            {localized('MetaTextInsights')}
          </Typography>
          <BasicLocationSelect
            id={DownloadReportStepTargets.Step2}
            name={localized('Location')}
            setActiveLocation={handleSelectedLocation}
            onOpen={onSelectorOpen}
            holdSelectedLocation={true}
          />
          <DateRangePicker
            dateFrom={fromDate!}
            dateFromChanged={dateFromChanged}
            onDateFromOpen={onSelectorOpen}
            onDateFromClose={onDateSelectorClose}
            dateTo={toDate!}
            dateToChanged={dateToChanged}
            onDateToOpen={onSelectorOpen}
            onDateToClose={onDateSelectorClose}
            fromDateId={DownloadReportStepTargets.Step4}
            toDateId={DownloadReportStepTargets.Step6}
          />
          <Box sx={styles.container}>
            <DownloadReportButton
              disabled={disabled}
              locationId={locationIdMap?.get(locationToString(selectedLocation))}
              fromDate={fromDate}
              toDate={toDate}
              id={DownloadReportStepTargets.Step8}
            />
            <DownloadCompleteReportButton fromDate={fromDate} toDate={toDate} />
          </Box>
        </MainPageContainer>
      </PageTitle>
    </RightSideBar>
  );
});
