import { SxProps, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IDeviceInfoDTO } from '../../../api/api';
import { useAuth } from '../../../auth/auth-provider';
import { localized } from '../../../i18n/i18n';
import { selectDevices } from '../../../state/features/devices/devices-slice';
import { getDevicesFull } from '../../../state/features/devices/operation';
import { getLocationsByOrganizationId } from '../../../state/features/locations/operations';
import { getOrganizations } from '../../../state/features/organization/operation';
import { selectOrganization } from '../../../state/features/settings/settings-slice';
import { AppState } from '../../../state/store';
import { theme } from '../../../styles/theme';
import { searchOption, searchOptionFilter } from '../../../utilities/helpers/device-helper';
import { PageHeader } from '../../components/default-components/page-header.tsx/page-header';
import { PageTitle } from '../../components/default-components/page-title/page-title';
import { AddNewButton } from '../../components/specific-components/buttons/add-new-button';
import { MainPageContainer } from '../../components/specific-components/containers/main-page-container';
import { useRightSidebar } from '../../components/specific-components/right-side-bar';
import { HeadCell, TableList } from '../../components/specific-components/table-list/table-list';
import { UnitTableRow } from '../../components/specific-components/table-list/units/units-table-row';
import { SetupWizard } from '../../components/specific-components/units/setup-wizard/setup-wizard';
import { useTutorial } from '../../../utilities/providers/tutorial-provider';
import { ConnectAndAddNewUnitStepTargets } from '../../../utilities/enums/tutorial-steps-target';

const styles: { [key: string]: SxProps } = {
  searchField: {
    marginLeft: '12px',
    width: '245px',
  },
  searchFieldRow: {
    marginTop: '10px',
  },
  metaText: {
    fontSize: theme.typography.body1,
  },
};

const headCells: HeadCell[] = [
  {
    id: 'connectId',
    label: localized('ConnectID'),
    align: 'left',
  },
  {
    id: 'category',
    label: localized('Category'),
    align: 'left',
  },
  {
    id: 'model',
    label: localized('Model'),
    align: 'left',
  },
  {
    id: 'location',
    label: localized('Location'),
    align: 'left',
  },
  {
    id: 'online',
    label: localized('Online'),
    align: 'right',
    width: '20px',
  },
  {
    id: 'settings',
    label: localized('Settings'),
    align: 'right',
    width: '20px',
  },
  {
    id: 'delete',
    label: localized('Delete'),
    align: 'right',
    width: '20px',
  },
];

const unitTableMaxHeight = 'calc(100vh - 250px)';

export const Units = React.memo(() => {
  const { isAdmin } = useAuth();
  const setSidebarContent = useRightSidebar();
  const dispatch = useDispatch();
  const {
    setTutorialState,
    tutorialState: { tourActive },
  } = useTutorial();
  const devices = useSelector(selectDevices);
  const selectedOrganization = useSelector(selectOrganization);
  const showSpinner = useSelector(
    (store: AppState) => store.devicesReducer.pending && store.devicesReducer.devices.length < 1,
  );
  const [searchText, setSearchText] = useState('');
  const [searchOption, setSearchOption] = useState<searchOption>('ConnectId');
  const [onlineChecked, setOnlineChecked] = useState(false);
  const [offlineChecked, setOfflineChecked] = useState(false);

  useEffect(() => {
    if (!tourActive) return;
    
    setTimeout(() => {
      setTutorialState({ stepIndex: 1 });
    }, 100);
  }, [setTutorialState, tourActive]);

  useEffect(() => {
    let intervalId: NodeJS.Timer;
    dispatch(getOrganizations());

    if (selectedOrganization) {
      dispatch(getDevicesFull(selectedOrganization.id));
      dispatch(getLocationsByOrganizationId(selectedOrganization.id));
      intervalId = setInterval(() => dispatch(getDevicesFull(selectedOrganization.id)), 30 * 1000);
    }

    return () => clearInterval(intervalId);
  }, [dispatch, selectedOrganization]);

  const addNewUnit = useCallback(() => {
    setSidebarContent(<SetupWizard />);
  }, [setSidebarContent]);

  // Gradually filters devices based on the different options
  const filterDevices = useCallback(() => {
    let tempDevices: IDeviceInfoDTO[] = [];
    if (devices.length !== 0) {
      tempDevices = devices.filter((d) => d.configured && d.locationDTO?.id !== null);
      // Always remove un-configured devices
      // Filter based on search string and option
      tempDevices = searchOptionFilter(tempDevices, searchText, searchOption);
      // Filters based on status
      if (onlineChecked) {
        tempDevices = tempDevices.filter((d) => d.deviceState?.isConnected);
      } else if (offlineChecked) {
        tempDevices = tempDevices.filter((d) => !d.deviceState?.isConnected);
      }
    }
    return tempDevices;
  }, [devices, searchOption, searchText, onlineChecked, offlineChecked]);

  return (
    <PageTitle title={localized('Units')}>
      <PageHeader area="units" title={localized('Units')} />
      <MainPageContainer>
        <Typography sx={styles.metaText} whiteSpace={'pre-line'} marginBottom={1}>
          {localized('MetaTextUnits')}
        </Typography>
        {/* Hidden as per requested in PBI 33829 */}
        {/* <FlexRow sx={styles.searchFieldRow}>
          <Typography fontSize={'12px'} sx={styles.searchField}>
            {localized('SearchFor')}
          </Typography>
          <Typography fontSize={'12px'}>{localized('SearchWithin')}</Typography>
        </FlexRow>
        <UnitsSearchBar
          searchText={searchText}
          setSearchText={setSearchText}
          searchOption={searchOption}
          setSearchOption={setSearchOption}
          onlineChecked={onlineChecked}
          setOnlineChecked={setOnlineChecked}
          offlineChecked={offlineChecked}
          setOfflineChecked={setOfflineChecked}
        /> */}
        {isAdmin && (
          <AddNewButton onClick={addNewUnit} id={ConnectAndAddNewUnitStepTargets.Step2}>
            {localized('AddUnit')}
          </AddNewButton>
        )}
        <TableList headCells={headCells} showSpinner={showSpinner} maxHeight={unitTableMaxHeight}>
          {filterDevices().map((device, i) => (
            <UnitTableRow
              device={device}
              key={device.id}
              id={i === 0 ? ConnectAndAddNewUnitStepTargets.Step12 : undefined}
            />
          ))}
        </TableList>
      </MainPageContainer>
    </PageTitle>
  );
});
