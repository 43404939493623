import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  INotificationSubscriptionDTO,
  NotificationSubscriptionClient,
  NotificationSubscriptionDTO,
} from '../../../api/api';
import { AsyncOperationHandler } from '../../../utilities/platform-helpers/api-helper';
import { notificationSubscriptionClient } from '../../api-clients';

export const getNotificationSubscriptionsByOrganization = createAsyncThunk(
  'notificationSubscriptions/getFromOrganization',
  (organizationId: number) => {
    return AsyncOperationHandler(
      (client) => (client as NotificationSubscriptionClient).getFromOrganization(organizationId),
      notificationSubscriptionClient,
    );
  },
);

export const updateNotificationSubscription = createAsyncThunk(
  'notificationSubscription/update',
  (notificationSubscription: INotificationSubscriptionDTO) => {
    return AsyncOperationHandler(
      (client) =>
        (client as NotificationSubscriptionClient).update(notificationSubscription as NotificationSubscriptionDTO),
      notificationSubscriptionClient,
      true,
    );
  },
);
