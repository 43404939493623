import { Loader } from '@googlemaps/js-api-loader';
import React, { FC, RefObject, createContext, useContext, useEffect, useRef } from 'react';
import { AutocompleteService, PlacesService } from './google-types';

const google_maps_key = 'AIzaSyA83Q1Ym04hT4V2o4IACeUq6gsDDTIyeeY';

const loader = new Loader({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? '',
  libraries: ['places'],
});

interface GoogleMapsState {
  autocompleteService: RefObject<undefined | AutocompleteService>;
  placesService: RefObject<undefined | PlacesService>;
}

const initialState: GoogleMapsState = {
  autocompleteService: { current: undefined },
  placesService: { current: undefined },
};

const GoogleMapsContext = createContext(initialState);
export const useGoogleMaps = (): GoogleMapsState => useContext(GoogleMapsContext);

export const GoogleMapsProvider: FC = ({ children }) => {
  const autocompleteService = useRef<undefined | AutocompleteService>();
  const placesService = useRef<undefined | PlacesService>();

  useEffect(() => {
    loader.load().then((google) => {
      // Import the places library
      google.maps.importLibrary('places');
      // Init the Autocomplete service
      autocompleteService.current = new google.maps.places.AutocompleteService();

      // Init the Places Service which require a map element to init. So we create an empty div which serves as its mapElement
      const mapElement = document.getElementById('map');
      if (mapElement) {
        const map = new google.maps.Map(mapElement);
        placesService.current = new google.maps.places.PlacesService(map);
      }
    });
  }, []);

  return (
    <GoogleMapsContext.Provider value={{ placesService, autocompleteService }}>
      {children}
      {/* Map element used in the places service */}
      <div id="map" style={{ display: 'none' }}></div>
    </GoogleMapsContext.Provider>
  );
};
