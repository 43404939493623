import { Button, SxProps } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import React, { FC, useCallback, useState } from 'react';
import { ReactComponent as DeviceLogIcon } from '../../../../../assets/icons/device-log-icon.svg';
import { localized } from '../../../../../i18n/i18n';
import { useDownloadFile } from '../../../../../services/download-helper';
import { deviceDebuggingClient } from '../../../../../state/api-clients';
import { buttonColor } from '../../../../../styles/color-constants';

interface Props {
  connectId: string;
  disabled: boolean;
}

const buttonStyle: SxProps = {
  textTransform: 'none',
  fontSize: '20px',
  color: buttonColor,
  width: '180px',
};

export const DownloadLogButton: FC<Props> = React.memo(({ connectId, disabled }) => {
  const [loading, setLoading] = useState(false);

  const preDownloading = useCallback(() => {
    setLoading(true);
  }, []);

  const postDownloading = useCallback(() => {
    setLoading(false);
  }, []);

  const onError = useCallback(() => {
    setLoading(false);
    // Snackbar?
  }, []);

  const apiDefinition = useCallback(() => {
    return deviceDebuggingClient.getDeviceDebugLog(connectId);
  }, [connectId]);

  const { download } = useDownloadFile({
    apiDefinition,
    preDownloading,
    postDownloading,
    onError,
  });

  const handleDownload = useCallback(() => {
    download();
  }, [download]);

  return (
    <Button
      startIcon={loading ? <CircularProgress color={'inherit'} /> : <DeviceLogIcon />}
      sx={buttonStyle}
      onClick={handleDownload}>
      {localized('DownloadDeviceLog')}
    </Button>
  );
});
