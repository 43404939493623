import {KeyboardArrowDown} from '@mui/icons-material';
import {FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, SxProps, Theme} from '@mui/material';
import React, {FC, memo, ReactNode, useCallback, useMemo} from 'react';
import {theme} from '../../../../styles/theme';

export class DropdownOption {
  label: string;
  value: any;
  constructor(label: string, value: any) {
    this.label = label;
    this.value = value;
  }
}

interface Props {
  options: DropdownOption[];
  onChange: (item: DropdownOption) => void;
  value: DropdownOption | null | undefined;
  label?: string;
  sx?: SxProps<Theme>;
  isInvalid?: boolean;
  labelSx?: SxProps<Theme>;
}

export const BasicDropdown: FC<Props> = memo((props) => {
  const mapOptionsToMenuItem = useMemo(() => {
    return props.options.map((option) => (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    ));
  }, [props.options]);

  const onChangeLocal = useCallback(
    (event: SelectChangeEvent<number>, newValue: ReactNode) => {
      const option = props.options.find((option) => option.value === event.target.value);
      if (option) {
        props.onChange(option);
      }
    },
    [props],
  );

  return (
    <FormControl sx={{margin: theme.spacing(1), marginBottom: theme.spacing(1)}}>
      <InputLabel
        shrink={true}
        sx={{
          backgroundColor: theme.palette.common.white,
          paddingRight: theme.spacing(1),
          paddingLeft: theme.spacing(1),
          color: theme.palette.text.primary,
          marginLeft: '-6px',
          ...props.labelSx,
        }}>
        {props.label}
      </InputLabel>
      <Select sx={props.sx} value={props.value?.value ?? 0} onChange={onChangeLocal} IconComponent={KeyboardArrowDown}>
        {mapOptionsToMenuItem}
      </Select>
    </FormControl>
  );
});
