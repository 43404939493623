import React, {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Organization} from '../../../api/api';
import {localized} from '../../../i18n/i18n';
import {getOrganizations} from '../../../state/features/organization/operation';
import {
  selectOrganisations,
  selectOrganiszationDropdownOptions,
} from '../../../state/features/organization/organization-slice';
import {
  selectOrganizationSettingDropdown,
  setOrganizationSetting,
} from '../../../state/features/settings/settings-slice';
import {BasicDropdown, DropdownOption} from '../../components/default-components/basic-dropdown/basic-dropdown';
import {PageHeader} from '../../components/default-components/page-header.tsx/page-header';
import {PageTitle} from '../../components/default-components/page-title/page-title';
import {MainPageContainer} from '../../components/specific-components/containers/main-page-container';
import {RightSideBar} from '../../components/specific-components/right-side-bar';

export const Settings = React.memo(() => {
  const dispatch = useDispatch();
  const organizationOptions = useSelector(selectOrganiszationDropdownOptions);
  const selectedOrganizationOption = useSelector(selectOrganizationSettingDropdown);
  const organizations = useSelector(selectOrganisations);

  useEffect(() => {
    dispatch(getOrganizations());
  }, [dispatch]);

  const onOrganizationChange = useCallback(
    (option: DropdownOption) => {
      const org = organizations.find((org) => org.id === option.value);
      dispatch(setOrganizationSetting(org as Organization));
    },
    [dispatch, organizations],
  );

  //TODO: Style this
  return (
    <RightSideBar>
      <PageTitle title={localized('Organizations')}>
        <PageHeader area="settings" title={localized('Organizations')}></PageHeader>
        <MainPageContainer>
          <BasicDropdown
            options={organizationOptions}
            label={localized('Organization')}
            value={selectedOrganizationOption}
            onChange={onOrganizationChange}
          />
        </MainPageContainer>
      </PageTitle>
    </RightSideBar>
  );
});
