import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IDeviceCategory } from '../../../../../api/api';
import { ReactComponent as ChangeTableIcon } from '../../../../../assets/icons/change-table-icon.svg';
import { ReactComponent as ShowerIcon } from '../../../../../assets/icons/shower-icon.svg';
import { ReactComponent as SinkIcon } from '../../../../../assets/icons/sink-icon.svg';
import { ReactComponent as SoapDispenserIcon } from '../../../../../assets/icons/soap-dispenser-icon.svg';
import { ReactComponent as ToiletSeatIcon } from '../../../../../assets/icons/toilet-seat-icon.svg';
import { localizedDynamic } from '../../../../../i18n/i18n';
import { selectDeviceCategories } from '../../../../../state/features/device-categories/device-category-slice';
import { getDeviceCategories } from '../../../../../state/features/device-categories/operation';
import { setDeviceCategory } from '../../../../../state/features/device-setup-wizard/device-setup-wizard-slice';
import { FlexColumn } from '../../../default-components/flex-column';
import { SelectionButton } from '../../buttons/selection-button';
import { useTutorial } from '../../../../../utilities/providers/tutorial-provider';

export const ProductCategorySelect: FC = React.memo(() => {
  const dispatch = useDispatch();
  const {
    setTutorialState,
    tutorialState: { tourActive },
  } = useTutorial();
  const deviceCategories = useSelector(selectDeviceCategories);
  const iconDictionary: { [key: string]: JSX.Element } = useMemo(() => {
    return {
      'DeviceCategory.Shower&ChangingTables': <ChangeTableIcon />,
      'DeviceCategory.ToiletLifters': <ToiletSeatIcon />,
      'DeviceCategory.Sinks': <SinkIcon />,
      'DeviceCategory.Shower': <ShowerIcon />,
      'DeviceCategory.SoapDispensers': <SoapDispenserIcon />,
    };
  }, []);

  useEffect(() => {
    dispatch(getDeviceCategories());
  }, [dispatch]);

  const handleClick = useCallback(
    (deviceCategory: IDeviceCategory) => () => {
      dispatch(setDeviceCategory(deviceCategory));
      if (!tourActive) return;
      
      setTimeout(() => {
        setTutorialState({ stepIndex: 4 });
      }, 200);
    },
    [dispatch, setTutorialState, tourActive],
  );

  return (
    <FlexColumn sx={{ marginBottom: 4 }}>
      {deviceCategories.map((deviceCategory) => {
        if (deviceCategory.nameTranslationKey) {
          return (
            <SelectionButton
              icon={iconDictionary[deviceCategory.nameTranslationKey]}
              onClick={handleClick(deviceCategory)}
              key={deviceCategory.id}
              disabled={deviceCategory.nameTranslationKey !== 'DeviceCategory.Shower&ChangingTables'}>
              {localizedDynamic(deviceCategory.nameTranslationKey ?? '')}
            </SelectionButton>
          );
        }
      })}
    </FlexColumn>
  );
});
