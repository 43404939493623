import * as React from 'react';
import { FC, memo, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { setPostLoginRedirectUri } from '../utilities/platform-helpers/auth-helper';
import { tokenStorageKey } from './auth-constants';
import { useAuth } from './auth-provider';

export const PrivateRoute: FC = memo(() => {
  const { isAuthenticated, loading, user, login, tokenExpiredHandling } = useAuth();
  const location = useLocation();

  // Renew token if expired on navigation
  useEffect(() => {
    if (!loading && user) {
      tokenExpiredHandling(tokenStorageKey);
    }
    // location is here to make sure this runs every time location changes
  }, [location, loading, user, tokenExpiredHandling]);

  // Redirect to login screen if user is trying to access private route without being signed in
  useEffect(() => {
    if (loading || isAuthenticated) {
      return;
    }

    setPostLoginRedirectUri();
    login();
  }, [loading, isAuthenticated, login]);

  return <Outlet />;
});
