import {
  DeviceCategoryClient,
  DeviceClient,
  DeviceConfigClient,
  DeviceDebuggingClient,
  DeviceModelClient,
  FirmwareClient,
  LocationClient,
  NotificationSubscriptionClient,
  OrganizationClient,
  ReportClient,
  TimeZoneClient,
  UserClient,
} from '../api/api';
import { ApiBaseUrl } from '../utilities/constants';

export const userClient = new UserClient(ApiBaseUrl);

export const organizationClient = new OrganizationClient(ApiBaseUrl);

export const locationClient = new LocationClient(ApiBaseUrl);

export const deviceClient = new DeviceClient(ApiBaseUrl);

export const deviceConfigClient = new DeviceConfigClient(ApiBaseUrl);

export const deviceDebuggingClient = new DeviceDebuggingClient(ApiBaseUrl);

export const deviceCategoryClient = new DeviceCategoryClient(ApiBaseUrl);

export const deviceModelClient = new DeviceModelClient(ApiBaseUrl);

export const notificationSubscriptionClient = new NotificationSubscriptionClient(ApiBaseUrl);

export const reportClient = new ReportClient(ApiBaseUrl);

export const timeZoneClient = new TimeZoneClient(ApiBaseUrl);

export const firmwareClient = new FirmwareClient(ApiBaseUrl);

export type ApiClient =
  | UserClient
  | OrganizationClient
  | LocationClient
  | DeviceClient
  | DeviceConfigClient
  | DeviceDebuggingClient
  | DeviceCategoryClient
  | DeviceModelClient
  | NotificationSubscriptionClient
  | TimeZoneClient
  | ReportClient
  | FirmwareClient;
