import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { unstable_BlockerFunction as BlockerFunction } from 'react-router-dom';
import { OrganizationRole } from '../../../../api/api';
import { localized } from '../../../../i18n/i18n';
import { selectNotificationSubscriptions } from '../../../../state/features/notification-subscriptions/notification-subscriptions-slice';
import { getNotificationSubscriptionsByOrganization } from '../../../../state/features/notification-subscriptions/operation';
import { selectOrganization } from '../../../../state/features/settings/settings-slice';
import { selectUsers } from '../../../../state/features/users/users-slice';
import { routes } from '../../../../state/routes';
import { AppState } from '../../../../state/store';
import { NavigationBlocker } from '../../../components/navigation-blocker';
import { useRightSidebar } from '../../../components/specific-components/right-side-bar';
import { WorkersNotificationTableRow } from '../../../components/specific-components/table-list/notifications/for-users/workers-notification-table-row';
import { HeadCell, TableList } from '../../../components/specific-components/table-list/table-list';
import { notificationTableMaxHeight } from '../notifications';
import { useTutorial } from '../../../../utilities/providers/tutorial-provider';

export const userNotificationHeadCells: HeadCell[] = [
  {
    id: 'name',
    label: localized('Name'),
    align: 'left',
    width: '230px',
  },
  {
    id: 'serviceArea',
    label: localized('ServiceArea'),
    align: 'left',
    width: '100px',
    borderRight: true,
  },
  {
    id: 'locations',
    label: localized('Locations'),
    align: 'center',
    width: '60px',
    borderRight: true,
  },
  {
    id: 'notificationType',
    label: localized('NotificationType'),
    align: 'left',
    width: '270px',
    borderRight: true,
  },
  {
    id: 'acute',
    label: localized('ImmediateCleaning'),
    align: 'center',
    width: '50px',
  },
  {
    id: 'prolongedUse',
    label: localized('ProlongedUse'),
    align: 'center',
    width: '50px',
  },
  {
    id: 'overload',
    label: localized('Overload'),
    align: 'center',
    width: '50px',
  },
  {
    id: 'impact',
    label: localized('Impact'),
    align: 'center',
    width: '50px',
    borderRight: true,
  },
  {
    id: 'report',
    label: localized('Reports'),
    align: 'center',
    width: '50px',
  },
];

interface Props {
  role: OrganizationRole;
}

export const NotificationsUsersOptions: FC<Props> = React.memo((props) => {
  const dispatch = useDispatch();
  const setSidebarContent = useRightSidebar();
  const {
    setTutorialState,
    tutorialState: { tourActive },
  } = useTutorial();
  const users = useSelector(selectUsers);
  const notificationSubscriptions = useSelector(selectNotificationSubscriptions);
  const selectedOrganization = useSelector(selectOrganization);
  const showSpinner = useSelector(
    (store: AppState) =>
      store.usersReducer.pending &&
      store.usersReducer.users.length < 1 &&
      store.notificationSubscriptionsReducer.pending,
  );

  useEffect(() => {
    // Get if any updates were made on location count
    if (selectedOrganization?.id) {
      dispatch(getNotificationSubscriptionsByOrganization(selectedOrganization.id));
    }

    return () => setSidebarContent(null);
  }, [props.role, setSidebarContent, selectedOrganization, dispatch]);

  const filteredUsers = useMemo(
    () =>
      users.filter((user) => {
        if (selectedOrganization) {
          return (
            user.organizationUser?.find((oU) => oU.organizationId === selectedOrganization.id)?.organizationRole ===
            props.role
          );
        }
      }),
    [props.role, selectedOrganization, users],
  );

  const unfinishedUsers = useMemo(
    () =>
      filteredUsers
        .filter((user) => {
          const notificationSubscription = notificationSubscriptions.find((ns) => ns.userId === user.id);
          // No rooms assigned is the default state and should not produce a warning/block
          if (!notificationSubscription?.locationIds?.length) return false;

          // If any notification type is set no warning/block
          if (notificationSubscription.notifyEmail && notificationSubscription.notifySms) return false;

          // If any option is set then no warning/block
          if (
            notificationSubscription.impact ||
            notificationSubscription.overload ||
            notificationSubscription.reports ||
            notificationSubscription.acute ||
            notificationSubscription.prolongedUseAlert
          )
            return false;

          return true;
        })
        .map((user) => user.name ?? ''),
    [filteredUsers, notificationSubscriptions],
  );

  const notificationBlockFunction: BlockerFunction = useCallback(
    (args): boolean => {
      if (
        args.nextLocation.pathname.includes(routes.notificationsAdministrators) ||
        args.nextLocation.pathname.includes(routes.notificationsManagers) ||
        args.nextLocation.pathname.includes(routes.notificationsMaintenanceStaff)
      )
        return false;
      return unfinishedUsers.length > 0;
    },
    [unfinishedUsers.length],
  );

  const usersWithSubscriptions = useMemo(
    () =>
      filteredUsers.filter((user) => {
        return notificationSubscriptions.some((nS) => nS.userId === user.id);
      }),
    [filteredUsers, notificationSubscriptions],
  );

  useEffect(() => {
    if (!tourActive) return;

    setTutorialState({
      stepIndex: 1,
      buttonStatus: { showBack: false, showContinue: usersWithSubscriptions.length !== 0 },
    });
  }, [setTutorialState, tourActive, usersWithSubscriptions.length]);

  return (
    <>
      <TableList headCells={userNotificationHeadCells} showSpinner={showSpinner} maxHeight={notificationTableMaxHeight}>
        {usersWithSubscriptions.map((user, i) => {
          const subscription = notificationSubscriptions.find((nS) => nS.userId === user.id);
          if (subscription) {
            return (
              <WorkersNotificationTableRow notificationSubscription={subscription} key={user.id} topInTable={i === 0} />
            );
          }
        })}
      </TableList>
      <NavigationBlocker
        block={!tourActive && notificationBlockFunction}
        title={localized('UnfinishedNotifications')}
        description={unfinishedUsers}
      />
    </>
  );
});
