import { FilledTextFieldProps, OutlinedInputProps, SxProps, TextField } from '@mui/material';
import React, { FC, useMemo } from 'react';
import { Controller, Message, ValidationRule, useFormContext } from 'react-hook-form';
import { errorColorLight, primaryTextColor } from '../../../../styles/color-constants';

interface Props extends Omit<FilledTextFieldProps, 'variant'> {
  name: string;
  label?: string;
  placeholder?: string;
  height?: string;
  validators?: Partial<{
    required: Message | ValidationRule<boolean>;
    min: ValidationRule<number | string>;
    max: ValidationRule<number | string>;
    maxLength: ValidationRule<number>;
    minLength: ValidationRule<number>;
    pattern: ValidationRule<RegExp>;
    valueAsNumber: boolean;
    valueAsDate: boolean;
    setValueAs: (value: any) => any;
    shouldUnregister?: boolean;
    onChange?: (event: any) => void;
    onBlur?: (event: any) => void;
    disabled: boolean;
  }>;
}

export const LargeTextInput: FC<Props> = React.memo((props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { height, label } = props;
  const textFieldStyle: SxProps = useMemo(
    () => ({
      backgroundColor: 'transparent !important',
      justifyContent: 'center',
      '& .MuiFormHelperText-root': {
        color: errorColorLight,
      },
      '& .MuiFilledInput-root': {
        borderRadius: '4px',
        backgroundColor: 'white',
        overflow: 'hidden',
        height: height ?? '70px',
      },
      '& .MuiFilledInput-root:hover': {
        backgroundColor: 'white',
      },
      '& .MuiFilledInput-root.Mui-focused': {
        backgroundColor: 'white',
      },
      '& .MuiFilledInput-input': {
        fontSize: '20px',
        marginBottom: label ? '0px' : '14px',
      },
      '& .MuiInputLabel-root': {
        color: primaryTextColor,
        marginTop: '5px',
        fontSize: '20px',
      },
      '& .MuiFormLabel-filled': {
        marginTop: '0px',
        transform: 'translate(12px, 7px) scale(0.5)',
      },
      '& .MuiInputLabel-root.Mui-focused': {
        marginTop: '0px',
        transform: 'translate(12px, 7px) scale(0.5)',
      },
    }),
    [height, label],
  );

  return (
    <Controller
      name={props.name}
      control={control}
      rules={props.validators}
      render={({ field }) => (
        <TextField
          {...field}
          type={props.type}
          sx={{ ...textFieldStyle, ...props.sx }}
          placeholder={props.placeholder}
          label={label}
          variant="filled"
          value={field.value ?? ''}
          error={!!errors[props.name]}
          helperText={!!errors[props.name] && errors[props.name].message}
          InputProps={{ disableUnderline: true, ...props.inputProps } as Partial<OutlinedInputProps>}
        />
      )}
    />
  );
});
