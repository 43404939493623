import { Box, IconButton, SxProps, TableCell, TableRow } from '@mui/material';
import React, { FC, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { INotificationSubscription, INotificationSubscriptionDTO } from '../../../../../../api/api';
import { ReactComponent as LocationsIcon } from '../../../../../../assets/icons/locations-icon.svg';
import { useAuth } from '../../../../../../auth/auth-provider';
import { localized } from '../../../../../../i18n/i18n';
import { updateNotificationSubscription } from '../../../../../../state/features/notification-subscriptions/operation';
import { selectOrganization } from '../../../../../../state/features/settings/settings-slice';
import { selectOrganizationJob, selectUserById } from '../../../../../../state/features/users/users-slice';
import { AppState } from '../../../../../../state/store';
import { disabledColor } from '../../../../../../styles/color-constants';
import { theme } from '../../../../../../styles/theme';
import { ManageNotificationsStepTargets } from '../../../../../../utilities/enums/tutorial-steps-target';
import { organizationJobToString } from '../../../../../../utilities/helpers/organization-enum-to-string';
import { nameof } from '../../../../../../utilities/platform-helpers/nameof-helper';
import { SetNotificationLocations } from '../../../../../pages/notifications/for-users/set-notification-locations';
import { FlexRow } from '../../../../default-components/flex-row';
import { StyledCheckbox } from '../../../StyledCheckbox';
import { useRightSidebar } from '../../../right-side-bar';
import { tableStyles } from '../../table-list';
import { NotificationToolTip } from './notification-tooltip';
import { useTutorial } from '../../../../../../utilities/providers/tutorial-provider';

const cellStyleLocal: SxProps = {
  ...tableStyles.rowCell,
  padding: 0,
};

const buttonStyle: SxProps = {
  marginLeft: '2px',
};

const border = '1px solid #E0E0E0';

interface Props {
  notificationSubscription: INotificationSubscriptionDTO;
  topInTable: boolean;
}

export const WorkersNotificationTableRow: FC<Props> = React.memo(({ notificationSubscription, topInTable }) => {
  const { isManager } = useAuth();
  const user = useSelector(selectUserById(notificationSubscription.userId));
  const dispatch = useDispatch();
  const setSidebarContent = useRightSidebar();
  const {
    setTutorialState,
    tutorialState: { tourActive },
  } = useTutorial();

  const selectedOrganization = useSelector(selectOrganization);
  const organizationJob = useSelector(selectOrganizationJob(user?.id, selectedOrganization?.id));

  const noLocations = useMemo(
    () => notificationSubscription.locationIds?.length === 0,
    [notificationSubscription.locationIds?.length],
  );
  const smsDisabled = useMemo(() => !user?.phoneNumber || noLocations, [noLocations, user?.phoneNumber]);
  const emailDisabled = useMemo(() => !user?.email || noLocations, [noLocations, user?.email]);
  const optionsDisabled = useMemo(
    () => noLocations || (!notificationSubscription.notifySms && !notificationSubscription.notifyEmail),
    [noLocations, notificationSubscription.notifyEmail, notificationSubscription.notifySms],
  );

  const textTextStyle = { color: smsDisabled ? disabledColor : undefined };
  const emailTextStyle = { color: emailDisabled ? disabledColor : undefined };

  const locationsConnected = useSelector((store: AppState) => {
    const notificationSub = store.notificationSubscriptionsReducer.notificationSubscriptions.find(
      (nS) => nS.id === notificationSubscription.id,
    );
    return notificationSub?.locationIds?.length ?? 0;
  });

  const handleCheck = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (isManager) {
        const name = event.currentTarget.name;
        const value = event.target.checked;

        const updateObj = {
          ...notificationSubscription,
          [name]: value,
        } as INotificationSubscriptionDTO;
        dispatch(updateNotificationSubscription(updateObj));
      }
    },
    [dispatch, isManager, notificationSubscription],
  );

  const handleSetLocations = useCallback(() => {
    setSidebarContent(<SetNotificationLocations notificationSubscription={notificationSubscription} />);

    if(!tourActive) return
    setTimeout(() => {
      setTutorialState({
        stepIndex: 3,
        buttonStatus: {
          showBack: false,
          showContinue: true,
        },
      });
    }, 400);
  }, [setSidebarContent, notificationSubscription, tourActive, setTutorialState]);

  return (
    <TableRow>
      <TableCell sx={cellStyleLocal} align={'left'}>
        {user?.name}
      </TableCell>
      <TableCell sx={{ ...cellStyleLocal, borderRight: border }} align={'left'}>
        {organizationJobToString(organizationJob)}
      </TableCell>
      <TableCell sx={{ ...tableStyles.rowCell, paddingTop: 0, paddingBottom: 0, borderRight: border }} align={'center'}>
        <FlexRow
          alignItems={'center'}
          justifyContent={'center'}
          id={topInTable ? ManageNotificationsStepTargets.Step3 : undefined}>
          {locationsConnected}
          <IconButton onClick={handleSetLocations} sx={buttonStyle}>
            <LocationsIcon width={'20px'} height={'30px'} color={theme.colors.checkBox.boarderColor} />
          </IconButton>
        </FlexRow>
      </TableCell>
      <TableCell sx={{ ...cellStyleLocal, borderRight: border }} align={'left'}>
        <FlexRow
          flexWrap={'wrap'}
          maxWidth={'300px'}
          overflow={'auto'}
          id={topInTable ? ManageNotificationsStepTargets.Step6 : undefined}>
          <NotificationToolTip notificationSubscription={notificationSubscription} toolTipKey={'sms'}>
            <FlexRow alignItems={'center'} width={'110px'} paddingLeft={'16px'}>
              <StyledCheckbox
                disabled={smsDisabled}
                checked={notificationSubscription.notifySms && !smsDisabled}
                name={nameof<INotificationSubscription>('notifySms')}
                onChange={handleCheck}
                border={true}
              />
              <span style={textTextStyle}>{localized('Text')}</span>
            </FlexRow>
          </NotificationToolTip>
          <NotificationToolTip notificationSubscription={notificationSubscription} toolTipKey={'email'}>
            <FlexRow alignItems={'center'} width={'110px'} paddingLeft={'16px'}>
              <StyledCheckbox
                disabled={emailDisabled}
                checked={notificationSubscription.notifyEmail && !emailDisabled}
                name={nameof<INotificationSubscription>('notifyEmail')}
                onChange={handleCheck}
                border={true}
              />
              <span style={emailTextStyle}>{localized('Email')}</span>
            </FlexRow>
          </NotificationToolTip>
        </FlexRow>
      </TableCell>
      <TableCell
        sx={cellStyleLocal}
        align={'center'}
        id={topInTable ? ManageNotificationsStepTargets.Step7 : undefined}>
        <NotificationToolTip notificationSubscription={notificationSubscription} toolTipKey={'acute'}>
          <Box>
            <StyledCheckbox
              disabled={optionsDisabled}
              checked={notificationSubscription.acute && !optionsDisabled}
              name={nameof<INotificationSubscription>('acute')}
              onChange={handleCheck}
              border={true}
            />
          </Box>
        </NotificationToolTip>
      </TableCell>
      <TableCell
        sx={cellStyleLocal}
        align={'center'}
        id={topInTable ? ManageNotificationsStepTargets.Step8 : undefined}>
        <NotificationToolTip notificationSubscription={notificationSubscription} toolTipKey={'prolongedUseAlert'}>
          <Box>
            <StyledCheckbox
              disabled={optionsDisabled}
              checked={notificationSubscription.prolongedUseAlert && !optionsDisabled}
              name={nameof<INotificationSubscription>('prolongedUseAlert')}
              onChange={handleCheck}
              border={true}
            />
          </Box>
        </NotificationToolTip>
      </TableCell>
      <TableCell
        sx={cellStyleLocal}
        align={'center'}
        id={topInTable ? ManageNotificationsStepTargets.Step9 : undefined}>
        <NotificationToolTip notificationSubscription={notificationSubscription} toolTipKey={'overload'}>
          <Box>
            <StyledCheckbox
              disabled={optionsDisabled}
              checked={notificationSubscription.overload && !optionsDisabled}
              name={nameof<INotificationSubscription>('overload')}
              onChange={handleCheck}
              border={true}
            />
          </Box>
        </NotificationToolTip>
      </TableCell>
      <TableCell
        sx={{ ...cellStyleLocal, borderRight: border }}
        align={'center'}
        id={topInTable ? ManageNotificationsStepTargets.Step10 : undefined}>
        <NotificationToolTip notificationSubscription={notificationSubscription} toolTipKey={'impact'}>
          <Box>
            <StyledCheckbox
              disabled={optionsDisabled}
              checked={notificationSubscription.impact && !optionsDisabled}
              name={nameof<INotificationSubscription>('impact')}
              onChange={handleCheck}
              border={true}
            />
          </Box>
        </NotificationToolTip>
      </TableCell>
      <TableCell
        sx={{ ...cellStyleLocal }}
        align={'center'}
        id={topInTable ? ManageNotificationsStepTargets.Step11 : undefined}>
        <NotificationToolTip notificationSubscription={notificationSubscription} toolTipKey={'report'}>
          <Box>
            <StyledCheckbox
              disabled={optionsDisabled}
              checked={notificationSubscription.reports && !optionsDisabled}
              name={nameof<INotificationSubscription>('reports')}
              onChange={handleCheck}
              border={true}
            />
          </Box>
        </NotificationToolTip>
      </TableCell>
    </TableRow>
  );
});
