import { IconButton, SxProps, TableCell, TableRow } from '@mui/material';
import React, { FC, useCallback } from 'react';
import { ILocationDTO } from '../../../../../../api/api';
import { ReactComponent as UserSearchIcon } from '../../../../../../assets/icons/user-search-icon.svg';
import { SetNotificationUsers } from '../../../../../pages/notifications/for-locations/set-notification-users';
import { FlexRow } from '../../../../default-components/flex-row';
import { useRightSidebar } from '../../../right-side-bar';
import { tableStyles } from '../../table-list';
import { useTutorial } from '../../../../../../utilities/providers/tutorial-provider';
import { ManageNotificationsStepTargets } from '../../../../../../utilities/enums/tutorial-steps-target';

interface Props {
  location: ILocationDTO;
  firstInTable: boolean;
}

const styles: { [key: string]: SxProps } = {
  button: {
    marginLeft: '2px',
  },
};

export const ByLocationTableRow: FC<Props> = React.memo((props) => {
  const { location } = props;
  const {
    setTutorialState,
    tutorialState: { tourActive },
  } = useTutorial();
  const setSidebarContent = useRightSidebar();

  const handleEditUsers = useCallback(() => {
    setSidebarContent(<SetNotificationUsers location={location} />);
    if (!tourActive) return;
    
    setTimeout(() => {
      setTutorialState({ stepIndex: 13 });
    }, 500);
  }, [location, setSidebarContent, setTutorialState, tourActive]);

  return (
    <TableRow>
      <TableCell align={'left'} sx={{ ...tableStyles.rowCell }}>
        {location.room}
      </TableCell>
      <TableCell align={'left'} sx={tableStyles.rowCell}>
        {location.floor}
      </TableCell>
      <TableCell align={'left'} sx={tableStyles.rowCell}>
        {location.street}
      </TableCell>
      <TableCell align={'left'} sx={tableStyles.rowCell}>
        {location.city}
      </TableCell>
      <TableCell align={'left'} sx={tableStyles.rowCell}>
        {location.country}
      </TableCell>
      <TableCell align={'right'} sx={tableStyles.rowCell}>
        <FlexRow alignItems={'center'} id={props.firstInTable ? ManageNotificationsStepTargets.Step13 : undefined}>
          {location.notificationSubscriptionIds?.length}
          <IconButton sx={styles.button} onClick={handleEditUsers}>
            <UserSearchIcon width={'24px'} height={'24px'} />
          </IconButton>
        </FlexRow>
      </TableCell>
    </TableRow>
  );
});
