import { DialogActions, DialogContentText, DialogTitle, Typography } from '@mui/material';
import React, { FC, memo, useCallback } from 'react';
import { unstable_BlockerFunction as BlockerFunction, unstable_useBlocker as useBlocker } from 'react-router-dom';
import { localized } from '../../i18n/i18n';
import { primaryTextColor } from '../../styles/color-constants';
import { BasicButton } from './default-components/buttons/basic-button';
import { BasicModal } from './default-components/modal/basic-modal';

interface Props {
  block: boolean | BlockerFunction;
  description?: string[];
  title?: string;
}
export const NavigationBlocker: FC<Props> = memo(({ block, title, description }) => {
  const blocker = useBlocker(block);
  const handleCancel = useCallback(() => {
    blocker.reset?.();
  }, [blocker]);

  const handleConfirm = useCallback(() => {
    blocker.proceed?.();
  }, [blocker]);

  return (
    <BasicModal open={blocker.state === 'blocked'}>
      <DialogTitle>{title ? title : localized('NavigateWarning')}</DialogTitle>
      <DialogContentText sx={{ marginBottom: 1 }}>
        {description?.map((text, index) => (
          <Typography key={index} variant="body1" sx={{ color: primaryTextColor }}>
            {text}
          </Typography>
        ))}
      </DialogContentText>
      <DialogActions>
        <BasicButton onClick={handleCancel} text={localized('Cancel')} buttonColor={'primary'} />
        <BasicButton onClick={handleConfirm} text={localized('Continue')} buttonColor={'warning'} />
      </DialogActions>
    </BasicModal>
  );
});
