import { SxProps } from '@mui/material';
import React, { FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { localized } from '../../../../../i18n/i18n';
import { clearDeviceSetupWizard } from '../../../../../state/features/device-setup-wizard/device-setup-wizard-slice';
import { selectLocationById } from '../../../../../state/features/locations/locations-slice';
import { selectOrganization } from '../../../../../state/features/settings/settings-slice';
import { AppState } from '../../../../../state/store';
import { sideBarDividerColor, sidebarIconColor } from '../../../../../styles/color-constants';
import { locationToString } from '../../../../../utilities/helpers/location-helper';
import { FlexColumn } from '../../../default-components/flex-column';
import { NavigationBlocker } from '../../../navigation-blocker';
import { StyledButton } from '../../buttons/styled-button';
import { RightSideContainer } from '../../containers/right-side-container';
import { useRightSidebar } from '../../right-side-bar';
import { SidebarCancelButton } from '../../sidebar-cancel-button';
import { ConnectIdInput } from './connect-id-input';
import { ModelSelect } from './model-select';
import { ProductCategorySelect } from './product-category-select';
import { UnitLocationSelect } from './unit-location-select';
import { UnitSettingsStep } from './unit-settings-step';
import { UnitWizardStep } from './unit-wizard-step';
import { DeviceConfigDTO, DeviceInfoDTO } from '../../../../../api/api';
import { configureDevice } from '../../../../../state/features/devices/operation';
import { useTutorial } from '../../../../../utilities/providers/tutorial-provider';
import { ConnectAndAddNewUnitStepTargets } from '../../../../../utilities/enums/tutorial-steps-target';

const styles: { [key: string]: SxProps } = {
  typography: {
    marginTop: '16px',
    marginBottom: '16px',
    color: sidebarIconColor,
  },
  dividerColor: {
    backgroundColor: sideBarDividerColor,
  },
  bottomDivider: {
    marginBottom: 2,
    marginTop: 2,
    backgroundColor: sideBarDividerColor,
  },
};

export const SetupWizard: FC = React.memo(() => {
  const dispatch = useDispatch();
  const setSidebarContent = useRightSidebar();
  const {
    setTutorialState,
    tutorialState: { tourActive },
  } = useTutorial();
  const selectedOrganization = useSelector(selectOrganization);
  const { device, category, model, locationId, deviceConfig } = useSelector(
    (store: AppState) => store.deviceSetupWizardReducer,
  );
  const location = useSelector(selectLocationById(locationId));

  useEffect(() => {
    if (!tourActive) return;

    setTimeout(() => {
      setTutorialState({ stepIndex: 2 });
    }, 400);
  }, [setTutorialState, tourActive]);

  const handleSaveUnit = useCallback(() => {
    if (locationId && device && model && selectedOrganization) {
      const deviceLocal = { ...device };
      deviceLocal.organizationId = selectedOrganization.id;
      deviceLocal.deviceModelId = model.id;
      deviceLocal.locationId = locationId;
      deviceLocal.deviceConfig = deviceConfig as DeviceConfigDTO;
      dispatch(configureDevice(deviceLocal as DeviceInfoDTO));
      setSidebarContent(null);

      if (!tourActive) return;

      setTimeout(() => {
        setTutorialState({ stepIndex: 11 });
      }, 600);
    }
  }, [
    locationId,
    device,
    model,
    selectedOrganization,
    deviceConfig,
    dispatch,
    setSidebarContent,
    tourActive,
    setTutorialState,
  ]);

  useEffect(() => {
    return () => {
      // Clear data on dismount
      dispatch(clearDeviceSetupWizard());
    };
  }, [dispatch]);

  return (
    <RightSideContainer title={localized('AddUnit')}>
      <FlexColumn sx={{ justifyContent: 'space-between', flex: 1 }}>
        <FlexColumn>
          <UnitWizardStep
            title={'1. ' + localized('ConnectID')}
            stage={'ConnectId'}
            value={device?.connectId}
            stepInput={<ConnectIdInput />}
            id={ConnectAndAddNewUnitStepTargets.Step3}
          />
          <UnitWizardStep
            title={'2. ' + localized('ProductCategory')}
            stage={'Product'}
            value={category?.nameTranslationKey && localized(category.nameTranslationKey as any)}
            stepInput={<ProductCategorySelect />}
            id={ConnectAndAddNewUnitStepTargets.Step4}
          />
          <UnitWizardStep
            title={'3. ' + localized('Model')}
            stage={'Model'}
            value={model?.modelCode}
            stepInput={<ModelSelect />}
            id={ConnectAndAddNewUnitStepTargets.Step5}
          />
          <UnitWizardStep
            title={'4. ' + localized('Location')}
            stage={'Location'}
            value={location && locationToString(location)}
            stepInput={<UnitLocationSelect />}
            id={ConnectAndAddNewUnitStepTargets.Step6}
          />
          <UnitWizardStep title={'5. ' + localized('Settings')} stage={'Settings'} stepInput={<UnitSettingsStep />} />
        </FlexColumn>
        <FlexColumn>
          <StyledButton
            sx={styles.button}
            disabled={!location}
            onClick={handleSaveUnit}
            id={ConnectAndAddNewUnitStepTargets.Step11}>
            {localized('SaveUnit')}
          </StyledButton>
          <SidebarCancelButton sx={{ marginBottom: '20px', marginTop: 0 }} />
          {/* No reason to validate steps after the first step*/}
        </FlexColumn>
      </FlexColumn>
      <NavigationBlocker block={!device} />
    </RightSideContainer>
  );
});
