import {SvgIcon} from '@mui/material';
import React from 'react';

export function SettingsIconSmall(props: any) {
  const color = props.color ?? '#647883';
  return (
    <SvgIcon {...props}>
      <g id="Group_10" data-name="Group 10" clipPath="url(#settings-small-clip-path)">
        <path
          id="Path_34"
          data-name="Path 34"
          d="M22.271,9.95l-1.989-.4a8.609,8.609,0,0,0-.673-1.628l1.124-1.687a.959.959,0,0,0-.12-1.209l-1.6-1.6a.958.958,0,0,0-1.209-.12L16.111,4.438a8.6,8.6,0,0,0-1.622-.679l-.4-1.988a.958.958,0,0,0-.94-.77H10.89a.958.958,0,0,0-.94.77l-.4,1.988a8.515,8.515,0,0,0-1.624.678L6.237,3.309a.959.959,0,0,0-1.209.12l-1.6,1.6a.959.959,0,0,0-.12,1.209L4.438,7.931a8.6,8.6,0,0,0-.676,1.621l-1.991.4a.959.959,0,0,0-.77.94v2.263a.958.958,0,0,0,.77.939l1.99.4a8.529,8.529,0,0,0,.673,1.629L3.308,17.8a.959.959,0,0,0,.12,1.209l1.6,1.6a.958.958,0,0,0,1.209.12L7.931,19.6a8.573,8.573,0,0,0,1.621.679l.4,1.989a.958.958,0,0,0,.94.771h2.262a.958.958,0,0,0,.94-.771l.4-1.989a8.557,8.557,0,0,0,1.629-.673L17.8,20.733a.957.957,0,0,0,1.209-.12l1.6-1.6a.958.958,0,0,0,.12-1.209L19.6,16.111a8.6,8.6,0,0,0,.678-1.622l1.989-.4a.958.958,0,0,0,.771-.939V10.89A.959.959,0,0,0,22.271,9.95Z"
          transform="translate(-0.521 -0.521)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_35"
          data-name="Path 35"
          d="M20.809,14.444a4.788,4.788,0,1,0,2.33,2.33"
          transform="translate(-7.292 -7.292)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeWidth="2"
        />
      </g>
    </SvgIcon>
  );
}
