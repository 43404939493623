import dayjs from 'dayjs';
import 'dayjs/locale/da';
import 'dayjs/locale/en-gb';
import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import englishTranslation from './en.json';
import englishPlatformTranslation from './platform-translations/en.json';

export const Languages = {
  en: 'English',
} as const;

export type LanguageType = keyof typeof Languages;

export type Translation = typeof i18nResources.en.translation;

// NB. When adding a NEW TRANSLATION, REMEMBER to IMPORT the corresponding dayjs LOCALE at the top!
const i18nResources = {
  en: {
    translation: {...englishPlatformTranslation, ...englishTranslation},
  },
};

export type i18nTranslationKey = any;

i18n.use(detector);
i18n.init(
  {
    fallbackLng: 'en',
    resources: i18nResources,
    // have a common namespace used around the full app
    ns: ['translation'],
    defaultNS: 'translation',
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it does escape per default to prevent xss!
    },
    react: {
      //withRef: false,
      bindI18n: 'languageChanged loaded',
      bindI18nStore: 'added removed',
      nsMode: 'default',
    },
  },
  (err, t) => {
    let userLang = navigator.language;
    if (userLang.includes('en')) {
      i18n.changeLanguage('en');
    }
  },
);

dayjs.extend(require('dayjs/plugin/localizedFormat'));

if (navigator.language) {
  const lngLower = navigator.language.toLocaleLowerCase();
  if (['en', 'en-us'].includes(lngLower)) {
    dayjs.locale('en');
  } else {
    //Fall back to UK-format. Appropriate for anyone that is neither US, or specifically supported with language (like DK)
    dayjs.locale('en-gb');
  }
}

// type-safe translation lookup
export const localized = (key: keyof Translation): string => {
  return i18n.t(key);
};

export const localizedDynamic = (key: keyof Translation | string): string => {
  return i18n.t(key);
};

export const localizedInterpolation = (key: keyof Translation, keysToReplace: any): string => {
  return i18n.t(key, keysToReplace);
};

export const getTranslation = (language: keyof typeof Languages) => i18n.getFixedT(language);

export const getLanguage = () => {
  return i18n.language === 'en-US' ? 'en' : i18n.language;
};

export const changeLanguage = async (language: keyof typeof Languages) => {
  await i18n.changeLanguage(language);

  dayjs.locale('en');
};
