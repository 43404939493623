import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Collapse, Divider, IconButton, SxProps, Typography } from '@mui/material';
import React, { FC, memo, useCallback, useState } from 'react';
import { theme } from '../../../../../styles/theme';
import { FlexRow } from '../../../default-components/flex-row';

export const styles: { [key: string]: SxProps } = {
  questionSpacer: {
    marginY: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  questionText: {
    fontSize: theme.typography.h5,
    fontWeight: 'bold',
    textTransform: 'none',
  },
  answerText: {
    marginBottom: 2,
  },
  addIcon: {
    color: theme.colors.textButton.specialTextColor,
  },
};

export interface IFaqItem {
  question: string;
  answer: string;
}

interface PropsFromParent {
  faq: IFaqItem;
}

export const FaqListItem: FC<PropsFromParent> = memo(({ faq }) => {
  const [checked, setChecked] = useState<boolean>(false);
  const togglePressed = useCallback(() => {
    setChecked(!checked);
  }, [checked]);

  return (
    <>
      <FlexRow sx={styles.questionSpacer}>
        <Typography sx={styles.questionText}>{faq.question}</Typography>
        <IconButton onClick={togglePressed}>
          {checked ? (
            <RemoveIcon sx={styles.addIcon} fontSize="large" />
          ) : (
            <AddIcon sx={styles.addIcon} fontSize="large" />
          )}
        </IconButton>
      </FlexRow>
      <Collapse in={checked} timeout="auto" unmountOnExit>
        <Typography sx={styles.answerText} whiteSpace={'pre-line'}>
          {faq.answer}
        </Typography>
      </Collapse>
      <Divider />
    </>
  );
});
