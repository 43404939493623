import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { FormControl, SxProps } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { ILocationDTO } from '../../../../api/api';
import { localized } from '../../../../i18n/i18n';
import { selectLocations } from '../../../../state/features/locations/locations-slice';
import { theme } from '../../../../styles/theme';
import { sortListItems } from '../../../../utilities/helpers/general-helpers';
import { locationToString } from '../../../../utilities/helpers/location-helper';
import { DropdownOption } from '../../default-components/basic-dropdown/basic-dropdown';
import { StyledSelect } from '../styled-select';

interface Props {
  name: string;
  setActiveLocation: (value: ILocationDTO) => void;
  onOpen?: (event: React.SyntheticEvent) => void;
  holdSelectedLocation: boolean;
  selectedValue?: ILocationDTO;
  id?: string;
}

const localStyles: { [key: string]: SxProps } = {
  formControl: {
    maxWidth: '700px',
    width: '100%',
    backgroundColor: theme.colors.dropdown.backgroundColor,
    marginLeft: '0px',
  },
  select: {
    height: '70px',
    fontSize: '20px',
    padding: '0',
    borderRadius: '3px',
    '& .MuiSelect-icon': {
      fontSize: '50px',
      marginRight: '7px',
      color: theme.colors.dropdown.textColor,
    },
    '& .MuiOutlinedInput-input': {
      paddingLeft: '28px',
      paddingBottom: '0',
      marginBottom: '16.5px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiFilledInput-root': {
      backgroundColor: theme.colors.dropdown.backgroundColor,
    },
    '& .MuiFilledInput-root:hover': {
      backgroundColor: theme.colors.dropdown.backgroundColor,
    },
    '& .MuiFilledInput-root.Mui-focused': {
      backgroundColor: theme.colors.dropdown.backgroundColor,
    },
  },
  fontSize: {
    fontSize: '20px',
  },
  menu: {
    '& .MuiMenuItem-root': {
      paddingTop: '0px',
    },
  },
};

// Very similar to location-select but without a bunch of extra features
export const BasicLocationSelect = React.memo((props: Props) => {
  const { setActiveLocation, onOpen } = props;
  const [selectedLocation, setSelectedLocation] = useState<ILocationDTO | undefined>(props.selectedValue);

  const locations = useSelector(selectLocations);

  const locationOptions: DropdownOption[] = useMemo(() => {
    const options = locations?.map((location) => ({ label: locationToString(location), value: location.id }));
    return sortListItems(options ?? [], 'label', 'asc');
  }, [locations]);

  const handleLocationSelect = useCallback(
    (e: DropdownOption) => {
      if (locations) {
        const location = locations.find((location) => location.id === e.value);
        if (location) {
          props.holdSelectedLocation && setSelectedLocation(location);
          setActiveLocation(location);
        }
      }
    },
    [locations, props.holdSelectedLocation, setActiveLocation],
  );

  return (
    <FormControl sx={localStyles.formControl}>
      <StyledSelect
        id={props.id}
        handleChange={handleLocationSelect}
        onOpen={onOpen}
        options={locationOptions}
        sx={localStyles.select}
        label={localized('Location')}
        color="secondary"
        IconComponent={KeyboardArrowDownIcon}
        value={locationOptions && locationOptions.find((o) => o.value === selectedLocation?.id)}
      />
    </FormControl>
  );
});
