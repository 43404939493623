import { IDeviceInfoDTO } from '../../api/api';
import { localizedDynamic } from '../../i18n/i18n';
import { locationToString } from './location-helper';

export type searchOption = 'ConnectId' | 'Model' | 'Category' | 'Location';

export const searchOptionFilter = (devices: IDeviceInfoDTO[], searchText: string, searchOption: searchOption) => {
  const filterText = searchText.toLowerCase();
  if (searchOption === 'ConnectId') {
    // Searches the value in the connectId field
    devices = devices.filter((d) => d['connectId']?.toLowerCase()?.includes(filterText));
  } else if (searchOption === 'Location') {
    // Searches generated location string
    devices = devices.filter((d) => locationToString(d.locationDTO).toLowerCase().includes(filterText));
  } else if (searchOption === 'Model') {
    devices = devices.filter((d) => d.deviceModelCode?.toLowerCase().includes(filterText));
  } else if (searchOption === 'Category') {
    devices = devices.filter((d) =>
      localizedDynamic(d.deviceCategoryNameTranslationKey ?? '')
        .toLowerCase()
        .includes(filterText),
    );
  }
  return devices;
};
