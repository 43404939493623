import { Autocomplete, AutocompleteRenderInputParams, SxProps, debounce } from '@mui/material';
import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, Message, ValidationRule, useFormContext } from 'react-hook-form';
import { localized } from '../../../../i18n/i18n';
import { whiteColor } from '../../../../styles/color-constants';
import { useGoogleMaps } from '../../../../utilities/google-maps-provider';
import {
  AutocompletePrediction,
  AutocompletionRequest,
  ComponentRestrictions,
  PlacesServiceStatus,
} from '../../../../utilities/google-types';
import { AutocompleteField, AutocompleteOption } from './maps-autocomplete-input-components';
import { useTutorial } from '../../../../utilities/providers/tutorial-provider';

const localStyles: { [key: string]: SxProps } = {
  searchField: {
    borderRadius: 1,
    backgroundColor: whiteColor,
    '& .MuiInput-input': {
      fontSize: '20px',
      height: '70px',
    },
  },
};

interface Props {
  name: string;
  label?: string;
  disabled?: boolean;
  validators?: Partial<{
    required: Message | ValidationRule<boolean>;
    min: ValidationRule<number | string>;
    max: ValidationRule<number | string>;
    maxLength: ValidationRule<number>;
    minLength: ValidationRule<number>;
    pattern: ValidationRule<RegExp>;
    valueAsNumber: boolean;
    valueAsDate: boolean;
    setValueAs: (value: any) => any;
    shouldUnregister?: boolean;
    onChange?: (event: any) => void;
    onBlur?: (event: any) => void;
    disabled: boolean;
  }>;
  noValuesText?: string;
  searchType?: 'country' | 'postal_code' | 'city' | 'address';
  countryRestraint?: string | string[] | null;
  placeHolder?: string;
  defaultValue?: string;
}

export const MapsAutocompleteInput: FC<Props> = memo((props) => {
  const [autocompleteValue, setAutocompleteValue] = useState<AutocompletePrediction | null>(null);
  const [searchValue, setSearchValue] = useState<string>('');
  const [options, setOptions] = useState<readonly AutocompletePrediction[]>([]);
  const { control, setValue } = useFormContext();
  const {
    tutorialState: { tourActive },
  } = useTutorial();

  const { autocompleteService } = useGoogleMaps();

  // Fetch method which debounces the autocomplete request to limit the amount of requests sent to the google api
  const fetch = useMemo(
    () =>
      debounce(
        (
          request: AutocompletionRequest,
          callback: (a: AutocompletePrediction[] | null, b: PlacesServiceStatus) => void,
        ) => {
          autocompleteService.current?.getPlacePredictions(request, callback);
        },
        400,
      ),
    [autocompleteService],
  );

  // Depending on the search type we can restrict the type of the suggestions to certain types.
  // https://developers.google.com/maps/documentation/javascript/supported_types#table3
  const restrictionTypes: string[] = useMemo(() => {
    switch (props.searchType) {
      case 'country':
        return ['country'];
      case 'postal_code':
        return ['postal_code'];
      case 'city':
        return ['locality'];
      case 'address':
        return ['address'];
      default:
        return [];
    }
  }, [props.searchType]);

  // When searching for anything other than a country we can limit the results to up to 5 countries by passing in an array
  const componentRestrictions: ComponentRestrictions = useMemo(() => {
    if (props.searchType !== 'country' && props.countryRestraint) {
      return { country: props.countryRestraint };
    } else {
      return { country: null };
    }
  }, [props.countryRestraint, props.searchType]);

  // Options object for the fetch request
  const fetchOptions: AutocompletionRequest = useMemo(
    () => ({
      input: searchValue,
      types: restrictionTypes,
      componentRestrictions: componentRestrictions,
      language: navigator.language.slice(3, 5).toUpperCase(),
    }),
    [componentRestrictions, searchValue, restrictionTypes],
  );

  useEffect(() => {
    if (props.defaultValue) {
      const acVal = {
        description: props.defaultValue,
        place_id: props.defaultValue,
        structured_formatting: { main_text: props.defaultValue },
      } as AutocompletePrediction;
      setAutocompleteValue(acVal);
    }
  }, [props.defaultValue]);
  // Effect which performs the autocomplete request.
  useEffect(() => {
    let active = true;

    if (!autocompleteService.current) {
      return undefined;
    }

    if (searchValue === '') {
      setOptions(autocompleteValue ? [autocompleteValue] : []);
      return undefined;
    }

    fetch(fetchOptions, (results: AutocompletePrediction[] | null, b: PlacesServiceStatus) => {
      if (active) {
        let newOptions: AutocompletePrediction[] = [];
        if (autocompleteValue) {
          newOptions = [autocompleteValue];
        }
        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });
  }, [searchValue, fetch, autocompleteValue, props.searchType, fetchOptions, autocompleteService]);

  // Handler method to invoke the relevant method for onChange
  const onChangeLocal = useCallback(
    (event: any, newValue: AutocompletePrediction | null) => {
      setOptions(newValue ? [newValue, ...options] : options);
      setAutocompleteValue(newValue);
      setValue(props.name, newValue?.structured_formatting.main_text);
      setValue(props.name + '_placeId', newValue?.place_id);
    },
    [options, props.name, setValue],
  );

  const onClearLocal = useCallback(() => {
    setSearchValue('');
    setAutocompleteValue(null);
    setValue(props.name, undefined);
  }, [props.name, setValue]);

  const _renderInput = useCallback(
    (params: AutocompleteRenderInputParams) => (
      <AutocompleteField params={params} placeholder={props.placeHolder} onClear={onClearLocal} />
    ),
    [onClearLocal, props.placeHolder],
  );

  const _renderOption = useCallback(
    (props: React.HTMLAttributes<HTMLLIElement>, option: AutocompletePrediction) => (
      <AutocompleteOption props={props} option={option} />
    ),
    [],
  );

  return (
    <Controller
      name={props.name}
      control={control}
      rules={props.validators}
      render={({ field }) => (
        <Autocomplete
          {...field}
          getOptionLabel={(option) => (typeof option === 'string' ? option : option.structured_formatting.main_text)}
          sx={localStyles.searchField}
          filterOptions={(x) => x}
          options={tourActive ? options.slice(0, 2) : options}
          autoComplete
          includeInputInList
          filterSelectedOptions
          value={autocompleteValue}
          noOptionsText={props.noValuesText ? props.noValuesText : localized('NoLocations')}
          onChange={(event, value) => {
            field.onChange(event, value);
            onChangeLocal(event, value);
          }}
          onInputChange={(event, newInputValue) => {
            setSearchValue(newInputValue);
          }}
          renderInput={_renderInput}
          renderOption={_renderOption}
        />
      )}
    />
  );
});
