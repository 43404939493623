import {IUser, OrganizationJob, OrganizationRole} from '../../api/api';
import {localized} from '../../i18n/i18n';

export const organizationJobToString = (job: OrganizationJob | undefined) => {
  return job === OrganizationJob.Other
    ? localized('Other')
    : job === OrganizationJob.Cleaning
    ? localized('Cleaning')
    : job === OrganizationJob.Janitorial
    ? localized('Janitorial')
    : job === OrganizationJob.Security
    ? localized('Security')
    : '';
};

export const organizationRoleToString = (role: OrganizationRole | undefined) => {
  return role === OrganizationRole.Admin
    ? localized('Administrator')
    : role === OrganizationRole.Manager
    ? localized('Manager')
    : role === OrganizationRole.Maintenance
    ? localized('MaintenanceStaffMember')
    : '';
};

export const getJob = (user: IUser | undefined, organizationId: number | undefined): string => {
  if (user === undefined) return '';

  const job = user.organizationUser?.find((orgUser) => orgUser.organizationId === organizationId)?.organizationJob;
  return organizationJobToString(job);
};
