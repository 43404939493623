import React, { FC } from 'react';
import { SwitchProps } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { LargeSwitch } from '../large-switch';

interface Props extends SwitchProps {
  name: string;
}

export const LargeFormSwitch: FC<Props> = React.memo((props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      name={props.name}
      control={control}
      render={({ field }) => <LargeSwitch {...props} {...field} checked={field.value} />}
    />
  );
});
