// Theme colors
export const primaryColor = '#dcdcdc';
export const primaryColorDarker = '#a9a9a9';
export const primaryTextColor = '#2b2b2b';

export const secondaryColor = '#FFFFFF';
export const secondaryTextColor = '#dcdcdc';

export const backgroundColor = secondaryColor;
export const validColor = '#31FF00';
export const invalidColor = '#f44336';
export const errorColorLight = '#de6363';
export const warningColor = '#FDB900';
export const whiteColor = '#FFFFFF';
export const blackColor = '#000000';
export const disabledColor = '#E0E4E6';
// Component colors

// Header
export const headerBackgroundColor = secondaryColor;

// Sidebar
export const barBackgroundColor = 'rgb(165, 165, 165)';
export const sidebarBackgroundColor = 'rgb(43, 43, 43)';
export const sidebarIconColor = 'rgb(165, 165, 165)';
export const sidebarIconSelectedColor = secondaryColor;
export const sidebarBackgroundSelectedColor = '#ECECEC';
export const sidebarSelectedBorder = '#B8B9C8';
export const sideBarProfileBackgroundColor = '#f5f4f4';
export const sideBarTextFieldBackgroundColor = whiteColor;
export const sideBarDividerColor = sidebarIconColor;

// Content Wrapper

// Basic Card
export const basicCardBgColor = '#FFFFFF';
export const basicCardBoxShadow = '0px 3px 6px #00000029';
// Button
export const buttonColor = '#F37021';
export const buttonDisabledColor = '#F0F0F0';
export const buttonDisabledTextColor = '#CCCCCC';
export const buttonDisabledBorderColor = buttonColor;
// Input

// Toggle

// Switch
export const switchThumbUnchecked = '#A5A5A5';

// Info item
export const onlineColor = '#67C74A';
export const offlineColor = '#DC3F3F';
// Textfield

// Checkbox Filter
export const checkboxFilterBg = '#FAFAFA';

// SearchInput
export const searchBgColor = '#E6E6E6';

// List Table

// Info item 2
export const infoItem2BackgroundColor = '#FAFAFA';
