import React, { FC } from 'react';
import { SxProps, Typography } from '@mui/material';
import { FlexRow } from '../../../default-components/flex-row';

const styles: { [key: string]: SxProps } = {
  typography: {
    color: 'white',
    fontSize: '16px',
    fontWeight: 200,
    marginLeft: '24px',
    marginRight: 'auto',
  },
};

interface Props {
  icon: JSX.Element;
  description: string;
  input: JSX.Element;
  id?: string
}

export const GeneralUnitConfigRow: FC<Props> = React.memo((props) => {
  return (
    <FlexRow alignItems={'center'} id={props.id}>
      {props.icon}
      <Typography sx={styles.typography} width={'295px'}>
        {props.description}
      </Typography>
      {props.input}
    </FlexRow>
  );
});
