import {SvgIcon} from '@mui/material';
import React from 'react';

export function EditIcon(props: any) {
  const color = props.color ?? '#647883';
  return (
    <SvgIcon {...props}>
      <g id="Group_78" data-name="Group 78" transform="translate(0 0.333)">
        <g id="Group_64" data-name="Group 64" clipPath="url(#clip-path)">
          <path
            id="Path_69"
            data-name="Path 69"
            d="M14.63,2,20.37,7.741,8.889,19.222,2,20.37l1.148-6.889Z"
            transform="translate(-0.852 -0.852)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <line
            id="Line_36"
            data-name="Line 36"
            x2="14"
            transform="translate(1 19.667)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <line
            id="Line_37"
            data-name="Line 37"
            transform="translate(20 19.667)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </g>
      </g>
    </SvgIcon>
  );
}
