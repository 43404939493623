export const GeneralTargets = {
  Body: 'body',
};

export const CreateANewLocationStepTargets = {
  Step1: 'tour-1-step-1-menu-item',
  Step2: 'tour-1-step-2-add-new-location',
  Step3: 'tour-1-step-3-enter-address',
  Step4: 'tour-1-step-4-enter-floor-and-room',
  Step5: 'tour-1-step-5-save',
  Step6: 'tour-1-step-6-new-location',
  Step7: 'tour-1-step-6-new-location-edit',
  LastStep: GeneralTargets.Body,
};

export const AddAndManageUsersStepTargets = {
  Step1: 'tour-2-step-1-menu-item',
  Step2: 'tour-2-step-2-maintenance',
  Step3: 'tour-2-step-3-add-new-maintenance',
  Step4: 'tour-2-step-4-enter-name-mail',
  Step5: 'tour-2-step-5-enter-phone-number',
  Step6: 'tour-2-step-6-select-service-area',
  // Classname because we want multiple targets in selector',
  Step7: 'MuiMenu-list',
  Step8: 'tour-2-step-8-send-invite',
  Step9: 'tour-2-step-9-edit',
  LastStep: GeneralTargets.Body,
};

export const ConnectAndAddNewUnitStepTargets = {
  Step1: 'tour-3-step-1-menu-item',
  Step2: 'tour-3-step-2-add-new-unit',
  Step3: 'tour-3-step-3-enter-connect-id',
  Step4: 'tour-3-step-4-choose-category',
  Step5: 'tour-3-step-5-choose-model',
  Step6: 'tour-3-step-6-choose-location',
  // Classname because we want multiple targets in selector',
  Step7: 'MuiMenu-list',
  Step8: 'tour-3-step-8-adjust-settings-1',
  Step9: 'tour-3-step-9-adjust-settings-2',
  Step10: 'tour-3-step-10-adjust-settings-3',
  Step11: 'tour-3-step-11-save-unit',
  Step12: 'tour-3-step-12-edit-or-delete',
  LastStep: GeneralTargets.Body,
};

export const DownloadReportStepTargets = {
  Step1: 'tour-5-step-1-menu-item',
  Step2: 'tour-5-step-2-choose-location',
  Step3: 'MuiMenu-list',
  Step4: 'tour-5-step-4-choose-fromdate',
  Step5: 'MuiCalendarPicker-root',
  Step6: 'tour-5-step-6-choose-todate',
  Step7: 'MuiCalendarPicker-root',
  Step8: 'tour-5-step-5-press-download',
  LastStep: GeneralTargets.Body,
};

export const ManageNotificationsStepTargets = {
  Step1: 'tour-4-step-1-menu-item',
  Step2: 'tour-4-step-2-choose-group',
  Step3: 'tour-4-step-3-choose-locations',
  Step4: 'tour-4-step-4-add-or-remove-locations',
  Step5: 'tour-4-step-5-save-locations',
  Step6: 'tour-4-step-6-how-to-receive',
  Step7: 'tour-4-step-7-enter-select-areas-1',
  Step8: 'tour-4-step-7-enter-select-areas-2',
  Step9: 'tour-4-step-7-enter-select-areas-3',
  Step10: 'tour-4-step-7-enter-select-areas-4',
  Step11: 'tour-4-step-7-enter-select-areas-5',
  Step12: 'tour-4-step-8-manage-locations',
  Step13: 'tour-4-step-9-who-shall-recive',
  Step14: 'tour-4-step-10-select-users',
  Step15: 'tour-4-step-11-save',
  Step16: GeneralTargets.Body,
  LastStep: GeneralTargets.Body,
};

export const asIdRef = (id: string) => {
  return '#' + id;
};
export const asClassnameRef = (id: string) => {
  return '.' + id;
};
