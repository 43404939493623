import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {SnackbarState} from './types';

const initialState: SnackbarState = {
  snackbars: [],
};

export const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    showSuccessSnackbar: (state, action: PayloadAction<string>) => {
      state.snackbars.push({
        key: new Date().getTime() + Math.random(),
        message: action.payload,
        options: {
          variant: 'success',
          autoHideDuration: 3000,
        },
      });
    },
    showErrorSnackbar: (state, action: PayloadAction<string>) => {
      state.snackbars.push({
        key: new Date().getTime() + Math.random(),
        message: action.payload,
        options: {
          variant: 'error',
          autoHideDuration: 10000,
        },
      });
    },
    showWarningSnackbar: (state, action: PayloadAction<string>) => {
      state.snackbars.push({
        key: new Date().getTime() + Math.random(),
        message: action.payload,
        options: {
          variant: 'warning',
          autoHideDuration: 8000,
        },
      });
    },
    showInfoSnackbar: (state, action: PayloadAction<string>) => {
      state.snackbars.push({
        key: new Date().getTime() + Math.random(),
        message: action.payload,
        options: {
          variant: 'info',
          autoHideDuration: 5000,
        },
      });
    },
    clearSnackbars: (state) => {
      state.snackbars = state.snackbars.map((s) => {
        s.dismissed = true;
        return s;
      });
    },
    clearSnackbar: (state, action: PayloadAction<number>) => {
      let snackbar = state.snackbars.find((s) => s.key === action.payload);
      if (snackbar) {
        snackbar.dismissed = true;
      }
    },
    removeSnackbar: (state, action: PayloadAction<number>) => {
      state.snackbars.splice(
        state.snackbars.findIndex((s) => s.key === action.payload),
        1,
      );
    },
  },
});

export const {
  showSuccessSnackbar,
  showErrorSnackbar,
  showWarningSnackbar,
  showInfoSnackbar,
  clearSnackbars,
  clearSnackbar,
  removeSnackbar,
} = snackbarSlice.actions;

export default snackbarSlice.reducer;
