import AddIcon from '@mui/icons-material/Add';
import { Button, SxProps } from '@mui/material';
import React, { FC, useCallback } from 'react';
import { theme } from '../../../../styles/theme';

interface Props {
  onClick?: () => void;
  id?: string;
}

const styles: { [key: string]: SxProps } = {
  button: {
    marginRight: 'auto',
    textTransform: 'none',
    fontSize: '20px',
    fontWeight: 'normal',
    color: theme.colors.textButton.specialTextColor,
  },
  icon: {
    marginRight: '10px',
  },
};

export const AddNewButton: FC<Props> = React.memo((props) => {
  const { onClick } = props;

  const localOnClick = useCallback(() => {
    if (onClick) onClick();
  }, [onClick]);

  return (
    <Button sx={styles.button} disableRipple onClick={localOnClick} id={props.id}>
      <AddIcon sx={styles.icon} />
      {props.children}
    </Button>
  );
});
