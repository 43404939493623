import LogoutIcon from '@mui/icons-material/Logout';
import { Button, List, Theme, useMediaQuery } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, { FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Organization } from '../../../api/api';
import { ReactComponent as HelpIcon } from '../../../assets/icons/help-icon.svg';
import { ReactComponent as InsightsIcon } from '../../../assets/icons/insights-icon.svg';
import { ReactComponent as LocationsIcon } from '../../../assets/icons/locations-icon.svg';
import { ReactComponent as NotificationsIcon } from '../../../assets/icons/notifications-icon.svg';
import { ReactComponent as SettingsIcon } from '../../../assets/icons/settings-icon.svg';
import { ReactComponent as UnitsIcon } from '../../../assets/icons/units-icon.svg';
import { ReactComponent as UsersIcon } from '../../../assets/icons/users-icon.svg';
import LogoPressalit from '../../../assets/pressalit_logo_white.svg';
import { useAuth } from '../../../auth/auth-provider';
import { localized } from '../../../i18n/i18n';
import { selectOrganisations } from '../../../state/features/organization/organization-slice';
import { selectOrganization, setOrganizationSetting } from '../../../state/features/settings/settings-slice';
import { getUserByAuthId } from '../../../state/features/users/operation';
import { routes } from '../../../state/routes';
import { sidebarBackgroundColor, sidebarIconColor } from '../../../styles/color-constants';
import {
  AddAndManageUsersStepTargets,
  ConnectAndAddNewUnitStepTargets,
  CreateANewLocationStepTargets,
  DownloadReportStepTargets,
  ManageNotificationsStepTargets,
} from '../../../utilities/enums/tutorial-steps-target';
import { FlexColumn } from '../../components/default-components/flex-column';
import { FlexRow } from '../../components/default-components/flex-row';
import { MenuItem } from './menu-item';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menu: {
      backgroundColor: sidebarBackgroundColor,
      display: 'flex',
      flexDirection: 'column',
      position: 'fixed',
      height: '100%',
      overflowY: 'auto',
      overflowX: 'hidden',
      zIndex: '3',
    },
    list: {
      flexGrow: 1,
    },
    logoBox: {
      marginTop: theme.spacing(4),
      justifyContent: 'center',
      height: '30px',
    },
    logo: {
      height: 16,
    },
    menuIcon: {
      margin: `${theme.spacing(1.75)} 0`,
      transform: 'scale(2.5)',
    },
    logout: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: theme.spacing(2),
    },
  }),
);

const buttonStyle = {
  textTransform: 'none',
  fontSize: '10px',
  fontWeight: 'normal',
  color: sidebarIconColor,
  alignItems: 'center',
};

export const SideMenu: FC = React.memo(() => {
  let location = useLocation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { logout } = useAuth();

  const selectedOrganization = useSelector(selectOrganization);
  const organizations = useSelector(selectOrganisations);

  const wide = useMediaQuery('(min-width:1560px)');
  const menuWidth = wide ? 150 : 80;

  // Done here to make sure that the active user is set in state
  useEffect(() => {
    dispatch(getUserByAuthId());

    if (!selectedOrganization) {
      dispatch(setOrganizationSetting(organizations[0] as Organization));
    }
  }, [dispatch, selectedOrganization, organizations]);

  const handleLogout = useCallback(() => {
    logout();
  }, [logout]);

  const locationBaseString = '/' + location.pathname.split('/')[1];

  return (
    <div className={classes.menu} style={{ width: menuWidth, minWidth: menuWidth }}>
      <FlexRow className={classes.logoBox}>
        {wide && (
          <Link to="/">
            <img alt="Logo" src={LogoPressalit} className={classes.logo} />
          </Link>
        )}
      </FlexRow>
      <List className={classes.list}>
        <MenuItem
          id={CreateANewLocationStepTargets.Step1}
          linkTo={routes.locations}
          selected={locationBaseString === routes.locations}
          icon={<LocationsIcon />}
          text={localized('Locations')}
          identifier={routes.locations}
          key={routes.locations}
        />
        <MenuItem
          id={AddAndManageUsersStepTargets.Step1}
          linkTo={routes.users}
          selected={locationBaseString === routes.users}
          icon={<UsersIcon />}
          text={localized('Users')}
          identifier={routes.users}
          key={routes.users}
        />
        <MenuItem
          id={ConnectAndAddNewUnitStepTargets.Step1}
          linkTo={routes.units}
          selected={locationBaseString === routes.units}
          icon={<UnitsIcon />}
          text={localized('Units')}
          identifier={routes.units}
          key={routes.units}
        />
        <MenuItem
          id={ManageNotificationsStepTargets.Step1}
          linkTo={routes.notifications}
          selected={locationBaseString === routes.notifications}
          icon={<NotificationsIcon />}
          text={localized('Notifications')}
          identifier={routes.notifications}
          key={routes.notifications}
        />
        <MenuItem
          id={DownloadReportStepTargets.Step1}
          linkTo={routes.insights}
          selected={locationBaseString === routes.insights}
          icon={<InsightsIcon />}
          text={localized('Insights')}
          identifier={routes.insights}
          key={routes.insights}
        />
        <MenuItem
          linkTo={routes.settings}
          selected={locationBaseString === routes.settings}
          icon={<SettingsIcon />}
          text={localized('Organizations')}
          identifier={routes.settings}
          key={routes.settings}
        />
      </List>
      <MenuItem
        linkTo={routes.help}
        selected={locationBaseString === routes.help}
        icon={<HelpIcon width={'40px'} height={'40px'} />}
        text={localized('Help')}
        identifier={routes.help}
        key={routes.help}
      />
      <div className={classes.logout}>
        <Button onClick={handleLogout} sx={buttonStyle} fullWidth>
          <FlexColumn alignItems={'center'}>
            <LogoutIcon sx={{ fontSize: '40px' }} />
            Logout
          </FlexColumn>
        </Button>
      </div>
    </div>
  );
});
