import { Box, Divider, SxProps, Typography } from '@mui/material';
import React, { FC, memo, useMemo } from 'react';
import { FaqListItem, IFaqItem } from './faq-list-item';

export const styles: { [key: string]: SxProps } = {
  tableListSpacing: {
    maxWidth: '800px',
    marginY: 5,
  },
  questionText: {
    marginBottom: 2,
  },
};

export interface IFaqList {
  topic: string;
  faqs: IFaqItem[];
}

interface PropsFromParent {
  faqList: IFaqList;
}

export const FaqList: FC<PropsFromParent> = memo(({ faqList }) => {
  const listItems = useMemo(() => faqList.faqs.map((faq, i) => <FaqListItem faq={faq} key={i} />), [faqList.faqs]);

  return (
    <Box sx={styles.tableListSpacing}>
      <Typography sx={styles.questionText}>{faqList.topic}</Typography>
      <Divider />
      {listItems}
    </Box>
  );
});
