import {Box, Theme} from '@mui/material';

import withStyles from '@mui/styles/withStyles';

export const PageContentContainer = withStyles((theme: Theme) => ({
  root: {
    width: '100%',
    height: 'auto',
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
}))(Box);
