import React, { FC, memo, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { localized, localizedInterpolation } from '../../../../i18n/i18n';
import { selectLocations } from '../../../../state/features/locations/locations-slice';
import { AddNewButton } from '../../../components/specific-components/buttons/add-new-button';
import { useRightSidebar } from '../../../components/specific-components/right-side-bar';
import { HeadCell, TableList } from '../../../components/specific-components/table-list/table-list';
import { CreateLocation } from '../create-location/create-location';
import { LocationsListItem } from './locations-list-item';
import { CreateANewLocationStepTargets } from '../../../../utilities/enums/tutorial-steps-target';

const headCells: HeadCell[] = [
  {
    id: 'name',
    label: localized('Name'),
    align: 'left',
  },
  {
    id: 'edit',
    label: localized('Edit'),
    align: 'right',
    width: '50px',
  },
  {
    id: 'delete',
    label: localized('Delete'),
    align: 'right',
    width: '50px',
  },
];

export const LocationsList: FC = memo(() => {
  const locations = useSelector(selectLocations);
  const setSidebarContent = useRightSidebar();

  const onNewLocation = useCallback(() => {
    setSidebarContent(<CreateLocation />);
  }, [setSidebarContent]);

  const listItems = useMemo(
    () =>
      locations.map((location, i) => (
        <LocationsListItem
          location={location}
          key={location.id}
          id={i === 0 ? CreateANewLocationStepTargets.Step6 : undefined}
        />
      )),
    [locations],
  );

  return (
    <>
      <AddNewButton onClick={onNewLocation} id={CreateANewLocationStepTargets.Step2}>
        {localizedInterpolation('AddNewInt', {
          area: localized('Location').toLocaleLowerCase(),
        })}
      </AddNewButton>
      <TableList headCells={headCells}>{listItems}</TableList>
    </>
  );
});
