import { SxProps } from '@mui/material';
import React, { FC } from 'react';
import { sidebarIconColor } from '../../../../styles/color-constants';
import { FlexColumn } from '../../default-components/flex-column';
import { PageContentContainer } from '../../default-components/page-container/page-content-container';

const styles: { [key: string]: SxProps } = {
  header: {
    display: 'flex',
    justifyContent: 'center',
    color: sidebarIconColor,
    height: '100%',
    paddingX: '9%',
  },
};

interface Props {
  title?: string;
}

export const RightSideContainer: FC<Props> = React.memo((props) => {
  return (
    <PageContentContainer sx={styles.header}>
      <FlexColumn marginTop={'20px'} flexGrow={1}>
        <h1>{props.title}</h1>
        {props.children}
      </FlexColumn>
    </PageContentContainer>
  );
});
