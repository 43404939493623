import { Divider, List, ListItemButton, ListItemText, SxProps } from '@mui/material';
import React, { FC, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ILocationDTO, INotificationSubscriptionDTO } from '../../../../api/api';
import { useAuth } from '../../../../auth/auth-provider';
import { localized } from '../../../../i18n/i18n';
import { updateLocation } from '../../../../state/features/locations/operations';
import { selectNotificationSubscriptions } from '../../../../state/features/notification-subscriptions/notification-subscriptions-slice';
import { selectUserNameLookupMap } from '../../../../state/features/users/users-slice';
import { sideBarDividerColor } from '../../../../styles/color-constants';
import { ManageNotificationsStepTargets } from '../../../../utilities/enums/tutorial-steps-target';
import { useTutorial } from '../../../../utilities/providers/tutorial-provider';
import { StyledCheckbox } from '../../../components/specific-components/StyledCheckbox';
import { StyledButton } from '../../../components/specific-components/buttons/styled-button';
import { RightSideContainer } from '../../../components/specific-components/containers/right-side-container';
import { useRightSidebar } from '../../../components/specific-components/right-side-bar';
import { SidebarCancelButton } from '../../../components/specific-components/sidebar-cancel-button';

interface Props {
  location: ILocationDTO;
}

const styles: { [key: string]: SxProps } = {
  dividerColor: {
    backgroundColor: sideBarDividerColor,
  },
};

const textStyles = {
  fontSize: '20px',
};

export const SetNotificationUsers: FC<Props> = React.memo((props) => {
  const { location } = props;
  const { isManager } = useAuth();
  const dispatch = useDispatch();
  const {
    setTutorialState,
    tutorialState: { tourActive },
  } = useTutorial();
  const setSidebarContent = useRightSidebar();
  const notificationSubscriptions = useSelector(selectNotificationSubscriptions);
  const userNameMap = useSelector(selectUserNameLookupMap);
  const [selectedSubscriptions, setselectedSubscriptions] = useState<INotificationSubscriptionDTO[]>(
    notificationSubscriptions.filter((ns) => ns.locationIds?.includes(location.id)),
  );

  const handleUserCheck = useCallback(
    (notificationSubscription: INotificationSubscriptionDTO) => () => {
      const index = selectedSubscriptions.findIndex((ns) => ns.id === notificationSubscription.id);
      if (index === -1) {
        setselectedSubscriptions([...selectedSubscriptions, notificationSubscription]);
      } else {
        setselectedSubscriptions(selectedSubscriptions.filter((l) => l?.id !== notificationSubscription.id));
      }
    },
    [selectedSubscriptions],
  );

  const handleSave = useCallback(() => {
    location.notificationSubscriptionIds = selectedSubscriptions.map((ns) => ns.id);
    dispatch(updateLocation(location));
    setSidebarContent(null);
    if (!tourActive) return;
    
    setTimeout(() => {
      setTutorialState({ stepIndex: 16 });
    }, 100);
  }, [location, selectedSubscriptions, dispatch, setSidebarContent, tourActive, setTutorialState]);

  return (
    <RightSideContainer title={localized('Users')}>
      <Divider sx={styles.dividerColor} />
      <List dense disablePadding sx={{ overflow: 'auto', marginBottom: 5 }} id={ManageNotificationsStepTargets.Step14}>
        {notificationSubscriptions.map((nS: INotificationSubscriptionDTO) => (
          <div key={nS.id}>
            <ListItemButton disableGutters disableRipple onClick={handleUserCheck(nS)} disabled={!isManager}>
              <StyledCheckbox checked={selectedSubscriptions.includes(nS)} />
              <ListItemText primary={userNameMap.get(nS.userId)} primaryTypographyProps={textStyles} />
            </ListItemButton>
            <Divider sx={styles.dividerColor} />
          </div>
        ))}
      </List>
      <StyledButton bottomStuck onClick={handleSave} id={ManageNotificationsStepTargets.Step15}>
        {localized('SaveAndClose')}
      </StyledButton>
      <SidebarCancelButton />
    </RightSideContainer>
  );
});
