import { Box, Divider, SxProps, Typography } from '@mui/material';
import React, { FC, ReactNode, memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentStage } from '../../../../../state/features/device-setup-wizard/device-setup-wizard-slice';
import { DeviceSetupStage } from '../../../../../state/features/device-setup-wizard/types';
import { AppState } from '../../../../../state/store';
import { sideBarDividerColor, sidebarIconColor } from '../../../../../styles/color-constants';

const styles: { [key: string]: SxProps } = {
  typography: {
    marginTop: '16px',
    marginBottom: '16px',
    color: sidebarIconColor,
  },
  dividerColor: {
    backgroundColor: sideBarDividerColor,
  },
  bottomDivider: {
    marginBottom: '64px',
    backgroundColor: sideBarDividerColor,
  },
};

interface PropsFromParent {
  title: string;
  value?: string;
  stage: DeviceSetupStage;
  stepInput: ReactNode;
  id?: string
}
export const UnitWizardStep: FC<PropsFromParent> = memo((props) => {
  const { currentStage } = useSelector((store: AppState) => store.deviceSetupWizardReducer);
  const dispatch = useDispatch();
  const handleTitleClick = useCallback(() => {
    if (props.value) dispatch(setCurrentStage(props.stage));
  }, [dispatch, props.stage, props.value]);

  const cursorStyle: SxProps = useMemo(() => ({ cursor: props.value ? 'pointer' : 'text' }), [props.value]);
  return (
    <Box id={props.id}>
      <Divider sx={styles.dividerColor} />
      <Box onClick={handleTitleClick} sx={cursorStyle}>
        <Typography fontSize={'20px'} sx={styles.typography}>
          {props.title}
          {props.value && <b>{' ' + props.value}</b>}
        </Typography>
      </Box>
      {props.stage === currentStage && props.stepInput}
    </Box>
  );
});
