import React, {FC} from 'react';
import {StyledButton, StyledButtonProps} from './styled-button';
import {CircularProgress} from '@mui/material';

interface Props extends StyledButtonProps {
  loading: boolean;
}

export const StyledLoadingButton: FC<Props> = React.memo((props) => {
  return (
    <StyledButton disabled={props.loading} {...props}>
      {props.loading ? <CircularProgress color={'inherit'} /> : props.children}
    </StyledButton>
  );
});
