import { SxProps } from '@mui/material';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { useAuth } from '../../../../auth/auth-provider';
import { localized } from '../../../../i18n/i18n';
import { useDownloadFile } from '../../../../services/download-helper';
import { reportClient } from '../../../../state/api-clients';
import { StyledLoadingButton } from '../buttons/styled-loading-button';

const styles: { [key: string]: SxProps } = {
  button: {
    width: '341px',
    height: '70px',
    marginTop: '50px',
    alignSelf: 'start',
  },
};

interface Props {
  fromDate?: Date;
  toDate?: Date;
}

export const DownloadCompleteReportButton: FC<Props> = React.memo(({ fromDate, toDate }) => {
  const { isGlobalAdmin } = useAuth();
  const disabled = useMemo(() => !(fromDate !== undefined && toDate !== undefined), [fromDate, toDate]);

  const apiDefinition = useCallback(() => {
    return reportClient.getCompleteReport(fromDate, toDate);
  }, [fromDate, toDate]);

  const { ref, url, download, name, loading } = useDownloadFile({
    apiDefinition,
  });

  if (!isGlobalAdmin) {
    return null;
  }

  return (
    <>
      <StyledLoadingButton loading={loading} sx={styles.button} disabled={disabled} onClick={download}>
        {localized('DownloadCompleteReport')}
      </StyledLoadingButton>
      <a href={url} download={name} className={'hidden'} ref={ref} />
    </>
  );
});
