import { createSelector, createSlice, isPending, isRejected } from '@reduxjs/toolkit';
import { AppState } from '../../store';
import { createLocation, deleteLocation, getLocationsByOrganizationId, updateLocation } from './operations';
import { LocationState } from './types';

const initialState: LocationState = {
  locations: [],
  pending: false,
  isSuccess: false,
};

export const locationsSlice = createSlice({
  name: 'locations',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get by organization
    builder.addCase(getLocationsByOrganizationId.fulfilled, (state, action) => {
      state.locations = action.payload;
      state.pending = false;
    });

    // Create
    builder.addCase(createLocation.fulfilled, (state, action) => {
      state.locations.push(action.payload);
      state.pending = false;
      state.isSuccess = true;
    });

    // Update
    builder.addCase(updateLocation.fulfilled, (state, action) => {
      const index = state.locations.findIndex((d) => d.id === action.payload.id);
      if (index > -1) {
        state.locations[index] = action.payload;
      }
      state.pending = false;
      state.isSuccess = true;
    });

    // Delete
    builder.addCase(deleteLocation.fulfilled, (state, action) => {
      const index = state.locations.findIndex((d) => d.id === action.meta.arg.id);
      if (index > -1) {
        state.locations.splice(index, 1);
      }
      state.pending = false;
      state.isSuccess = true;
    });

    builder.addMatcher(
      isPending(getLocationsByOrganizationId, createLocation, updateLocation, deleteLocation),
      (state) => {
        state.pending = true;
        state.isSuccess = false;
      },
    );
    builder.addMatcher(
      isRejected(getLocationsByOrganizationId, createLocation, updateLocation, deleteLocation),
      (state) => {
        state.pending = false;
        state.isSuccess = false;
      },
    );
  },
});

export const selectLocations = (store: AppState) => store.locationsReducer.locations;

export const selectLocationById = (id?: number) =>
  createSelector(selectLocations, (locations) => {
    return locations.find((l) => l.id === id);
  });
// eslint-disable-next-line no-empty-pattern
export const {} = locationsSlice.actions;

export default locationsSlice.reducer;
