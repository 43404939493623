import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { withStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { buttonColor, sidebarIconColor } from '../../../styles/color-constants';

interface PropsFromParent {
  id?: string;
  selected: boolean;
  linkTo: string;
  icon?: JSX.Element;
  text?: string;
  identifier: any;
  onMenuItemClicked?: (identifier: string) => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    centered: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '10px'
    },
    link: {
      textDecoration: 'none',
    },
  }),
);

const StyledListItem = withStyles({
  root: {
    height: '96px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: sidebarIconColor,
    '& .MuiListItemIcon-root': {
      opacity: 1,
      color: sidebarIconColor,
    },
  },
  selected: {
    '& .MuiListItemIcon-root': {
      opacity: 1,
      color: buttonColor,
    },
    color: buttonColor,
    backgroundColor: 'rgba(0,0,0,0.0) !important',
    background: `linear-gradient(${buttonColor}, ${buttonColor}) right / 6px 50% no-repeat`,
  },
})(ListItemButton);

export const MenuItem: FC<PropsFromParent> = React.memo((props) => {
  const classes = useStyles();

  const onMenuItemClicked = () => {
    props.onMenuItemClicked && props.onMenuItemClicked(props.identifier);
  };

  return (
    <Link to={props.linkTo} className={classes.link} id={props.id}>
      <div>
        <StyledListItem selected={props.selected} onClick={onMenuItemClicked} disableRipple>
          <ListItemIcon className={`${classes.centered}`}>{props.icon}</ListItemIcon>
          <ListItemText
            primaryTypographyProps={{ fontSize: '10px' }}
            className={`${classes.centered}`}
            primary={props.text}
          />
        </StyledListItem>
      </div>
    </Link>
  );
});
