import { Typography } from '@mui/material';
import React, { FC, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { localized } from '../../../../../i18n/i18n';
import { getDeviceByConnectId } from '../../../../../state/features/devices/operation';
import { AppState } from '../../../../../state/store';
import { FlexColumn } from '../../../default-components/flex-column';
import { Form } from '../../../default-components/form/form';
import { StyledButton } from '../../buttons/styled-button';
import { LargeTextField } from '../../large-text-field';
import { useTutorial } from '../../../../../utilities/providers/tutorial-provider';

export const ConnectIdInput: FC = React.memo(() => {
  const dispatch = useDispatch();
  const {
    setTutorialState,
    tutorialState: { tourActive },
  } = useTutorial();
  const { device } = useSelector((store: AppState) => store.deviceSetupWizardReducer);
  const [localConnectId, setLocalConnectId] = useState<string>(device?.connectId ?? '');

  const handleSubmit = useCallback(() => {
    // Trim connectId to avoid spaces before or after the connectId
    const trimmedConnectId = localConnectId.trimStart().trimEnd();
    setLocalConnectId(trimmedConnectId);

    dispatch(getDeviceByConnectId({ connectId: trimmedConnectId, forSetup: true }));
    if (!tourActive) return;
    setTimeout(() => {
      setTutorialState({ stepIndex: 3 });
    }, 200);
  }, [dispatch, localConnectId, setTutorialState, tourActive]);

  const handleConnectId = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalConnectId(e.target.value);
  }, []);

  return (
    <FlexColumn marginBottom={'auto'} marginTop={'-12px'}>
      <Typography fontSize={'12px'} whiteSpace={'pre-line'}>
        {localized('MetaTextNewUnitConnectId')}
      </Typography>
      <Form onSubmit={handleSubmit}>
        <LargeTextField
          name="connectId number"
          placeholder={localized('ConnectID')}
          value={localConnectId}
          handleChange={handleConnectId}
        />
        <StyledButton type="submit" sx={{ marginBottom: 4 }}>
          {localized('Continue')}
        </StyledButton>
      </Form>
    </FlexColumn>
  );
});
