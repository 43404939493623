import React, {FC} from 'react';
import {RightSideBar} from '../../components/specific-components/right-side-bar';
import {Units} from './units';

// Workaround for using rightsidebar as a context
export const UnitsRoute: FC = React.memo((props) => {
  return (
    <RightSideBar>
      <Units />
    </RightSideBar>
  );
});
