import React, { FC, memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ILocationDTO, LocationDTO } from '../../../../api/api';
import { createLocation, updateLocation } from '../../../../state/features/locations/operations';
import { selectOrganization } from '../../../../state/features/settings/settings-slice';
import { AppState } from '../../../../state/store';
import { usePrevious } from '../../../../utilities/use-previous-hook';
import { Form } from '../../../components/default-components/form/form';
import { RightSideContainer } from '../../../components/specific-components/containers/right-side-container';
import { useRightSidebar } from '../../../components/specific-components/right-side-bar';
import { LocationForm } from './location-form';
import { useTutorial } from '../../../../utilities/providers/tutorial-provider';

interface PropsFromParent {
  existingLocation?: ILocationDTO;
}

export const CreateLocation: FC<PropsFromParent> = memo(({ existingLocation }) => {
  const dispatch = useDispatch();
  const setSidebarContent = useRightSidebar();
  const selectedOrganization = useSelector(selectOrganization);
  const {
    setTutorialState,
    tutorialState: { tourActive },
  } = useTutorial();

  const { pending, isSuccess } = useSelector((store: AppState) => store.locationsReducer);

  const prevProp = usePrevious(isSuccess);
  // Direct to overview after saving. (We do this due to race condition between save and getAll)
  useEffect(() => {
    if (prevProp !== undefined && isSuccess && !prevProp) {
      setSidebarContent(null);
    }
  }, [pending, isSuccess, prevProp, setSidebarContent]);

  const handleSubmit = useCallback(
    (data: unknown) => {
      if (!selectedOrganization) return;
      const location = LocationDTO.fromJS(data);
      location.organizationId = selectedOrganization.id;
      if (existingLocation) {
        // Update
        location.id = existingLocation.id;
        dispatch(updateLocation(location));
      } else {
        // Insert
        dispatch(createLocation(location));
        if (!tourActive) return;
        setTimeout(() => {
          setTutorialState({ stepIndex: 5 });
        }, 2000);
      }
    },
    [dispatch, existingLocation, selectedOrganization, setTutorialState, tourActive],
  );

  return (
    <RightSideContainer>
      <Form maxHeight onSubmit={handleSubmit}>
        <LocationForm existingLocation={existingLocation} />
      </Form>
    </RightSideContainer>
  );
});
