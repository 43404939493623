import { Box, SxProps } from '@mui/material';
import React, { FC, useCallback, useState } from 'react';
import { localized } from '../../../../i18n/i18n';
import { useDownloadFile } from '../../../../services/download-helper';
import { reportClient } from '../../../../state/api-clients';
import { StyledLoadingButton } from '../buttons/styled-loading-button';
import { useTutorial } from '../../../../utilities/providers/tutorial-provider';
import { DownloadCompleteReportButton } from './download-complete-report-button';

const styles: { [key: string]: SxProps } = {
  button: {
    width: '341px',
    height: '70px',
    marginTop: '50px',
    alignSelf: 'start',
  },
};

interface Props {
  disabled: boolean;
  locationId?: number;
  fromDate?: Date;
  toDate?: Date;
  id?: string;
}

export const DownloadReportButton: FC<Props> = React.memo(({ disabled, locationId, fromDate, toDate, id }) => {
  const {
    setTutorialState,
    tutorialState: { tourActive },
  } = useTutorial();

  const apiDefinition = useCallback(() => {
    return reportClient.getReport(locationId, fromDate, toDate);
  }, [locationId, fromDate, toDate]);

  const { ref, url, download, name, loading } = useDownloadFile({
    apiDefinition,
  });

  const handleDownload = useCallback(() => {
    download();
    if (!tourActive) return;

    setTimeout(() => {
      setTutorialState({ stepIndex: 8 });
    }, 500);
  }, [download, setTutorialState, tourActive]);

  return (
    <>
      <StyledLoadingButton loading={loading} sx={styles.button} disabled={disabled} onClick={handleDownload} id={id}>
        {localized('DownloadReport')}
      </StyledLoadingButton>
      <a href={url} download={name} className={'hidden'} ref={ref} />
    </>
  );
});
