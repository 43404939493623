import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Box, SxProps, Tooltip, Typography, styled, tooltipClasses } from '@mui/material';
import React from 'react';
import { localized } from '../../../../i18n/i18n';
import { sidebarBackgroundColor, whiteColor } from '../../../../styles/color-constants';
import { theme } from '../../../../styles/theme';
import { FlexRow } from '../../default-components/flex-row';

const styles: { [key: string]: SxProps } = {
  bold: {
    fontWeight: 'bold',
  },
  header: {
    fontSize: theme.typography.h5,
    fontWeight: 'bold',
  },
  requirementsSpacing: {
    marginTop: 2,
  },
  requirement: {
    alignItems: 'center',
    textAlign: 'center',
  },
  dashIcon: {
    marginX: 1,
    fontSize: 'small',
  },
  helpIcon: {
    marginLeft: 1,
  },
};

interface Props {
  className?: string;
}

export const BeforeYouGetStarted = styled(({ className }: Props) => {
  return (
    <Box marginBottom={5}>
      <Typography sx={styles.header} whiteSpace={'pre-line'}>
        {localized('BeforeYouGetStarted')}
      </Typography>
      <Typography whiteSpace={'pre-line'}>{localized('ToCompleteTutorial')}</Typography>
      <Box sx={styles.requirementsSpacing}>
        <FlexRow sx={styles.requirement}>
          <FiberManualRecordIcon sx={styles.dashIcon} />
          <Typography sx={styles.bold} whiteSpace={'pre-line'}>
            {localized('Requirement1')}
          </Typography>
          <Tooltip title={localized('Requirement1Helptext')} classes={{ popper: className }} arrow placement="right">
            <HelpOutlineIcon sx={styles.helpIcon} />
          </Tooltip>
        </FlexRow>
        <FlexRow sx={styles.requirement}>
          <FiberManualRecordIcon sx={styles.dashIcon} />
          <Typography sx={styles.bold} whiteSpace={'pre-line'}>
            {localized('Requirement2')}
          </Typography>
          <Tooltip title={localized('Requirement2Helptext')} classes={{ popper: className }} arrow placement="right">
            <HelpOutlineIcon sx={styles.helpIcon} />
          </Tooltip>
        </FlexRow>
      </Box>
    </Box>
  );
})(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: sidebarBackgroundColor,
    color: whiteColor,
    fontSize: 15,
    padding: 16,
    whiteSpace: 'pre-line'
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: sidebarBackgroundColor,
  },
}));
