import CircularProgress from '@mui/material/CircularProgress';
import {SxProps, Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, {FC, useMemo} from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '4px',
    },
  }),
);

interface Props {
  size?: string | number | undefined;
  color?: string;
}

export const BasicSpinner: FC<Props> = React.memo((props) => {
  const classes = useStyles();
  const spinnerStyle: SxProps = useMemo(
    () => ({
      color: props.color,
    }),
    [props.color],
  );

  return (
    <div className={classes.root}>
      <CircularProgress size={props.size} sx={spinnerStyle} />
    </div>
  );
});
