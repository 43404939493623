import { Button, SxProps, Typography } from '@mui/material';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { theme } from '../../../../styles/theme';
import { FlexRow } from '../../default-components/flex-row';

const styles: { [key: string]: SxProps } = {
  largeButton: {
    width: '320px',
    height: '100px',
    borderRadius: 1,
    color: theme.colors.largeButton.textColor,
    backgroundColor: theme.colors.largeButton.backgroundColor,
    marginX: '10px',
    textTransform: 'none',
    border: '3px solid transparent',
    '&:hover': {
      backgroundColor: theme.colors.largeButton.hoverBackgroundColor,
      color: theme.colors.largeButton.hoverTextColor,
    },
  },
  largeButtonFocused: {
    width: '320px',
    height: '100px',
    borderRadius: 1,
    color: theme.colors.largeButton.textColor,
    backgroundColor: theme.colors.largeButton.backgroundColor,
    marginX: '10px',
    textTransform: 'none',
    border: `3px solid ${theme.colors.largeButton.selectedBottomColor}`,
    '&:hover': {
      backgroundColor: theme.colors.largeButton.backgroundColor,
    },
  },
  flexContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const link = {
  textDecoration: 'none',
};

interface Props {
  linkTo: string;
  focus: boolean;
  icon: JSX.Element;
  text: string;
  id?: string;
}

export const LargeButton: FC<Props> = React.memo((props) => {
  const { linkTo, focus, icon, text } = props;
  return (
    <Link to={linkTo} style={link}>
      <Button sx={focus ? styles.largeButtonFocused : styles.largeButton} disableRipple id={props.id}>
        <FlexRow sx={styles.flexContainer}>
          {icon}
          <Typography variant="h6">{text}</Typography>
        </FlexRow>
      </Button>
    </Link>
  );
});
