import { Typography } from '@mui/material';
import React, { FC, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { OrganizationRole } from '../../../api/api';
import { useAuth } from '../../../auth/auth-provider';
import { localized } from '../../../i18n/i18n';
import { selectOrganization } from '../../../state/features/settings/settings-slice';
import { selectUsers } from '../../../state/features/users/users-slice';
import { AppState } from '../../../state/store';
import { AddAndManageUsersStepTargets } from '../../../utilities/enums/tutorial-steps-target';
import { AddNewButton } from '../../components/specific-components/buttons/add-new-button';
import { useRightSidebar } from '../../components/specific-components/right-side-bar';
import { HeadCell, TableList } from '../../components/specific-components/table-list/table-list';
import { UserMaintenanceTableRow } from '../../components/specific-components/table-list/users/user-maintenance-table-row';
import { EditUserProfile } from './side-content/edit-user-profile';
import { userTableMaxHeight } from './users';

const headCells: HeadCell[] = [
  {
    id: 'name',
    label: localized('Name'),
    align: 'left',
  },
  {
    id: 'email',
    label: localized('Email'),
    align: 'left',
  },
  {
    id: 'phoneNr',
    label: localized('PhoneNr'),
    align: 'left',
    width: '150px',
  },
  {
    id: 'serviceArea',
    label: localized('ServiceArea'),
    align: 'left',
  },
  {
    id: 'edit',
    label: localized('Edit'),
    align: 'right',
    width: '20px',
  },
  {
    id: 'delete',
    label: localized('Delete'),
    align: 'right',
    width: '20px',
  },
];

export const UsersMaintenance: FC = React.memo(() => {
  const setSidebarContent = useRightSidebar();
  const users = useSelector(selectUsers);
  const selectedOrganization = useSelector(selectOrganization);
  const { isSuccess } = useSelector((store: AppState) => store.usersReducer);
  const showSpinner = useSelector(
    (store: AppState) => store.usersReducer.pending && store.usersReducer.users.length < 1,
  );
  const { isManager } = useAuth();

  // Clear sidebar when swapping page
  useEffect(() => {
    return () => setSidebarContent(null);
  }, [setSidebarContent]);

  useEffect(() => {
    if (isSuccess) setSidebarContent(null);
  }, [isSuccess, setSidebarContent]);

  const addMaintenanceStaff = useCallback(() => {
    setSidebarContent(<EditUserProfile role={OrganizationRole.Maintenance} />);
  }, [setSidebarContent]);

  const filteredUsers = users.filter((user) => {
    if (selectedOrganization) {
      return (
        user.organizationUser?.find((oU) => oU.organizationId === selectedOrganization.id)?.organizationRole ===
        OrganizationRole.Maintenance
      );
    }
  });

  return (
    <>
      <Typography fontSize={'30px'} fontWeight={'bold'}>
        {localized('MaintenanceStaff')}
      </Typography>
      <Typography variant="body1" whiteSpace={'pre-line'} marginBottom={1}>
        {localized('MetaTextMaintenance')}
      </Typography>
      {isManager && (
        <AddNewButton onClick={addMaintenanceStaff} id={AddAndManageUsersStepTargets.Step3}>
          {localized('AddMaintenance')}
        </AddNewButton>
      )}
      <TableList headCells={headCells} showSpinner={showSpinner} maxHeight={userTableMaxHeight}>
        {filteredUsers.map((user, i) => (
          <UserMaintenanceTableRow
            user={user}
            id={i === 0 ? AddAndManageUsersStepTargets.Step9 : undefined}
          />
        ))}
      </TableList>
    </>
  );
});
