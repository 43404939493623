import {createAsyncThunk} from '@reduxjs/toolkit';
import {DeviceModelClient} from '../../../api/api';
import {AsyncOperationHandler} from '../../../utilities/platform-helpers/api-helper';
import {deviceModelClient} from '../../api-clients';

export const getDeviceModelsByCategory = createAsyncThunk(
  'deviceModels/getDeviceModelsByCategory',
  (deviceCategoryId: number) => {
    return AsyncOperationHandler(
      (client) => (client as DeviceModelClient).getByCategory(deviceCategoryId),
      deviceModelClient,
    );
  },
);
