import LocationOnIcon from '@mui/icons-material/LocationOn';
import { AutocompleteRenderInputParams, Box, Grid, SxProps, TextField, Typography } from '@mui/material';
import parse from 'autosuggest-highlight/parse';
import React, { FC, memo } from 'react';
import { localized } from '../../../../i18n/i18n';
import { buttonColor, whiteColor } from '../../../../styles/color-constants';
import { AutocompletePrediction } from '../../../../utilities/google-types';

const localStyles: { [key: string]: SxProps } = {
  searchField: {
    borderRadius: 1,
    backgroundColor: whiteColor,
    '& .MuiInput-input': {
      fontSize: '20px',
      height: '70px',
      marginLeft: 2,
    },
  },
};

interface FieldProps {
  params: AutocompleteRenderInputParams;

  placeholder?: string;
  onClear: () => void;
}

interface OptionProps {
  props: React.HTMLAttributes<HTMLLIElement>;
  option: AutocompletePrediction;
}

export const AutocompleteField: FC<FieldProps> = memo(({ params, placeholder, onClear }) => {
  return (
    <TextField
      {...params}
      InputProps={{
        ...params.InputProps, // Important to include InputProps from params as this includes the suggestions dropdown/select
        endAdornment: <></>,
      }}
      placeholder={placeholder ? placeholder : localized('SearchHint')}
      variant="standard"
      sx={localStyles.searchField}
    />
  );
});

export const AutocompleteOption: FC<OptionProps> = memo(({ props, option }) => {
  const matches = option.structured_formatting.main_text_matched_substrings || [];

  const parts = parse(
    option.structured_formatting.main_text,
    matches.map((match: any) => [match.offset, match.offset + match.length]),
  );

  return (
    <li {...props} key={option.place_id}>
      <Grid container alignItems="center">
        <Grid item sx={{ display: 'flex', width: 44 }}>
          <LocationOnIcon sx={{ color: buttonColor }} />
        </Grid>
        <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
          {parts.map((part, index) => (
            <Box key={index} component="span" sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}>
              {part.text}
            </Box>
          ))}
          <Typography variant="body2">{option.structured_formatting.secondary_text}</Typography>
        </Grid>
      </Grid>
    </li>
  );
});
