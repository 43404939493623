import { SxProps, Typography } from '@mui/material';
import React, { FC, useCallback } from 'react';
import { localized } from '../../../../i18n/i18n';
import { routes } from '../../../../state/routes';
import { theme } from '../../../../styles/theme';
import { GoToButton } from '../buttons/go-to-button';
import { useNavigate } from 'react-router-dom';

const styles: { [key: string]: SxProps } = {
  metaText: {
    fontSize: theme.typography.body1,
    marginBottom: 1,
  },
};

export const GoToFAQ: FC = React.memo(() => {
  const navigate = useNavigate();

  const goToFAQ = useCallback(() => {
    navigate(routes.helpFAQ);
  }, [navigate]);

  return (
    <>
      <Typography sx={styles.metaText} whiteSpace={'pre-line'}>
        {localized('HelpMetaText4')}
      </Typography>
      <GoToButton onClick={goToFAQ}>{localized('GoToFAQpage')}</GoToButton>
    </>
  );
});
