import { SxProps } from '@mui/material';
import React, { FC, useCallback } from 'react';
import { localized } from '../../../i18n/i18n';
import { buttonColor } from '../../../styles/color-constants';
import { StyledButton } from './buttons/styled-button';
import { useRightSidebar } from './right-side-bar';

interface Props {
  sx?: SxProps;
}

const buttonStyle: SxProps = {
  marginTop: 0,
  marginBottom: '20px',
  backgroundColor: 'unset',
  border: 'none',
  color: buttonColor,
  boxShadow: 'unset',
  '&:hover': {
    color: buttonColor,
    backgroundColor: 'unset',
    border: `none`,
    boxShadow: 'unset',
  },
};
export const SidebarCancelButton: FC<Props> = React.memo(({ sx }) => {
  const setSidebarContent = useRightSidebar();

  const handleCancel = useCallback(() => {
    setSidebarContent(null);
  }, [setSidebarContent]);

  return (
    <StyledButton
      onClick={handleCancel}
      bottomStuck
      sx={{
        ...buttonStyle,
        ...sx,
      }}>
      {localized('Cancel')}
    </StyledButton>
  );
});
