import React, { FC } from 'react';
import { Switch, SwitchProps, SxProps } from '@mui/material';
import { buttonColor, primaryTextColor, switchThumbUnchecked } from '../../../styles/color-constants';

const switchStyle: SxProps = {
  height: 50,
  width: 100,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: '3px',
    transitionDuration: '330ms',
    '&.Mui-checked': {
      transform: 'translateX(50px)',
      '& .MuiSwitch-thumb': {
        color: buttonColor,
      },
      '& + .MuiSwitch-track': {
        backgroundColor: 'white',
        opacity: 1,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: '#9A4613',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    height: 44,
    width: 44,
    color: switchThumbUnchecked,
    transition: 'color 330ms',
  },
  '& .MuiSwitch-track': {
    borderRadius: 25,
    opacity: 1,
    backgroundColor: 'white',
    '&:before, &:after': {
      position: 'absolute',
      top: '20%',
      height: 25,
      width: 25,
      fontSize: '20px',
      color: primaryTextColor,
      opacity: 1,
    },
    '&:before': {
      left: 13,
      content: '"ON"',
    },
    '&:after': {
      right: 20,
      content: '"OFF"',
    },
  },
};

export const LargeSwitch: FC<SwitchProps> = React.memo((props) => {
  return <Switch sx={switchStyle} {...props} disableRipple />;
});
