import { SxProps, Typography } from '@mui/material';
import React, { FC } from 'react';
import { localized } from '../../../i18n/i18n';
import { theme } from '../../../styles/theme';
import { PageHeader } from '../../components/default-components/page-header.tsx/page-header';
import { PageTitle } from '../../components/default-components/page-title/page-title';
import { MainPageContainer } from '../../components/specific-components/containers/main-page-container';
import { RightSideBar } from '../../components/specific-components/right-side-bar';
import { GoToFAQ } from '../../components/specific-components/help/go-to-faq';
import { KnowMyWayAround } from '../../components/specific-components/help/know-my-way-around';
import { TutorialList } from '../../components/specific-components/tutorial/tutorial-list';
import { BeforeYouGetStarted } from '../../components/specific-components/help/before-you-get-started';
export const styles: { [key: string]: SxProps } = {
  metaText: {
    fontSize: theme.typography.body1,
    marginBottom: 3,
  },
  metaTextBold: {
    fontSize: theme.typography.body1,
    marginBottom: 3,
    fontWeight: 'bold',
  },
};

export const Help: FC = React.memo(() => {
  return (
    <RightSideBar>
      <PageTitle title={localized('HelpAndTutorials')}>
        <PageHeader area="help" title={localized('HelpAndTutorials')}></PageHeader>
        <MainPageContainer>
          <Typography sx={styles.metaText} whiteSpace={'pre-line'}>
            {localized('HelpMetaText1')}
          </Typography>
          <BeforeYouGetStarted />
          <Typography sx={styles.metaTextBold} whiteSpace={'pre-line'}>
            {localized('HelpMetaText2')}
          </Typography>
          <Typography sx={styles.metaText} whiteSpace={'pre-line'}>
            {localized('HelpMetaText3')}
          </Typography>
          <TutorialList />
          <KnowMyWayAround />
          <GoToFAQ />
        </MainPageContainer>
      </PageTitle>
    </RightSideBar>
  );
});
