import { Typography } from '@mui/material';
import React from 'react';
import { ACTIONS, CallBackProps, Step } from 'react-joyride';
import { NavigateFunction } from 'react-router-dom';
import { Dispatch } from 'redux';
import { localized, localizedInterpolation } from '../../../../../i18n/i18n';
import {
  AddAndManageUsersStepTargets,
  asClassnameRef,
  asIdRef,
} from '../../../../../utilities/enums/tutorial-steps-target';
import {
  defaultStepConfigs,
  defaultTutorialState,
  resetTriggered,
  successfulBackward,
  successfulForward,
} from '../../../../../utilities/helpers/tutorial-helper';
import { TutorialState } from '../../../../../utilities/providers/tutorial-provider';

export const addAndManageUsersLogic = (
  data: CallBackProps,
  navigate: NavigateFunction,
  dispatch: Dispatch<any>,
  setState: (patch: Partial<TutorialState> | ((prevState: TutorialState) => Partial<TutorialState>)) => void,
) => {
  const { status, action, index, lifecycle } = data;
  const nextStep = action === ACTIONS.NEXT ? index + 1 : index - 1;
  let buttonStatus = { showContinue: true, showBack: true };

  if (successfulForward(action, lifecycle)) {
    switch (nextStep) {
      case 1:
        return;
      case 2:
        buttonStatus.showContinue = false;
        buttonStatus.showBack = true;
        break;
      case 5:
      case 6:
      case 7:
        buttonStatus.showContinue = false;
        break;
      case 8:
        buttonStatus.showBack = false;
        break;
    }

    setState({
      stepIndex: nextStep,
      buttonStatus: buttonStatus,
    });
  } else if (successfulBackward(action, lifecycle)) {
    switch (nextStep) {
      case 1:
      case 2:
        buttonStatus.showBack = false;
        break;
      case 5:
        buttonStatus.showContinue = false;
        break;
      case 6:
        // Breaks if we go back to selector list, because it's not mountet
        setState({
          stepIndex: 5,
          buttonStatus: {
            showContinue: false,
            showBack: true,
          },
        });
        return;
      case 8:
        buttonStatus.showBack = false;
        break;
    }

    setState({
      stepIndex: nextStep,
      buttonStatus: buttonStatus,
    });
  } else if (resetTriggered(status, action, lifecycle)) {
    setState(defaultTutorialState);
  }
};

export const addAndManageUsersSteps: Step[] = [
  {
    ...defaultStepConfigs,
    title: (
      <Typography fontWeight={'bold'}>
        {localizedInterpolation('TutorialStep1Title', { menuItem: localized('Users') })}
      </Typography>
    ),
    target: asIdRef(AddAndManageUsersStepTargets.Step1),
  },
  {
    ...defaultStepConfigs,
    title: <Typography fontWeight={'bold'}>{localized('AddAndManageUsersStep2Title')}</Typography>,
    content: <Typography>{localized('AddAndManageUsersStep2Content')}</Typography>,
    target: asIdRef(AddAndManageUsersStepTargets.Step2),
  },
  {
    ...defaultStepConfigs,
    title: <Typography fontWeight={'bold'}>{localized('AddAndManageUsersStep3Title')}</Typography>,
    target: asIdRef(AddAndManageUsersStepTargets.Step3),
  },
  {
    ...defaultStepConfigs,
    title: <Typography fontWeight={'bold'}>{localized('AddAndManageUsersStep4Title')}</Typography>,
    target: asIdRef(AddAndManageUsersStepTargets.Step4),
  },
  {
    ...defaultStepConfigs,
    title: <Typography fontWeight={'bold'}>{localized('AddAndManageUsersStep5Title')}</Typography>,
    target: asIdRef(AddAndManageUsersStepTargets.Step5),
  },
  {
    ...defaultStepConfigs,
    title: <Typography fontWeight={'bold'}>{localized('AddAndManageUsersStep6Title')}</Typography>,
    target: asIdRef(AddAndManageUsersStepTargets.Step6),
  },
  {
    ...defaultStepConfigs,
    title: <Typography fontWeight={'bold'}>{localized('AddAndManageUsersStep6Title')}</Typography>,
    // Classname because we want multiple targets in selector',
    target: asClassnameRef(AddAndManageUsersStepTargets.Step7),
  },
  {
    ...defaultStepConfigs,
    title: <Typography fontWeight={'bold'}>{localized('AddAndManageUsersStep8Title')}</Typography>,
    target: asIdRef(AddAndManageUsersStepTargets.Step8),
  },
  {
    ...defaultStepConfigs,
    title: <Typography fontWeight={'bold'}>{localized('AddAndManageUsersStep9Title')}</Typography>,
    target: asIdRef(AddAndManageUsersStepTargets.Step9),
    spotlightClicks: false,
  },
  {
    ...defaultStepConfigs,
    title: (
      <Typography fontWeight={'bold'}>
        {localizedInterpolation('TutorialLastStepTitle', { item: localized('User').toLowerCase() })}
      </Typography>
    ),
    content: <Typography>{localized('TutorialLastStepContent')}</Typography>,
    target: asIdRef(AddAndManageUsersStepTargets.LastStep),
    placement: 'center',
  },
];
