import { createSlice } from '@reduxjs/toolkit';
import { getTimeZones } from './operations';
import { TimeZoneSlice as TimeZoneState } from './types';

const initialState: TimeZoneState = {
  timezones: [],
  pending: false,
};
export const timeZoneSlice = createSlice({
  name: 'timezone',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTimeZones.fulfilled, (state, action) => {
      state.timezones = action.payload;
      state.pending = false;
    });
    builder.addCase(getTimeZones.pending, (state) => {
      state.pending = true;
    });
    builder.addCase(getTimeZones.rejected, (state) => {
      state.pending = false;
    });
  },
});

export default timeZoneSlice.reducer;
