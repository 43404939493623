import React, { FC, useCallback, useMemo, useState } from 'react';
import { FileResponse, IDeviceDataDumpDTO } from '../../../../../api/api';
import { localized } from '../../../../../i18n/i18n';
import { StyledLoadingButton } from '../../buttons/styled-loading-button';
import { useDownloadFile } from '../../../../../services/download-helper';
import CircularProgress from '@mui/material/CircularProgress';
import { FlexColumn } from '../../../default-components/flex-column';

interface Props {
  dataDumpData: IDeviceDataDumpDTO;
}

export const DataDumpDownload: FC<Props> = React.memo(({ dataDumpData }) => {
  const [downloading, setDownloading] = useState(false);

  const preDownloading = useCallback(() => {
    setDownloading(true);
  }, []);

  const postDownloading = useCallback(() => {
    setDownloading(false);
  }, []);

  const onError = useCallback(() => {
    setDownloading(false);
  }, []);

  const apiDefinition = useCallback(() => {
    const blob = new Blob([JSON.stringify(dataDumpData)], { type: 'application/json' });
    return Promise.resolve<FileResponse>({ data: blob, status: 200, fileName: 'dataDump.json' });
  }, [dataDumpData]);

  const { download } = useDownloadFile({
    apiDefinition,
    preDownloading,
    postDownloading,
    onError,
  });

  const handleDownload = useCallback(() => {
    download();
  }, [download]);

  const progress = useMemo(() => {
    if (dataDumpData === undefined) return 0;

    // @ts-ignore
    return (Object.keys(dataDumpData).filter((x) => dataDumpData[x] !== undefined).length * 100) / 5;
  }, [dataDumpData]);

  return (
    <FlexColumn alignItems={'center'}>
      {!Object.values(dataDumpData).includes(undefined) ? (
        <StyledLoadingButton loading={downloading} onClick={handleDownload}>
          {localized('DownloadDataDump')}
        </StyledLoadingButton>
      ) : (
        <CircularProgress color={'inherit'} variant={'determinate'} value={progress} />
      )}
    </FlexColumn>
  );
});
