import { DialogActions, DialogTitle } from '@mui/material';
import React, { ReactNode, createContext, memo, useCallback, useContext, useRef, useState } from 'react';
import { localized } from '../../i18n/i18n';
import { BasicButton } from '../../view/components/default-components/buttons/basic-button';
import { BasicModal } from '../../view/components/default-components/modal/basic-modal';

interface IDialogContext {
  openDeleteDialog: (title: string, onConfirm: () => void, onCancel?: () => void) => void;
}

const initialState: IDialogContext = {
  openDeleteDialog: () => {},
};

interface PropsFromParent {
  children: ReactNode;
}

export const DialogContext = createContext(initialState);

export const useDialog = (): IDialogContext => useContext(DialogContext);

export const DialogProvider = memo<PropsFromParent>(({ children }) => {
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [title, setTitle] = useState<string>();
  const onConfirmCallback = useRef<() => void>(() => {});
  const onCancelCallback = useRef<(() => void) | undefined>(undefined);

  const openDeleteDialog = useCallback((title: string, onConfirm: () => void, onCancel?: () => void) => {
    onConfirmCallback.current = onConfirm;
    onCancelCallback.current = onCancel;
    setTitle(title);
    setShowDialog(true);
  }, []);

  const onDismiss = useCallback(() => {
    setShowDialog(false);
    onCancelCallback.current = undefined;
  }, []);

  const onConfirmLocal = useCallback(() => {
    onConfirmCallback.current();
    onDismiss();
  }, [onDismiss]);

  const onCancelLocal = useCallback(() => {
    onCancelCallback.current && onCancelCallback.current();
    onDismiss();
  }, [onDismiss]);

  return (
    <DialogContext.Provider value={{ openDeleteDialog }}>
      {children}
      <BasicModal open={showDialog}>
        <DialogTitle>{title}</DialogTitle>
        <DialogActions>
          <BasicButton onClick={onCancelLocal} text={localized('Cancel')} buttonColor={'secondary'} />
          <BasicButton onClick={onConfirmLocal} text={localized('Delete')} buttonColor={'error'} />
        </DialogActions>
      </BasicModal>
    </DialogContext.Provider>
  );
});
