import { Divider, Typography } from '@mui/material';
import React, { FC, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IDeviceConfigDTO, IDeviceInfoDTO } from '../../../../api/api';
import { ReactComponent as AlertIcon } from '../../../../assets/icons/alert-icon.svg';
import { ReactComponent as CleaningIcon } from '../../../../assets/icons/cleaning-icon.svg';
import { ReactComponent as TimeIcon } from '../../../../assets/icons/time-icon.svg';
import { useAuth } from '../../../../auth/auth-provider';
import { localized } from '../../../../i18n/i18n';
import { insertDeviceConfig, updateDeviceConfig } from '../../../../state/features/devices/operation';
import { AppState } from '../../../../state/store';
import { sideBarDividerColor } from '../../../../styles/color-constants';
import { numberValidator } from '../../../../utilities/platform-helpers/validator-helper';
import { FlexColumn } from '../../default-components/flex-column';
import { Form } from '../../default-components/form/form';
import { StyledButton } from '../buttons/styled-button';
import { RightSideContainer } from '../containers/right-side-container';
import { LargeFormSwitch } from '../form/large-form-switch';
import { LargeTextInput } from '../form/large-text-input';
import { useRightSidebar } from '../right-side-bar';
import { SideBarStack } from '../side-bar-stack';
import { SidebarCancelButton } from '../sidebar-cancel-button';
import { AdminUnitSettings } from './unit-config/admin-unit-settings/admin-unit-settings';
import { GeneralUnitConfigRow } from './unit-config/general-unit-config-row';

interface Props {
  device: IDeviceInfoDTO;
}

export const UnitsSettings: FC<Props> = React.memo((props) => {
  const { device } = props;
  const { isGlobalAdmin } = useAuth();
  const dispatch = useDispatch();
  const setSidebarContent = useRightSidebar();
  const { pending } = useSelector((store: AppState) => store.devicesReducer);
  const handleDeviceConfigSubmit = useCallback(
    (data: { usageTimes: string; prolongedUseAlertTimeout: string; alertActive: boolean }) => {
      if (device.deviceConfig) {
        device.deviceConfig.usagesBetweenCleaning = +data.usageTimes;
        device.deviceConfig.prolongedUseAlertTimeoutMinutes = +data.prolongedUseAlertTimeout;
        device.deviceConfig.prolongedUseAlertSound = data.alertActive;
        dispatch(updateDeviceConfig(device.deviceConfig));
      } else {
        const newDeviceConfig = {
          deviceId: device.id,
          usagesBetweenCleaning: +data.usageTimes,
          prolongedUseAlertTimeoutMinutes: +data.prolongedUseAlertTimeout,
          prolongedUseAlertSound: data.alertActive,
        } as IDeviceConfigDTO;
        dispatch(insertDeviceConfig(newDeviceConfig));
      }
      setSidebarContent(null);
    },
    [dispatch, device, setSidebarContent],
  );

  const defaultValues = useMemo(
    () => ({
      usageTimes: device.deviceConfig?.usagesBetweenCleaning ?? 1,
      prolongedUseAlertTimeout: device.deviceConfig?.prolongedUseAlertTimeoutMinutes ?? 60,
      alertActive: device.deviceConfig?.prolongedUseAlertSound ?? true,
    }),
    [device.deviceConfig],
  );

  return (
    <RightSideContainer>
      <Form onSubmit={handleDeviceConfigSubmit} maxHeight defaultValues={defaultValues}>
        <SideBarStack>
          <FlexColumn>
            <Typography fontSize={'16px'} fontWeight={200}>
              {localized('Settings')}
            </Typography>
            <Typography fontSize={'30px'} fontWeight={'bold'}>
              {device.connectId}
            </Typography>
          </FlexColumn>
          <GeneralUnitConfigRow
            icon={<CleaningIcon width={'48px'} />}
            description={localized('CleaningTimesDescription')}
            input={
              <LargeTextInput
                name="usageTimes"
                validators={{ ...numberValidator, min: 1 }}
                label={localized('Times')}
                sx={{ width: '100px' }}
                type={'number'}
              />
            }
          />
          <GeneralUnitConfigRow
            icon={<TimeIcon width={'48px'} />}
            description={localized('ProlongedUseTimeoutDescription')}
            input={
              <LargeTextInput
                name="prolongedUseAlertTimeout"
                validators={{ ...numberValidator, min: 30 }}
                label={localized('Minutes')}
                sx={{ width: '100px' }}
                type={'number'}
              />
            }
          />
          <GeneralUnitConfigRow
            icon={<AlertIcon width={'48px'} />}
            description={localized('ProlongedUseToggleDescription')}
            input={<LargeFormSwitch name="alertActive" />}
          />
        </SideBarStack>
        <Divider sx={{ backgroundColor: sideBarDividerColor, marginY: '20px' }} />
        {isGlobalAdmin && <AdminUnitSettings device={device} />}
        <StyledButton type="submit" bottomStuck disabled={pending}>
          {localized('SaveSettings')}
        </StyledButton>
        <SidebarCancelButton />
      </Form>
    </RightSideContainer>
  );
});
