import {Box, Button, SxProps} from '@mui/material';
import React, {FC} from 'react';
import {
  buttonColor,
  primaryTextColor,
  secondaryColor,
  buttonDisabledColor,
  buttonDisabledBorderColor,
  whiteColor,
} from '../../../../styles/color-constants';

const styles: {[key: string]: SxProps} = {
  button: {
    marginTop: '10px',
    minHeight: '70px',
    fontSize: '20px',
    color: secondaryColor,
    backgroundColor: buttonColor,
    border: `3px solid ${buttonDisabledBorderColor}`,
    textTransform: 'none',
    fontWeight: '400',
    ':hover': {
      backgroundColor: whiteColor,
      color: primaryTextColor,
    },
    '&:disabled': {
      backgroundColor: buttonDisabledColor,
      color: primaryTextColor,
    },
    display: 'flex',
    justifyContent: 'flex-start',
    boxShadow: 'none',
    padding: 0,
  },
  boxIcon: {
    width: '104px',
    height: '70px',
    marginTop: 'auto',
    marginBottom: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  boxText: {
    marginLeft: '0px',
  },
  boxTextSolo: {
    marginLeft: '28px',
  },
};

interface Props {
  icon?: JSX.Element;
  endIcon?: boolean;
  value?: string;
  disabled?: boolean;
  onClick?: any;
}

export const SelectionButton: FC<Props> = React.memo((props) => {
  return (
    <Button
      variant="contained"
      sx={styles.button}
      onClick={props.onClick}
      value={props.value}
      disabled={props.disabled}>
      {props.icon && !props.endIcon && <Box sx={styles.boxIcon}>{props.icon}</Box>}
      <Box sx={props.icon && !props.endIcon ? styles.boxText : styles.boxTextSolo}>{props.children}</Box>
      {props.icon && props.endIcon && <Box sx={styles.boxIcon}>{props.icon}</Box>}
    </Button>
  );
});
