import { FileResponse } from '../api/api';
import React, { useCallback, useRef, useState } from 'react';

// Taken from https://www.techprescient.com/react-custom-hook-typescript-to-download-a-file-through-api/
interface DownloadFileProps {
  readonly apiDefinition: () => Promise<FileResponse | null>;
  readonly preDownloading?: () => void;
  readonly postDownloading?: () => void;
  readonly onError?: () => void;
}

interface DownloadedFileInfo {
  readonly download: () => Promise<void>;
  readonly ref: React.MutableRefObject<HTMLAnchorElement | null>;
  readonly name: string | undefined;
  readonly url: string | undefined;
  readonly loading: boolean;
}

export const useDownloadFile = ({
  apiDefinition,
  preDownloading,
  postDownloading,
  onError,
}: DownloadFileProps): DownloadedFileInfo => {
  const ref = useRef<HTMLAnchorElement | null>(null);
  const [fileUrl, setFileUrl] = useState<string>();
  const [name, setFileName] = useState<string>();
  const [loading, setLoading] = useState(false);

  const preDownloadingLocal = useCallback(() => {
    if (preDownloading) preDownloading();
    setLoading(true);
  }, []);

  const postDownloadingLocal = useCallback(() => {
    if (postDownloading) postDownloading();
    setLoading(false);
  }, []);

  const onErrorLocal = useCallback(() => {
    if (onError) onError();
    setLoading(false);
  }, []);

  const download = async () => {
    try {
      preDownloadingLocal();
      const file = (await apiDefinition()) as FileResponse;
      const { data, fileName } = file;
      const fileNameDecoded = decodeURIComponent(fileName?.replaceAll('+', ' ') ?? '');
      setFileName(fileNameDecoded);
      const url = URL.createObjectURL(data);
      promptUserWithFileOptions(fileNameDecoded ?? '', url);
      postDownloadingLocal();

      // Clean up the URL object after 10 seconds
      setTimeout(() => {
        URL.revokeObjectURL(url);
      }, 10000);
    } catch (error) {
      console.error(error);
      onErrorLocal();
    }
  };

  return { download, ref, url: fileUrl, name, loading };
};

const promptUserWithFileOptions = (filename: string, url: string) => {
  const link = document.createElement('a');
  link.href = url;
  link.target = '_blank';
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
