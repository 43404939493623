import { ILocationDTO } from '../../api/api';

export const roomToLocationString = (locationInfo: ILocationDTO | undefined) => {
  if (locationInfo) {
    return `${locationInfo.room}, ${locationInfo.floor}, ${locationInfo.street}, ${locationInfo.city}`;
  }
  return '';
};

export const locationToString = (locationInfoDTO: ILocationDTO | undefined) => {
  if (locationInfoDTO === undefined) {
    return '';
  }
  return `${locationInfoDTO.zipCode}, ${locationInfoDTO.city}, ${locationInfoDTO.street}, ${locationInfoDTO.floor}, ${locationInfoDTO.room}`;
};
