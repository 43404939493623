import { Grid, useMediaQuery } from '@mui/material';
import React, { FC, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useLayoutStyles } from './app-routes';
import { useAuth } from './auth/auth-provider';
import { routes } from './state/routes';
import { TutorialProvider } from './utilities/providers/tutorial-provider';
import { OverlaySpinner } from './view/components/default-components/spinner/overlay-spinner';
import { SideMenu } from './view/navigation/desktop/side-menu';
import { GeneralTargets } from './utilities/enums/tutorial-steps-target';

export const AppLayout: FC = () => {
  const classes = useLayoutStyles();
  const wide = useMediaQuery('(min-width:1560px)');
  const leftMargin = wide ? 150 : 80;

  const { isAuthenticated } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // VERY IMPORTANT TO NOT NAVIGATE BEFORE USER IS AUTHENTICATED
    if (location.pathname === '/' && isAuthenticated) {
      navigate(routes.insights);
    }
  }, [location.pathname, navigate, isAuthenticated]);

  // Don't render any components before auth has been initialized
  if (isAuthenticated === undefined) {
    return null;
  }

  return (
    <div id={GeneralTargets.Body}>
      <TutorialProvider>
        <Grid wrap="nowrap" container={true}>
          <SideMenu />
          <OverlaySpinner />
          <div className={classes.appContent} style={{ marginLeft: leftMargin }}>
            <Outlet />
          </div>
        </Grid>
      </TutorialProvider>
    </div>
  );
};
