import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  NotificationSubscription,
  OrganizationJob,
  OrganizationRole,
  OrganizationUser,
  User,
  UserRole,
} from '../../../../api/api';
import { localized } from '../../../../i18n/i18n';
import { selectOrganization } from '../../../../state/features/settings/settings-slice';
import { getUserById, saveUser, updateUser } from '../../../../state/features/users/operation';
import { selectUserById } from '../../../../state/features/users/users-slice';
import { AppState } from '../../../../state/store';
import { organizationRoleToString } from '../../../../utilities/helpers/organization-enum-to-string';
import { FlexRow } from '../../../components/default-components/flex-row';
import { Form } from '../../../components/default-components/form/form';
import { StyledButton } from '../../../components/specific-components/buttons/styled-button';
import { RightSideContainer } from '../../../components/specific-components/containers/right-side-container';
import { useRightSidebar } from '../../../components/specific-components/right-side-bar';
import { SidebarCancelButton } from '../../../components/specific-components/sidebar-cancel-button';
import { UserFormFields } from '../../../components/specific-components/users/user-form-fields';
import { AddAndManageUsersStepTargets } from '../../../../utilities/enums/tutorial-steps-target';
import { useTutorial } from '../../../../utilities/providers/tutorial-provider';

interface Props {
  userId?: number;
  editEmail?: boolean;
  role?: OrganizationRole;
}

export const EditUserProfile: FC<Props> = React.memo((props) => {
  const { userId } = props;
  const dispatch = useDispatch();
  const setSidebarContent = useRightSidebar();
  const { pending } = useSelector((store: AppState) => store.usersReducer);
  const dbUser = useSelector(selectUserById(userId));
  const selectedOrganization = useSelector(selectOrganization);
  const {
    setTutorialState,
    tutorialState: { tourActive },
  } = useTutorial();

  const [job, setJob] = useState<OrganizationJob>(OrganizationJob.Other);
  const typeName = useMemo(() => organizationRoleToString(props.role).toLowerCase(), [props.role]);

  const organizationUser = useMemo(() => {
    if (dbUser && selectedOrganization) {
      return dbUser?.organizationUser?.find((orgUser) => orgUser.organizationId === selectedOrganization.id);
    }
  }, [selectedOrganization, dbUser]);

  useEffect(() => {
    if (!userId) return;
    dispatch(getUserById(userId));
  }, [dispatch, userId]);

  useEffect(() => {
    if (dbUser) {
      setJob(organizationUser?.organizationJob ?? 0);
    }
  }, [dbUser, organizationUser]);

  const saveEdit = useCallback(
    (data: any) => {
      if (dbUser) {
        const updatedUser = { ...dbUser, ...data } as User;
        updatedUser.organizationUser = dbUser.organizationUser?.map((orgUser) => {
          if (orgUser.organizationId === selectedOrganization?.id) {
            orgUser.organizationJob = job;
          }
          return orgUser;
        });
        dispatch(updateUser(updatedUser));
      } else {
        const newUser = { ...data } as User;
        newUser.role = UserRole.User;
        newUser.notificationSubscription = new NotificationSubscription();
        newUser.organizationUser = [
          {
            organizationId: selectedOrganization?.id,
            organizationRole: props.role,
            organizationJob: job,
          } as OrganizationUser,
        ];
        dispatch(saveUser(newUser));

        if (!tourActive) return;
        setTimeout(() => {
          setTutorialState({ stepIndex: 8 });
        }, 500);
      }
      setSidebarContent(null);
    },
    [dbUser, setSidebarContent, dispatch, selectedOrganization?.id, job, props.role, tourActive, setTutorialState],
  );

  // Can't handle changing user to edit without finishing edit of current user
  const defaultValues = useMemo(() => {
    return dbUser;
  }, [dbUser]);

  return (
    <RightSideContainer>
      <FlexRow>
        <h1>{props.userId ? localized('EditUserProfile') : `${localized('AddNew')} ${typeName}`}</h1>
      </FlexRow>
      <Form onSubmit={saveEdit} maxHeight defaultValues={defaultValues}>
        <UserFormFields
          role={organizationUser?.organizationRole ?? props.role}
          job={job}
          setJob={setJob}
          createMode={!userId || props.role === OrganizationRole.Maintenance}
        />
        <StyledButton type="submit" bottomStuck disabled={pending} id={AddAndManageUsersStepTargets.Step8}>
          {userId || props.role === OrganizationRole.Maintenance ? localized('SaveChanges') : localized('SendInvite')}
        </StyledButton>
        <SidebarCancelButton />
      </Form>
    </RightSideContainer>
  );
});
