import { createAsyncThunk } from '@reduxjs/toolkit';
import { ToggleLoggingData } from '../devices/types';
import { AsyncOperationHandler } from '../../../utilities/platform-helpers/api-helper';
import { DeviceDebuggingClient, FirmwareClient } from '../../../api/api';
import { deviceDebuggingClient, firmwareClient } from '../../api-clients';
import { GetDataDumpParams } from './types';

export const toggleDebugLogging = createAsyncThunk('adminDevice/toggleDebugLogging', (data: ToggleLoggingData) => {
  const { connectId, enableLogging } = data;
  return AsyncOperationHandler(
    (client) => (client as DeviceDebuggingClient).toggleDebugLogging(connectId, enableLogging),
    deviceDebuggingClient,
  );
});

export const requestDataDump = createAsyncThunk('adminDevice/requestDataDump', (connectId: string) => {
  return AsyncOperationHandler(
    (client) => (client as DeviceDebuggingClient).requestDataDump(connectId),
    deviceDebuggingClient,
    true,
    'DataDumpStarted',
  );
});

export const getDataDumpData = createAsyncThunk('adminDevice/getDataDump', (params: GetDataDumpParams) => {
  const { databaseId, from } = params;
  return AsyncOperationHandler(
    (client) => (client as DeviceDebuggingClient).getDataDumpData(databaseId, from),
    deviceDebuggingClient,
  );
});

export const getDeviceFirmwareVersion = createAsyncThunk('adminDevice/getFirmwareVersion', (connectId: string) => {
  return AsyncOperationHandler(
    (client) => (client as FirmwareClient).getDeviceFirmwareVersion(connectId),
      firmwareClient,
  );
});

export const getFirmwareUpdateTimestamp = createAsyncThunk('adminDevice/getFirmwareTimestamp', (connectId: string) => {
  return AsyncOperationHandler((client) => (client as FirmwareClient).getFWUpdateTimestamp(connectId), firmwareClient);
});

export const getNewOrEqualFirmwaresForDevice = createAsyncThunk(
  'adminDevice/getNewOrEqualFirmwaresForDevice',
  (connectId: string) => {
    return AsyncOperationHandler(
      (client) => (client as FirmwareClient).getNewOrEqualFirmwaresForDevice(connectId),
        firmwareClient,
    );
  },
);
