import { ACTIONS, Actions, LIFECYCLE, Lifecycle, STATUS, Status, Step } from 'react-joyride';
import { TutorialState } from '../providers/tutorial-provider';

export const defaultTutorialState: TutorialState = {
  run: false,
  stepIndex: 0,
  steps: [],
  tutorialLogic: () => {},
  tourActive: false,
  buttonStatus: {
    showContinue: false,
    showBack: false,
  },
};

export const defaultStepConfigs: Step = {
  disableBeacon: true,
  disableScrolling: true,
  disableOverlayClose: true,
  spotlightClicks: true,
  placement: 'auto',
};

export const completedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED, STATUS.ERROR];
export const completedActions: string[] = [ACTIONS.RESET, ACTIONS.CLOSE];
export const completedLifecycle: string[] = [LIFECYCLE.ERROR, LIFECYCLE.COMPLETE];

export const resetTriggered = (status: Status, action: Actions, lifecycle: Lifecycle) => {
  return (
    completedStatuses.includes(status) || completedActions.includes(action) || completedLifecycle.includes(lifecycle)
  );
};
export const successfulForward = (action: Actions, lifecycle: Lifecycle) => {
  return action === ACTIONS.NEXT && lifecycle === LIFECYCLE.COMPLETE;
};
export const successfulBackward = (action: Actions, lifecycle: Lifecycle) => {
  return action === ACTIONS.PREV && lifecycle === LIFECYCLE.COMPLETE;
};
export const successfulSideMenuForward = (nextStepIndex: number, action: Actions) => {
  return nextStepIndex === 1 && action === ACTIONS.NEXT;
};
