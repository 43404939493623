import { createSelector, createSlice, isAnyOf, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import { AppState } from '../../store';
import { toggleDebugLogging } from '../admin-device/operation';
import { setOrganizationSetting } from '../settings/settings-slice';
import {
  configureDevice,
  getDeviceByConnectId,
  getDeviceTwinState,
  getDevicesFull,
  insertDeviceConfig,
  removeConfiguration,
  updateDeviceConfig,
  updateDeviceFirmware,
} from './operation';
import { DevicesState } from './types';

const initialState: DevicesState = {
  devices: [] as any,
  activeDeviceState: undefined,
  pending: false,
  updateFirmwarePending: false,
  isSuccess: false,
};

export const devicesSlice = createSlice({
  name: 'devices',
  initialState,
  reducers: {
    resetActiveDevice: (state) => {
      state.activeDeviceState = undefined;
    },
  },
  extraReducers: (builder) => {
    // GetDevicesFull
    builder.addCase(getDevicesFull.fulfilled, (state, action) => {
      state.devices = action.payload;
    });

    // GetByConnectId
    builder.addCase(getDeviceByConnectId.fulfilled, (state, action) => {
      if (action.payload) {
        const index = state.devices.findIndex((d) => d.id === action.payload.id);
        if (index === -1) {
          state.devices = [...state.devices, action.payload];
        }
      }
    });

    // Configure device
    builder.addCase(configureDevice.fulfilled, (state, action) => {
      state.isSuccess = true;
      const index = state.devices.findIndex((d) => d.id === action.payload.id);
      if (index > -1) {
        state.devices[index] = action.payload;
      } else {
        // This is needed as the setup wizard component treats update like an upsert
        state.devices.push(action.payload);
      }
    });

    // Remove configuration
    builder.addCase(removeConfiguration.fulfilled, (state, action) => {
      state.isSuccess = true;
      const index = state.devices.findIndex((d) => d.id === action.payload.id);
      if (index > -1) {
        state.devices[index] = action.payload;
      }
    });

    // UpdateFirmware
    builder.addCase(updateDeviceFirmware.fulfilled, (state) => {
      state.updateFirmwarePending = false;
    });

    builder.addCase(updateDeviceFirmware.pending, (state) => {
      state.updateFirmwarePending = true;
    });

    builder.addCase(updateDeviceFirmware.rejected, (state) => {
      state.updateFirmwarePending = false;
    });

    // EnableExtendedDebugging
    builder.addCase(toggleDebugLogging.fulfilled, (state, action) => {
      if (state.activeDeviceState) {
        state.activeDeviceState.extendedGeneralLogging = action.meta.arg.enableLogging;
      }
    });

    // GetDeviceTwinState
    builder.addCase(getDeviceTwinState.fulfilled, (state, action) => {
      state.activeDeviceState = action.payload;
    });

    // Resets when new organization is selected
    builder.addCase(setOrganizationSetting, (state) => {
      state.devices = initialState.devices;
    });

    // Insert DeviceConfig
    builder.addCase(insertDeviceConfig.fulfilled, (state, action) => {
      const index = state.devices.findIndex((d) => d.id === action.payload.deviceId);
      if (index > -1) {
        state.devices[index] = { ...state.devices[index], deviceConfig: action.payload };
      }
    });

    // Update DeviceConfig
    builder.addCase(updateDeviceConfig.fulfilled, (state, action) => {
      const index = state.devices.findIndex((d) => d.id === action.payload.deviceId);
      if (index > -1) {
        state.devices[index] = { ...state.devices[index], deviceConfig: action.payload };
      }
    });
    builder.addMatcher(
      isAnyOf(
        isRejected(
          getDevicesFull,
          getDeviceByConnectId,
          removeConfiguration,
          toggleDebugLogging,
          getDeviceTwinState,
          insertDeviceConfig,
          updateDeviceConfig,
          configureDevice,
        ),
        isFulfilled(
          getDevicesFull,
          getDeviceByConnectId,
          removeConfiguration,
          toggleDebugLogging,
          getDeviceTwinState,
          insertDeviceConfig,
          updateDeviceConfig,
          configureDevice,
        ),
      ),
      (state) => {
        state.pending = false;
      },
    );
    builder.addMatcher(
      isPending(
        getDevicesFull,
        getDeviceByConnectId,
        removeConfiguration,
        toggleDebugLogging,
        getDeviceTwinState,
        insertDeviceConfig,
        updateDeviceConfig,
        configureDevice,
      ),
      (state) => {
        state.isSuccess = false;
        state.pending = true;
      },
    );
  },
});

export const selectDevices = (store: AppState) => store.devicesReducer.devices;

export const selectDeviceByConnectId = (connectId: string) =>
  createSelector(selectDevices, (devices) => devices.find((d) => d.connectId === connectId));
export const selectDevicesPending = (store: AppState) => store.devicesReducer.pending;
export const selectActiveDeviceState = (store: AppState) => store.devicesReducer.activeDeviceState;
export const selectUpdateFirmwarePending = (state: AppState) => state.devicesReducer.updateFirmwarePending;

export const { resetActiveDevice } = devicesSlice.actions;

export default devicesSlice.reducer;
