export const routes = {
  userManagement: '/user-management',
  userManagementCreate: '/user-management/create',
  userManagementEdit: '/user-management/:userId/edit',

  organization: '/organization',
  organizationCreate: '/organization/create',
  organizationEdit: '/organization/:organizationId/edit',

  template: '/template',

  callback: '/callback',
  loggedOut: '/logged-out',

  noPermission: '/no-permission',

  insights: '/insights',

  users: '/users',
  usersAdministrators: '/users/administrators',
  usersManagers: '/users/managers',
  usersMaintenanceStaff: '/users/maintenanceStaff',

  locations: '/locations',

  units: '/units',

  notifications: '/notifications',

  notificationsLocations: '/notifications/locations',
  notificationsLocationsRooms: '/notifications/locations/rooms',
  notificationsLocationsFloors: '/notifications/locations/floors',
  notificationsLocationsAddresses: '/notifications/locations/addresses',

  notificationsAdministrators: '/notifications/administrators',
  notificationsManagers: '/notifications/managers',
  notificationsMaintenanceStaff: '/notifications/maintenanceStaff',

  help: '/help',
  helpFAQ: '/help/FAQ',
  settings: '/settings',
};

export const defaultRoute = routes.insights;
