import React, { createContext, memo, useCallback, useContext, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { OrganizationRole } from '../api/api';
import { setAccessToken, setAuthPending, setInitLoginSilent } from '../state/features/auth/auth-slice';
import { AppState } from '../state/store';
import { AuthRoles } from '../utilities/constants';
import { getSubFromToken, isTokenExpired } from '../utilities/platform-helpers/auth-helper';
import { usePrevious } from '../utilities/use-previous-hook';
import { firstLoginKey, tokenStorageKey } from './auth-constants';
import { AuthUser, IAuthContext } from './auth-types';
import {
  clientAcquireTokenSilent,
  clientGetUserRole,
  clientLoginSilent,
  clientLoginWithRedirect,
  clientLogout,
  clientUserisGlobalAdmin,
  initAuthClient,
} from './b2c/b2c-auth-provider';

// Initial state for the auth context.
const initialState: IAuthContext = {
  isAuthenticated: undefined,
  user: undefined,
  loading: true,
  isAdmin: false,
  isManager: false,
  isWorker: false,
  isGlobalAdmin: false,
  authId: '',
  loginSilent: () => {},
  login: () => {},
  logout: () => {},
  getToken: () => '',
  validateOrganizationPermissions: () => {},
  tokenExpiredHandling: () => true,
};

const AuthContext = createContext(initialState);
export const useAuth = (): IAuthContext => useContext(AuthContext);

export const GeneralAuthProvider = memo(({ children }) => {
  const { initLogin, initLoginSilent, pending } = useSelector((store: AppState) => store.authReducer);
  // State for holding values related to the user
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | undefined>(undefined);
  const [user, setUser] = useState<AuthUser | undefined>();
  const [authId, setAuthId] = useState<string>('');
  const [token, setToken] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(pending);
  const [isWorker, setIsWorker] = useState(false);
  const [isManager, setIsManager] = useState(false);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [isGlobalAdmin, setIsGlobalAdmin] = useState(false);
  const [triggerLogin, setTriggerLogin] = useState<boolean>(false);
  const [triggerLogout, setTriggerLogout] = useState<boolean>(false);
  const prevTriggerLogin = usePrevious(triggerLogin);
  const prevTriggerLogout = usePrevious(triggerLogout);
  // Special case for api, as AsyncOperationHandler cannot access the useAuth hook.
  const prevInitLogin = usePrevious(initLogin);
  const prevInitLoginSilent = usePrevious(initLoginSilent);
  const dispatch = useDispatch();
  const selectedOrganization = useSelector((store: AppState) => store.settingsReducer.selectedOrganization);
  const [validateOrganizationPermission, setValidateOrganizationPermission] = useState<boolean>(false);
  const activeUser = useSelector((store: AppState) => store.usersReducer.activeUser);
  const setLoadingHelper = useCallback(
    (value: boolean) => {
      setLoading(value);
      dispatch(setAuthPending(value));
    },
    [dispatch],
  );

  const setTokenHelper = useCallback(
    (value: string) => {
      setToken(value);
      localStorage.setItem(tokenStorageKey, value);
      dispatch(setAccessToken(value));
    },
    [dispatch],
  );

  // Callback function for registering the relevant information about the user in the auth provider.
  const handleTokenResponseCallback = useCallback(
    (tokenResponse: string) => {
      setTokenHelper(tokenResponse);
      setAuthId(getSubFromToken()!);
      setIsGlobalAdmin(clientUserisGlobalAdmin());
      setTriggerLogin(false);
      setIsAuthenticated(true);
      setLoadingHelper(false);
      if (authId) {
        localStorage.setItem(firstLoginKey + authId, 'no'); // Value does not mattter, only if the key exists.
      }
    },
    [authId, setTokenHelper, setLoadingHelper],
  );

  // Callback function to reset the authentication state held in the auth provider
  const handleLogoutCallback = useCallback(() => {
    setIsAuthenticated(false);
    setTokenHelper('');
    setAuthId('');
    setUser(undefined);
    setIsAdmin(false);
    setIsGlobalAdmin(false);
    setTriggerLogout(false);
  }, [setTokenHelper]);

  // Responsible for initiating the client used in the service specific auth providers
  // For B2C and AAD this function instantiates the MSAL instance and registers the provided callbacks
  useEffect(() => {
    const initAuth = async () => {
      await initAuthClient(handleTokenResponseCallback, handleLogoutCallback);
    };
    initAuth();
  }, [handleTokenResponseCallback, handleLogoutCallback]);

  // Attempts to authenticate the user silently with at refresh token.
  // If it fails it will initiate a login with redirect automatically
  const loginSilent = useCallback(async () => {
    setLoadingHelper(true);
    await clientLoginSilent();
    dispatch(setInitLoginSilent(false));
  }, [setLoadingHelper, dispatch]);
  const getToken = useCallback((): string => {
    return token ?? localStorage.getItem(tokenStorageKey);
  }, [token]);

  // Initiates the login flow
  const login = useCallback(() => {
    setTriggerLogin(true);
  }, []);

  // Initiates the logout flow
  const logout = useCallback(() => {
    setTriggerLogout(true);
  }, []);

  // Effect comparing prevState of the variable to avoid multiple calls to login
  useEffect(() => {
    if (triggerLogin && triggerLogin !== prevTriggerLogin) {
      setLoadingHelper(true);
      clientLoginWithRedirect();
    }
  }, [triggerLogin, prevTriggerLogin, setLoadingHelper]);

  // Effect comparing prevState of the variable to avoid multiple calls to logout
  useEffect(() => {
    if (triggerLogout && triggerLogout !== prevTriggerLogout) {
      setLoadingHelper(true);
      clientLogout();
    }
  }, [triggerLogout, prevTriggerLogout, setLoadingHelper]);

  // Handlers for refresh token cases from api-helper
  useEffect(() => {
    if (initLogin && initLogin !== prevInitLogin) {
      setLoadingHelper(true);
      clientLoginWithRedirect();
    }
  }, [initLogin, prevInitLogin, setLoadingHelper]);

  useEffect(() => {
    if (initLoginSilent && initLoginSilent !== prevInitLoginSilent) {
      loginSilent();
    }
  }, [initLoginSilent, prevInitLoginSilent, loginSilent]);

  const validateOrganizationPermissions = useCallback(() => {
    setValidateOrganizationPermission(true);
  }, []);

  const tokenExpiredHandling = useCallback((tokenStorageKey: string) => {
    if (isTokenExpired(tokenStorageKey)) {
      clientAcquireTokenSilent(true);
      return true;
    }
    return false;
  }, []);

  useEffect(() => {
    if (user?.tokenRole === AuthRoles.GlobalAdmin) {
      // Override admin permissions if user is global admin
      setIsWorker(true);
      setIsManager(true);
      setIsAdmin(true);
    } else if (activeUser && selectedOrganization) {
      const orgUser = activeUser.organizationUser?.find(
        (orgUser) => orgUser.organizationId === selectedOrganization.id,
      );

      if (orgUser) {
        if (orgUser.organizationRole <= OrganizationRole.Admin) {
          setIsAdmin(true);
        }
        if (orgUser.organizationRole <= OrganizationRole.Manager) {
          setIsManager(true);
        }
        if (orgUser.organizationJob <= OrganizationRole.Maintenance) {
          setIsWorker(true);
        }
      }
    }
    setValidateOrganizationPermission(false);
  }, [selectedOrganization, activeUser, user, setLoadingHelper]);

  useEffect(() => {
    const orgUser = activeUser?.organizationUser?.find((ou) => ou.organizationId === selectedOrganization?.id);
    let authUser: AuthUser = {
      name: activeUser?.name ?? '',
      orgainzationRole: orgUser?.organizationRole,
      tokenRole: clientGetUserRole(),
    };

    // This is done to avoid unnessesary updates in components that subscribe to user changes
    if (!shallowEqual(user, authUser)) {
      setUser(authUser);
    }
  }, [selectedOrganization, activeUser, user, setLoadingHelper]);

  useEffect(() => {
    if (validateOrganizationPermission && user?.tokenRole) {
      // validateOrgPermissionHelper();
    }
  }, [validateOrganizationPermission, user]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        loading,
        user,
        isWorker,
        isManager,
        isAdmin,
        isGlobalAdmin,
        authId,
        login,
        logout,
        loginSilent,
        getToken,
        validateOrganizationPermissions,
        tokenExpiredHandling,
      }}>
      {children}
    </AuthContext.Provider>
  );
});
