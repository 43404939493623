import { Box, BoxProps } from '@mui/material';
import React, { FC } from 'react';

export const FlexRow: FC<BoxProps> = React.memo((props) => {
  return (
    <Box display="flex" flexDirection="row" width={1} {...props}>
      {props.children}
    </Box>
  );
});
