import { Typography } from '@mui/material';
import React from 'react';
import { ACTIONS, CallBackProps, Step } from 'react-joyride';
import { NavigateFunction } from 'react-router-dom';
import { Dispatch } from 'redux';
import { localized, localizedInterpolation } from '../../../../../i18n/i18n';
import {
  DownloadReportStepTargets,
  asClassnameRef,
  asIdRef,
} from '../../../../../utilities/enums/tutorial-steps-target';
import {
  defaultStepConfigs,
  defaultTutorialState,
  resetTriggered,
  successfulBackward,
  successfulForward,
} from '../../../../../utilities/helpers/tutorial-helper';
import { TutorialState } from '../../../../../utilities/providers/tutorial-provider';

export const downloadReportLogic = (
  data: CallBackProps,
  navigate: NavigateFunction,
  dispatch: Dispatch<any>,
  setState: (patch: Partial<TutorialState> | ((prevState: TutorialState) => Partial<TutorialState>)) => void,
) => {
  const { status, action, index, lifecycle } = data;
  const nextStep = action === ACTIONS.NEXT ? index + 1 : index - 1;
  let buttonStatus = { showContinue: true, showBack: true };

  if (successfulForward(action, lifecycle)) {
    switch (nextStep) {
      case 1:
        buttonStatus.showBack = false;
        buttonStatus.showContinue = false;
        break;
      case 2:
        buttonStatus.showBack = false;
        buttonStatus.showContinue = false;
        break;
      case 3:
        buttonStatus.showContinue = false;
        break;
      case 4:
        buttonStatus.showContinue = false;
        break;
      case 5:
        buttonStatus.showContinue = false;
        break;
      case 6:
        buttonStatus.showContinue = false;
        break;
      case 7:
        buttonStatus.showContinue = false;
        break;
      case 8:
        break;
    }

    setState({
      stepIndex: nextStep,
      buttonStatus: buttonStatus,
    });
  } else if (successfulBackward(action, lifecycle)) {
    switch (nextStep) {
      case 1:
        buttonStatus.showBack = false;
        buttonStatus.showContinue = false;
        break;
      case 2:
        setState({
          stepIndex: 1,
          buttonStatus: {
            showContinue: false,
            showBack: true,
          },
        });
        return;
      case 3:
        buttonStatus.showContinue = false;
        break;
      case 4:
        setState({
          stepIndex: 3,
          buttonStatus: {
            showContinue: false,
            showBack: true,
          },
        });
        return;
      case 5:
        buttonStatus.showContinue = false;
        break;
      case 6:
        setState({
          stepIndex: 5,
          buttonStatus: {
            showContinue: false,
            showBack: true,
          },
        });
        return;
      case 7:
        setState({
          stepIndex: 5,
          buttonStatus: {
            showContinue: false,
            showBack: true,
          },
        });
        return;
    }

    setState({
      stepIndex: nextStep,
      buttonStatus: buttonStatus,
    });
  } else if (resetTriggered(status, action, lifecycle)) {
    setState(defaultTutorialState);
  }
};

export const downloadReportStep: Step[] = [
  {
    ...defaultStepConfigs,
    title: (
      <Typography fontWeight={'bold'}>
        {localizedInterpolation('TutorialStep1Title', { menuItem: localized('Insights') })}
      </Typography>
    ),
    target: asIdRef(DownloadReportStepTargets.Step1),
  },
  {
    ...defaultStepConfigs,
    title: <Typography fontWeight={'bold'}>{localized('DownloadReportStep2Title')}</Typography>,
    target: asIdRef(DownloadReportStepTargets.Step2),
  },
  {
    ...defaultStepConfigs,
    title: <Typography fontWeight={'bold'}>{localized('DownloadReportStep2Title')}</Typography>,
    target: asClassnameRef(DownloadReportStepTargets.Step3),
  },
  {
    ...defaultStepConfigs,
    title: <Typography fontWeight={'bold'}>{localized('DownloadReportStep4Title')}</Typography>,
    target: asIdRef(DownloadReportStepTargets.Step4),
  },
  {
    ...defaultStepConfigs,
    title: <Typography fontWeight={'bold'}>{localized('DownloadReportStep4Title')}</Typography>,
    target: asClassnameRef(DownloadReportStepTargets.Step5),
  },
  {
    ...defaultStepConfigs,
    title: <Typography fontWeight={'bold'}>{localized('DownloadReportStep6Title')}</Typography>,
    target: asIdRef(DownloadReportStepTargets.Step6),
  },
  {
    ...defaultStepConfigs,
    title: <Typography fontWeight={'bold'}>{localized('DownloadReportStep6Title')}</Typography>,
    target: asClassnameRef(DownloadReportStepTargets.Step7),
  },
  {
    ...defaultStepConfigs,
    title: <Typography fontWeight={'bold'}>{localized('DownloadReportStep8Title')}</Typography>,
    target: asIdRef(DownloadReportStepTargets.Step8),
  },
  {
    ...defaultStepConfigs,
    title: <Typography fontWeight={'bold'}>{localized('DownloadReportStep9Title')}</Typography>,
    content: <Typography>{localized('TutorialLastStepContent')}</Typography>,
    target: asIdRef(DownloadReportStepTargets.LastStep),
    spotlightClicks: false,
    placement: 'center',
  },
];
