import { createAsyncThunk } from '@reduxjs/toolkit';
import { ILocationDTO, LocationClient, LocationDTO } from '../../../api/api';
import { AsyncOperationHandler } from '../../../utilities/platform-helpers/api-helper';
import { locationClient } from '../../api-clients';

export const getLocationsByOrganizationId = createAsyncThunk(
  'locations/getLocationsByOrganizationId',
  (organizationId: number) => {
    return AsyncOperationHandler(
      (client) => (client as LocationClient).getByOrganizationId(organizationId),
      locationClient,
    );
  },
);

export const createLocation = createAsyncThunk('locations/createLocation', (location: ILocationDTO) => {
  return AsyncOperationHandler(
    (client) => (client as LocationClient).insert(location as LocationDTO),
    locationClient,
    true,
  );
});

export const updateLocation = createAsyncThunk('locations/updateLocation', (location: ILocationDTO) => {
  return AsyncOperationHandler(
    (client) => (client as LocationClient).update(location as LocationDTO),
    locationClient,
    true,
  );
});

export const deleteLocation = createAsyncThunk('locations/deleteLocation', (location: ILocationDTO) => {
  return AsyncOperationHandler(
    (client) => (client as LocationClient).delete(location as LocationDTO),
    locationClient,
    true,
  );
});
