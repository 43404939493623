import {OrganizationJob, OrganizationRole, UserRole} from '../../api/api';
import {localizedDynamic} from '../../i18n/i18n';
import {DropdownOption} from '../../view/components/default-components/basic-dropdown/basic-search-dropdown';

export function getUserRoleDropdownOptions(): DropdownOption[] {
  var options = Object.keys(UserRole)
    .filter((k) => typeof UserRole[k as any] === 'number')
    .map((e) => new DropdownOption(localizedDynamic(e), UserRole[e as any]));

  return options.filter((option) => option.label !== 'Admin');
}

export function getOrganizationRoleDropdownOptions(): DropdownOption[] {
  return Object.keys(OrganizationRole)
    .filter((k) => typeof OrganizationRole[k as any] === 'number')
    .map((e) => new DropdownOption(localizedDynamic(e), OrganizationRole[e as any]));
}

export function getOrganizationJobDropdownOptions(): DropdownOption[] {
  return Object.keys(OrganizationJob)
    .filter((k) => typeof OrganizationJob[k as any] === 'number')
    .map((e) => new DropdownOption(localizedDynamic(e), OrganizationJob[e as any]));
}
