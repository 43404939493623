import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IDeviceCategory, IDeviceConfigDTO, IDeviceModel } from '../../../api/api';
import { getDeviceByConnectId } from '../devices/operation';
import { DeviceSetupStage, DeviceSetupWizardState } from './types';

const initialState: DeviceSetupWizardState = {
  currentStage: 'ConnectId',
  device: undefined,
  category: undefined,
  model: undefined,
  deviceConfig: undefined,
  locationId: undefined,
};

export const deviceSetupWizardSlice = createSlice({
  name: 'deviceSetupWizard',
  initialState: initialState,
  reducers: {
    clearDeviceSetupWizard: () => {
      return initialState;
    },
    setDeviceCategory: (state, action: PayloadAction<IDeviceCategory>) => {
      state.category = action.payload;
      state.currentStage = 'Model';
    },
    setDeviceModel: (state, action: PayloadAction<IDeviceModel>) => {
      state.model = action.payload;
      state.currentStage = 'Location';
    },
    setDeviceLocationId: (state, action: PayloadAction<number>) => {
      state.locationId = action.payload;
      state.currentStage = 'Settings';
    },
    setDeviceConfig: (state, action: PayloadAction<IDeviceConfigDTO>) => {
      if (state.device === undefined) return;

      state.deviceConfig = action.payload;
      state.deviceConfig.usagesBetweenCleaning =
        state.deviceConfig.usagesBetweenCleaning > 0 ? state.deviceConfig.usagesBetweenCleaning : 1;
      state.deviceConfig.deviceId = state.device.id;
    },
    setCurrentStage: (state, action: PayloadAction<DeviceSetupStage>) => {
      state.currentStage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDeviceByConnectId.fulfilled, (state, action) => {
      if (action.payload) {
        state.device = action.payload;
        state.currentStage = 'Product';
      } else {
        state.device = undefined;
      }
    });
    builder.addCase(getDeviceByConnectId.rejected, (state) => {
      state.currentStage = 'ConnectId';
    });
  },
});

export const {
  clearDeviceSetupWizard,
  setDeviceCategory,
  setDeviceModel,
  setDeviceLocationId,
  setCurrentStage,
  setDeviceConfig,
} = deviceSetupWizardSlice.actions;
export default deviceSetupWizardSlice.reducer;
