import ClearIcon from '@mui/icons-material/Clear';
import { Divider, IconButton, List, ListItem, ListItemText, SxProps } from '@mui/material';
import { Box } from '@mui/system';
import React, { FC, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ILocationDTO, INotificationSubscriptionDTO, NotificationSubscription } from '../../../../api/api';
import { useAuth } from '../../../../auth/auth-provider';
import { localized } from '../../../../i18n/i18n';
import { selectLocations } from '../../../../state/features/locations/locations-slice';
import { updateNotificationSubscription } from '../../../../state/features/notification-subscriptions/operation';
import { selectOrganization } from '../../../../state/features/settings/settings-slice';
import { buttonColor, sideBarDividerColor } from '../../../../styles/color-constants';
import { roomToLocationString } from '../../../../utilities/helpers/location-helper';
import { useDialog } from '../../../../utilities/providers/dialog-provider';
import { StyledButton } from '../../../components/specific-components/buttons/styled-button';
import { RightSideContainer } from '../../../components/specific-components/containers/right-side-container';
import { BasicLocationSelect } from '../../../components/specific-components/locations/basic-location-select';
import { useRightSidebar } from '../../../components/specific-components/right-side-bar';
import { SidebarCancelButton } from '../../../components/specific-components/sidebar-cancel-button';
import { FlexColumn } from '../../../components/default-components/flex-column';
import { ManageNotificationsStepTargets } from '../../../../utilities/enums/tutorial-steps-target';
import { useTutorial } from '../../../../utilities/providers/tutorial-provider';

const textStyles = {
  fontSize: '20px',
};
const styles: { [key: string]: SxProps } = {
  dividerColor: {
    backgroundColor: sideBarDividerColor,
  },
  bottomDivider: {
    marginBottom: '64px',
    backgroundColor: sideBarDividerColor,
  },
  locationSelector: {
    marginBottom: '30px',
  },
};

interface Props {
  notificationSubscription: INotificationSubscriptionDTO;
}

export const SetNotificationLocations: FC<Props> = React.memo((props) => {
  const dispatch = useDispatch();
  const setSidebarContent = useRightSidebar();
  const { openDeleteDialog } = useDialog();
  const { notificationSubscription } = props;
  const { isManager } = useAuth();
  const {
    setTutorialState,
    tutorialState: { tourActive },
  } = useTutorial();
  const locations = useSelector(selectLocations);
  const [subscribedLocations, setSubscribedLocations] = useState<ILocationDTO[]>(
    locations.filter((l) => notificationSubscription.locationIds?.includes(l.id)),
  );

  const selectedOrganization = useSelector(selectOrganization);

  const removeFromList = useCallback(
    (removeLocation: ILocationDTO | undefined) => () => {
      if (!removeLocation) return;
      setSubscribedLocations(subscribedLocations.filter((l) => l?.id !== removeLocation.id));
    },
    [subscribedLocations],
  );

  const openDelete = useCallback(
    (location: ILocationDTO) => () => {
      openDeleteDialog(localized('ConfirmRemoveLocation'), () => {
        setSubscribedLocations(subscribedLocations.filter((l) => l?.id !== location.id));
      });
    },
    [openDeleteDialog, subscribedLocations],
  );

  const handleLocationSelected = useCallback(
    (location: ILocationDTO) => {
      setSubscribedLocations([...subscribedLocations, location]);
    },
    [subscribedLocations],
  );

  const handleSave = useCallback(() => {
    if (notificationSubscription) {
      notificationSubscription.locationIds = subscribedLocations.map((l) => l.id);
      dispatch(updateNotificationSubscription(notificationSubscription as NotificationSubscription));
    }
    setSidebarContent(null);

    if (!tourActive) return;
    setTimeout(() => {
      setTutorialState({
        stepIndex: 12,
        buttonStatus: {
          showBack: false,
          showContinue: true,
        },
      });
    }, 400);
  }, [notificationSubscription, setSidebarContent, tourActive, subscribedLocations, dispatch, setTutorialState]);

  return (
    <RightSideContainer title={localized('Locations')}>
      <FlexColumn sx={{ flexGrow: 1, marginBottom: '20px' }} id={ManageNotificationsStepTargets.Step4}>
        {isManager && (
          <>
            <Box sx={styles.locationSelector}>
              <BasicLocationSelect
                name={localized('SelectLocation')}
                setActiveLocation={handleLocationSelected}
                holdSelectedLocation={false}
              />
            </Box>
            <Divider sx={styles.dividerColor} />
          </>
        )}
        <List dense disablePadding>
          {subscribedLocations?.map((location) => {
            return (
              location && (
                <div key={location?.id}>
                  <ListItem disableGutters>
                    {isManager && (
                      <IconButton onClick={openDelete(location)}>
                        <ClearIcon htmlColor={buttonColor} />
                      </IconButton>
                    )}
                    <ListItemText primary={roomToLocationString(location)} primaryTypographyProps={textStyles} />
                  </ListItem>
                  <Divider sx={styles.dividerColor} />
                </div>
              )
            );
          })}
        </List>
      </FlexColumn>

      <StyledButton onClick={handleSave} bottomStuck id={ManageNotificationsStepTargets.Step5}>
        {localized('SaveAndClose')}
      </StyledButton>
      <SidebarCancelButton />
    </RightSideContainer>
  );
});
