import { SxProps, TextField } from '@mui/material';
import React, { FC, memo } from 'react';
import { Controller, Message, ValidationRule, useFormContext } from 'react-hook-form';
import { whiteColor } from '../../../../styles/color-constants';

const localStyles: { [key: string]: SxProps } = {
  searchField: {
    borderRadius: 1,
    backgroundColor: whiteColor,
    flex: 1,
    display: 'flex',
    '& .MuiInput-input': {
      fontSize: '20px',
      height: '70px',
      marginLeft: 2,
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '4px',
      backgroundColor: 'white',
      overflow: 'hidden',
      height: '70px',
    },
    '& .MuiOutlinedInput-input': {
      paddingTop: 1,
      paddingBottom: 1,
      fontSize: '20px',
    },
  },
};

interface Props {
  name: string;
  label?: string;
  disabled?: boolean;
  placeholder?: string;
  validators?: Partial<{
    required: Message | ValidationRule<boolean>;
    min: ValidationRule<number | string>;
    max: ValidationRule<number | string>;
    maxLength: ValidationRule<number>;
    minLength: ValidationRule<number>;
    pattern: ValidationRule<RegExp>;
    valueAsNumber: boolean;
    valueAsDate: boolean;
    setValueAs: (value: any) => any;
    shouldUnregister?: boolean;
    onChange?: (event: any) => void;
    onBlur?: (event: any) => void;
    disabled: boolean;
  }>;
}

export const TextInput: FC<Props> = memo((props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      name={props.name}
      control={control}
      rules={props.validators}
      render={({ field }) => (
        <TextField
          {...field}
          disabled={props.disabled}
          sx={localStyles.searchField}
          InputLabelProps={{ shrink: true }}
          value={field.value ?? ''}
          label={props.label}
          placeholder={props.placeholder}
          error={!!errors[props.name]}
          helperText={!!errors[props.name] && errors[props.name].message}
        />
      )}
    />
  );
});
