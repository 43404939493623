import {styled, SxProps, TextField, TextFieldProps} from '@mui/material';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import React, {FC, useCallback} from 'react';
import {ReactComponent as DatePickerIcon} from '../../../../assets/icons/date-picker-icon.svg';
import {localized} from '../../../../i18n/i18n';
import {theme} from '../../../../styles/theme';
import {DateFormat} from '../../../../utilities/constants';

interface Props {
  dateFromChanged: (DateFrom: Date) => void;
  dateToChanged: (DateTo: Date) => void;
  onDateFromOpen?: (() => void)
  onDateToOpen?: (() => void)
  onDateFromClose?: (() => void)
  onDateToClose?: (() => void)
  dateFrom: Date;
  dateTo: Date;
  fromDateId?: string;
  toDateId?: string;
}

const styles: {[key: string]: SxProps} = {
  datePicker: {
    backgroundColor: theme.colors.datePicker?.backgroundColor,
    width: '330px',
    color: theme.colors.datePicker?.textColor,
  },
};

const container = {
  width: '700px',
  display: 'flex',
  marginTop: '20px',
};

const datePickerContainer = {
  backgroundColor: theme.colors.datePicker?.backgroundColor,
  padding: '10px 10px 5px 0px',
  height: '80px',
};

const alignCenterPaddingTop20 = {
  width: '18px',
  alignSelf: 'center',
  paddingTop: theme.spacing(2.5),
};

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    fontSize: 20,
    color: theme.colors.datePicker?.textColor,
    '& fieldset': {
      border: 'none',
    },
  },
  '& .MuiFormLabel-filled': {
    transform: 'translate(15px, -5px) scale(0.5)',
  },
  '& .MuiInputLabel-root.Mui-focused': {
    transform: 'translate(15px, -5px) scale(0.5)',
  },
});

const inputLabel = {
  fontSize: 20,
  color: theme.colors.datePicker?.textColor,
};

export const DateRangePicker: FC<Props> = React.memo((props: Props) => {
  const {dateFromChanged, dateToChanged, onDateFromOpen, onDateToOpen, onDateFromClose, onDateToClose, fromDateId, toDateId} = props;
  const dateFromChangedLocal = useCallback(
    (d: Date | null) => {
      if (d) {
        dateFromChanged(d);
      }
    },
    [dateFromChanged],
  );

  const dateToChangedLocal = useCallback(
    (d: Date | null) => {
      if (d) {
        dateToChanged(d);
      }
    },
    [dateToChanged],
  );

  return (
    <div style={container} >
      <LocalizationProvider dateAdapter={AdapterDayjs} >
        <div style={datePickerContainer} id={fromDateId}>
          <DatePicker
            mask="__/__/__"
            label={localized('FromDate')}
            value={props.dateFrom ?? null}
            inputFormat={DateFormat}
            onChange={dateFromChangedLocal}
            onOpen={onDateFromOpen}
            onClose={onDateFromClose}
            renderInput={(params: TextFieldProps) => (
              <StyledTextField InputLabelProps={{style: inputLabel}} {...params} sx={styles.datePicker} fullWidth />
            )}
            components={{
              OpenPickerIcon: DatePickerIcon,
            }}
          />
        </div>

        <span style={alignCenterPaddingTop20} />
        <div style={datePickerContainer} id={toDateId}>
          <DatePicker
            mask="__/__/__"
            label={localized('ToDate')}
            inputFormat={DateFormat}
            value={props.dateTo ?? null}
            onChange={dateToChangedLocal}
            onOpen={onDateToOpen}
            onClose={onDateToClose}
            renderInput={(params: TextFieldProps) => (
              <StyledTextField InputLabelProps={{style: inputLabel}} {...params} sx={styles.datePicker} fullWidth />
            )}
            components={{
              OpenPickerIcon: DatePickerIcon,
            }}
          />
        </div>
      </LocalizationProvider>
    </div>
  );
});
