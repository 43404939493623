import { createSelector, createSlice } from '@reduxjs/toolkit';
import { AppState } from '../../store';
import { setOrganizationSetting } from '../settings/settings-slice';
import { getNotificationSubscriptionsByOrganization, updateNotificationSubscription } from './operation';
import { NotificationSubscriptionsState } from './types';

const initialState: NotificationSubscriptionsState = {
  notificationSubscriptions: [],
  pending: false,
  isSuccess: false,
};

export const notificationSubscriptionsSlice = createSlice({
  name: 'notificationSubscriptions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // GetByOrganization
    builder.addCase(getNotificationSubscriptionsByOrganization.fulfilled, (state, action) => {
      state.pending = false;
      state.notificationSubscriptions = action.payload;
    });

    builder.addCase(getNotificationSubscriptionsByOrganization.pending, (state, _) => {
      state.pending = true;
    });

    builder.addCase(getNotificationSubscriptionsByOrganization.rejected, (state, _) => {
      state.pending = false;
    });

    // Update
    builder.addCase(updateNotificationSubscription.fulfilled, (state, action) => {
      state.pending = false;
      state.isSuccess = true;
      const index = state.notificationSubscriptions.findIndex((nS) => nS.id === action.payload.id);
      if (index !== -1) {
        state.notificationSubscriptions[index] = action.payload;
      }
    });

    builder.addCase(updateNotificationSubscription.pending, (state, _) => {
      state.pending = true;
      state.isSuccess = false;
    });

    builder.addCase(updateNotificationSubscription.rejected, (state, _) => {
      state.pending = false;
      state.isSuccess = false;
    });

    // Resets when new organization is selected
    builder.addCase(setOrganizationSetting, (state) => {
      state.notificationSubscriptions = initialState.notificationSubscriptions;
    });
  },
});

export const selectNotificationSubscriptions = (store: AppState) =>
  store.notificationSubscriptionsReducer.notificationSubscriptions;

export const selectNotificationSubscriptionByUserId = (userId?: number) =>
  createSelector(selectNotificationSubscriptions, (notifications) => {
    return notifications.find((n) => n.userId === userId);
  });

// eslint-disable-next-line
export const {} = notificationSubscriptionsSlice.actions;

export default notificationSubscriptionsSlice.reducer;
