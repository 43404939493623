import { Typography } from '@mui/material';
import React, { FC, memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ILocationDTO } from '../../../../../api/api';
import { localized } from '../../../../../i18n/i18n';
import { setDeviceLocationId } from '../../../../../state/features/device-setup-wizard/device-setup-wizard-slice';
import { selectLocationById } from '../../../../../state/features/locations/locations-slice';
import { AppState } from '../../../../../state/store';
import { styles } from '../../../../pages/locations/locations';
import { BasicLocationSelect } from '../../locations/basic-location-select';
import { useTutorial } from '../../../../../utilities/providers/tutorial-provider';

export const UnitLocationSelect: FC = memo(() => {
  const {
    setTutorialState,
    tutorialState: { tourActive },
  } = useTutorial();
  const { locationId } = useSelector((store: AppState) => store.deviceSetupWizardReducer);
  const location = useSelector(selectLocationById(locationId));
  const dispatch = useDispatch();

  const handleLocationSelect = useCallback(
    (location: ILocationDTO) => {
      dispatch(setDeviceLocationId(location.id));
      if (!tourActive) return;
      setTimeout(() => {
        setTutorialState({ stepIndex: 7 });
      }, 200);
    },
    [dispatch, setTutorialState, tourActive],
  );

  const onSelectorOpen = useCallback(() => {
    if (!tourActive) return;
    setTimeout(() => {
      setTutorialState({ stepIndex: 6 });
    }, 300);
  }, [setTutorialState, tourActive]);

  return (
    <>
      <BasicLocationSelect
        name={localized('Location')}
        setActiveLocation={handleLocationSelect}
        onOpen={onSelectorOpen}
        holdSelectedLocation={true}
        selectedValue={location}
      />
      <Typography sx={styles.typography}>{localized('LocationMetaText')}</Typography>
    </>
  );
});
