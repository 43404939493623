import React, { FC, useCallback, useEffect, useState } from 'react';
import { FlexColumn } from '../../../default-components/flex-column';
import { useDispatch, useSelector } from 'react-redux';
import { localized } from '../../../../../i18n/i18n';
import { showErrorSnackbar } from '../../../../../state/features/snackbar/snackbar-slice';
import { selectActiveDeviceState } from '../../../../../state/features/devices/devices-slice';
import { resetDataDump, selectDataDumpData } from '../../../../../state/features/admin-device/admin-device-slice';
import { getDataDumpData, requestDataDump } from '../../../../../state/features/admin-device/operation';
import { Button, SxProps } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { ReactComponent as DataDumpIcon } from '../../../../../assets/icons/data-dump-icon.svg';
import { buttonColor } from '../../../../../styles/color-constants';

interface Props {
  connectId: string;
  databaseId: number;
}

const buttonStyle: SxProps = {
  textTransform: 'none',
  fontSize: '20px',
  color: buttonColor,
  width: '180px',
};

export const DataDump: FC<Props> = React.memo(({ connectId, databaseId }) => {
  const dispatch = useDispatch();
  const dataDumpData = useSelector(selectDataDumpData);
  const deviceState = useSelector(selectActiveDeviceState);
  const [loadingData, setLoadingData] = useState(false);
  const [requestTime, setRequestTime] = useState<Date>();
  const [refetchTimeout, setRefetchTimeout] = useState<NodeJS.Timeout>();

  useEffect(() => {
    if (refetchTimeout === undefined) return;

    const timeout = setTimeout(() => {
      clearTimeout(refetchTimeout);
      setLoadingData(false);
      dispatch(showErrorSnackbar(localized('DataDumpTimeout')));
    }, 120 * 1000); // Two minute timeout

    return () => {
      clearTimeout(timeout);
      dispatch(resetDataDump());
    };
  }, [refetchTimeout, dispatch]);

  useEffect(() => {
    if (dataDumpData === undefined) return;

    if (!Object.values(dataDumpData).includes(undefined)) {
      setLoadingData(false);
    } else if (loadingData && requestTime !== undefined) {
      const timeout = setTimeout(() => {
        dispatch(getDataDumpData({ databaseId, from: requestTime }));
      }, 5000);
      if (refetchTimeout === undefined) {
        setRefetchTimeout(timeout);
      }
    }
    // eslint-disable-next-line
  }, [dataDumpData]);

  const handleRequestDataDump = useCallback(() => {
    dispatch(requestDataDump(connectId));
    setLoadingData(true);
    const date = new Date().toLocaleString();
    setRequestTime(new Date(date));

    setTimeout(() => {
      dispatch(getDataDumpData({ databaseId, from: new Date() }));
    }, 5000);
  }, [connectId, databaseId, dispatch]);

  return (
    <FlexColumn>
      <Button
        startIcon={loadingData ? <CircularProgress color={'inherit'} /> : <DataDumpIcon />}
        sx={buttonStyle}
        onClick={handleRequestDataDump}
        disabled={deviceState === null}>
        {localized('RequestDataDump')}
      </Button>
    </FlexColumn>
  );
});
