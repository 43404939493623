import { Button, SxProps } from '@mui/material';
import React, { FC, useCallback } from 'react';
import {
  buttonColor,
  buttonDisabledColor,
  buttonDisabledTextColor,
  primaryTextColor,
  secondaryColor,
  whiteColor,
} from '../../../../styles/color-constants';

const buttonStyle: SxProps = {
  borderRadius: 0,
  height: '70px',
  minHeight: '70px',
  width: '100%',
  maxWidth: '340px',
  alignSelf: 'center',
  marginTop: '41px',
  fontSize: '20px',
  boxShadow: 'none',
  color: secondaryColor,
  backgroundColor: buttonColor,
  textTransform: 'none',
  '&:disabled': {
    color: buttonDisabledTextColor,
    backgroundColor: buttonDisabledColor,
  },
  '&:hover': {
    boxShadow: 'none',
    color: primaryTextColor,
    backgroundColor: whiteColor,
    border: `3px solid ${buttonColor}`,
  },
};

const buttonBottomStyle: SxProps = {
  borderRadius: 0,
  height: '70px',
  minHeight: '70px',
  width: '100%',
  maxWidth: '340px',
  alignSelf: 'center',
  fontSize: '20px',
  color: secondaryColor,
  backgroundColor: buttonColor,
  textTransform: 'none',
  marginTop: 'auto',
  marginBottom: '20px',
  '&:disabled': {
    color: buttonDisabledTextColor,
    backgroundColor: buttonDisabledColor,
  },
  '&:hover': {
    color: primaryTextColor,
    backgroundColor: whiteColor,
    border: `3px solid ${buttonColor}`,
  },
};

export interface StyledButtonProps {
  type?: 'button' | 'submit' | 'reset' | undefined;
  bottomStuck?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  sx?: SxProps;
  id?: string;
}

export const StyledButton: FC<StyledButtonProps> = React.memo((props) => {
  const { onClick, sx } = props;
  const localOnClick = useCallback(() => {
    if (onClick) {
      onClick();
    }
  }, [onClick]);

  const styleToUse = props.bottomStuck ? buttonBottomStyle : buttonStyle;

  return (
    <Button
      id={props.id}
      type={props.type ?? 'button'}
      variant="contained"
      disabled={props.disabled}
      sx={{ ...styleToUse, ...sx }}
      onClick={localOnClick}>
      {props.children}
    </Button>
  );
});
