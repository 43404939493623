import { Refresh } from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { CircularProgress, IconButton, SxProps, Typography } from '@mui/material';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from '../../../../../auth/auth-provider';
import { localized } from '../../../../../i18n/i18n';
import {
  selectAvailableFirmwares,
  selectCurrentFirmware,
  selectRequestedFirmware,
} from '../../../../../state/features/admin-device/admin-device-slice';
import {
  getDeviceFirmwareVersion,
  getFirmwareUpdateTimestamp,
  getNewOrEqualFirmwaresForDevice,
} from '../../../../../state/features/admin-device/operation';
import { selectUpdateFirmwarePending } from '../../../../../state/features/devices/devices-slice';
import { updateDeviceFirmware } from '../../../../../state/features/devices/operation';
import { showInfoSnackbar } from '../../../../../state/features/snackbar/snackbar-slice';
import { buttonColor } from '../../../../../styles/color-constants';
import { DropdownOption } from '../../../default-components/basic-dropdown/basic-search-dropdown';
import { FlexColumn } from '../../../default-components/flex-column';
import { FlexRow } from '../../../default-components/flex-row';
import { StyledLoadingButton } from '../../buttons/styled-loading-button';
import { StyledSelect } from '../../styled-select';

const styles: SxProps = {
  width: '50%',
  marginRight: '20px',
  '& .MuiSelect-icon': {
    fontSize: '50px',
  },
};

interface Props {
  connectId: string;
}

export const FirmwareUpdater: FC<Props> = React.memo(({ connectId }) => {
  const dispatch = useDispatch();
  const { isGlobalAdmin, isAdmin } = useAuth();

  const availableFirmwares = useSelector(selectAvailableFirmwares);
  const currentFirmware = useSelector(selectCurrentFirmware);
  const requestedFirmware = useSelector(selectRequestedFirmware);
  const updateFirmwarePending = useSelector(selectUpdateFirmwarePending);

  const [firmwareOptions, setFirmwareOptions] = useState<DropdownOption[]>([]);
  const [reloadingVersions, setReloadingVersions] = useState(false);
  const [selectedFirmwareOption, setSelectedFirmwareOption] = useState<string>('');

  useEffect(() => {
    if (!isAdmin || updateFirmwarePending) return;

    dispatch(getFirmwareUpdateTimestamp(connectId));
    dispatch(getDeviceFirmwareVersion(connectId));

    if (!isGlobalAdmin) return;

    dispatch(getNewOrEqualFirmwaresForDevice(connectId));
  }, [isGlobalAdmin, isAdmin, connectId, updateFirmwarePending, dispatch]);

  useEffect(() => {
    const options = availableFirmwares.map((firmware) => ({
      value: firmware.version,
      label: firmware.version,
    }));
    options.sort((a, b) => (a.value < b.value ? -1 : 1));
    setFirmwareOptions(options);

    setSelectedFirmwareOption(options.find((option) => option.value === currentFirmware)?.value ?? '');
  }, [availableFirmwares, currentFirmware]);

  const handleReloadVersions = useCallback(() => {
    setReloadingVersions(true);
  }, []);

  const handleFirmwareSelect = useCallback((option: DropdownOption) => {
    setSelectedFirmwareOption(option.value);
  }, []);

  const handleUpdate = useCallback(() => {
    if (currentFirmware === selectedFirmwareOption) {
      dispatch(showInfoSnackbar(localized('FirmwareAlreadyInstalled') + selectedFirmwareOption));
    }
    dispatch(updateDeviceFirmware({ connectId: connectId, firmwareVersion: selectedFirmwareOption }));
  }, [dispatch, connectId, selectedFirmwareOption, currentFirmware]);

  return (
    <FlexColumn>
      {requestedFirmware && (
        <FlexRow sx={{ alignItems: 'center' }}>
          <Typography sx={{ color: 'red', alignContent: 'center' }}>
            {'Requested firmware version: ' + requestedFirmware}{' '}
          </Typography>
          <IconButton onClick={handleReloadVersions}>
            {reloadingVersions ? (
              <CircularProgress size={24} sx={{ color: buttonColor }} />
            ) : (
              <Refresh htmlColor={buttonColor} />
            )}
          </IconButton>
        </FlexRow>
      )}
      <FlexRow>
        <StyledSelect
          label={localized('FirmwareVersion')}
          value={firmwareOptions.find((o) => o.value === selectedFirmwareOption)}
          options={firmwareOptions}
          height={'70px'}
          handleChange={handleFirmwareSelect}
          whitebackground={true}
          IconComponent={KeyboardArrowDownIcon}
          sx={styles}
        />
        <StyledLoadingButton
          sx={{ margin: 'auto', width: '50%' }}
          loading={updateFirmwarePending}
          onClick={handleUpdate}>
          {localized('UpdateFirmware')}
        </StyledLoadingButton>
      </FlexRow>
    </FlexColumn>
  );
});
