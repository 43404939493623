import { AADOptions, B2COptions } from './auth-types';
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const CALLBACK_URL = `${process.env.REACT_APP_BASE_URL}`;
export const AUTHORITY = process.env.REACT_APP_AUTHORITY;
export const TOKEN_ID = process.env.REACT_APP_TOKEN_ID as string;
export const AUTH_SCOPE = `api://${process.env.REACT_APP_API_CLIENT_ID}/${process.env.REACT_APP_AAD_API_PERMISSION}`;
export const TOKEN_ROLE_IDENTIFIER = process.env.REACT_APP_B2C_TOKEN_ROLE_IDENTIFIER as string;

// ------------------------------
// ----------    AAD     --------
// ------------------------------
export const AADConfig: AADOptions = {
  ClientId: process.env.REACT_APP_CLIENT_ID as string,
  RedirectUri: `${process.env.REACT_APP_BASE_URL}`,
  Authority: process.env.REACT_APP_AUTHORITY as string,
  Scope: `api://${process.env.REACT_APP_API_CLIENT_ID}/${process.env.REACT_APP_AAD_API_PERMISSION}`,
  CacheLocation: 'localStorage',
  TokenRoleIdentifier: 'roles',
};

// ------------------------------
// ----------    B2C     --------
// ------------------------------
export const B2CConfig: B2COptions = {
  ClientId: process.env.REACT_APP_B2C_CLIENT_ID as string,
  RedirectUri: process.env.REACT_APP_B2C_REDIRECT_URI as string,
  Authority: `https://${process.env.REACT_APP_B2C_TENANT}.b2clogin.com/tfp/${process.env.REACT_APP_B2C_TENANT_URL}/${process.env.REACT_APP_B2C_SIGN_IN_POLICY}`,
  PasswordResetAuthority: `https://${process.env.REACT_APP_B2C_TENANT}.b2clogin.com/tfp/${process.env.REACT_APP_B2C_TENANT_URL}/${process.env.REACT_APP_B2C_PASSWORD_RESET_POLICY}`,
  Scope: process.env.REACT_APP_B2C_SCOPE as string,
  CacheLocation: 'localStorage',
  TokenRoleIdentifier: 'extension_Role',
};

export const tokenStorageKey = 'token';
export const firstLoginKey = 'firstLogin:';
