import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import React, {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { sidebarBackgroundColor } from '../../../styles/color-constants';
import { theme } from '../../../styles/theme';
import { FlexRow } from '../default-components/flex-row';
const useStyles = makeStyles(() =>
  createStyles({
    contentSlidePanel: {
      width: '100%',
      height: '100vh',
    },
    slidePane: {
      overflow: 'auto',
      height: '100vh',
      background: sidebarBackgroundColor,
      position: 'fixed',
      top: 0,
      right: 0,
      minWidth: '340px',
      maxWidth: '600px',
      width: '40%',
      [theme.breakpoints.down('lg')]: {
        width: '50%',
      },
      zIndex: 200,
      boxShadow: '1px 0px 7px rgba(0,0,0,0.5)',
      transform: 'translateX(100%)',
      transition: 'transform 0.3s ease-out',
    },
    slidePaneOpen: {
      transform: 'translateX(0)',
    },
  }),
);

const RightSidebarContext = createContext<JSX.Element | null>(null);
const RightSidebarDispatchContext = createContext<Dispatch<SetStateAction<JSX.Element | null>>>(
  {} as Dispatch<SetStateAction<JSX.Element | null>>,
);

export const useRightSidebar = () => useContext(RightSidebarDispatchContext);

const RightSideBar: FC = React.memo((props) => {
  const classes = useStyles();
  const [sidebarContent, setSidebarContent] = useState<JSX.Element | null>(null);

  const escFunction = useCallback((event) => {
    if (event.key === 'Escape') {
      setSidebarContent(null);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, [escFunction]);

  return (
    <RightSidebarContext.Provider value={sidebarContent}>
      <RightSidebarDispatchContext.Provider value={setSidebarContent}>
        <FlexRow>
          <div className={classes.contentSlidePanel}>{props.children}</div>
          <div className={clsx([classes.slidePane, sidebarContent ? classes.slidePaneOpen : null])}>
            {sidebarContent}
          </div>
        </FlexRow>
      </RightSidebarDispatchContext.Provider>
    </RightSidebarContext.Provider>
  );
});

export { RightSideBar, RightSidebarContext, RightSidebarDispatchContext };
