import { Typography } from '@mui/material';
import React from 'react';
import { ACTIONS, CallBackProps, Step } from 'react-joyride';
import { NavigateFunction } from 'react-router-dom';
import { Dispatch } from 'redux';
import { localized, localizedInterpolation } from '../../../../../i18n/i18n';
import { CreateANewLocationStepTargets, asIdRef } from '../../../../../utilities/enums/tutorial-steps-target';
import {
  defaultStepConfigs,
  defaultTutorialState,
  resetTriggered,
  successfulBackward,
  successfulForward,
} from '../../../../../utilities/helpers/tutorial-helper';
import { TutorialState } from '../../../../../utilities/providers/tutorial-provider';

export const createANewLoactionLogic = (
  data: CallBackProps,
  navigate: NavigateFunction,
  dispatch: Dispatch<any>,
  setState: (patch: Partial<TutorialState> | ((prevState: TutorialState) => Partial<TutorialState>)) => void,
) => {
  const { status, action, index, lifecycle } = data;
  const nextStep = action === ACTIONS.NEXT ? index + 1 : index - 1;
  let buttonStatus = { showContinue: true, showBack: true };

  if (successfulForward(action, lifecycle)) {
    switch (nextStep) {
      case 1:
        buttonStatus.showContinue = false;
        buttonStatus.showBack = false;
        break;
      case 4:
        buttonStatus.showContinue = false;
        break;
      case 5:
        buttonStatus.showBack = false;
        break;
    }

    setState({
      stepIndex: nextStep,
      buttonStatus: buttonStatus,
    });
  } else if (successfulBackward(action, lifecycle)) {
    switch (nextStep) {
      case 1:
      case 5:
        buttonStatus.showBack = false;
        break;
    }

    setState({
      stepIndex: nextStep,
      buttonStatus: buttonStatus,
    });
  } else if (resetTriggered(status, action, lifecycle)) {
    setState(defaultTutorialState);
  }
};

export const createNewLocationSteps: Step[] = [
  {
    ...defaultStepConfigs,
    title: (
      <Typography fontWeight={'bold'}>
        {localizedInterpolation('TutorialStep1Title', { menuItem: localized('Locations') })}
      </Typography>
    ),
    target: asIdRef(CreateANewLocationStepTargets.Step1),
  },
  {
    ...defaultStepConfigs,
    title: <Typography fontWeight={'bold'}>{localized('CreateLocationStep2Title')}</Typography>,
    target: asIdRef(CreateANewLocationStepTargets.Step2),
  },
  {
    ...defaultStepConfigs,
    title: <Typography fontWeight={'bold'}>{localized('CreateLocationStep3Title')}</Typography>,
    content: <Typography>{localized('CreateLocationStep3Content')}</Typography>,
    target: asIdRef(CreateANewLocationStepTargets.Step3),
  },
  {
    ...defaultStepConfigs,
    title: <Typography fontWeight={'bold'}>{localized('CreateLocationStep4Title')}</Typography>,
    content: <Typography>{localized('CreateLocationStep4Content')}</Typography>,
    target: asIdRef(CreateANewLocationStepTargets.Step4),
  },
  {
    ...defaultStepConfigs,
    title: <Typography fontWeight={'bold'}>{localized('CreateLocationStep5Title')}</Typography>,
    target: asIdRef(CreateANewLocationStepTargets.Step5),
  },
  {
    ...defaultStepConfigs,
    title: <Typography fontWeight={'bold'}>{localized('CreateLocationStep6Title')}</Typography>,
    target: asIdRef(CreateANewLocationStepTargets.Step6),
    spotlightClicks: false,
  },
  {
    ...defaultStepConfigs,
    title: <Typography fontWeight={'bold'}>{localized('CreateLocationStep7Title')}</Typography>,
    target: asIdRef(CreateANewLocationStepTargets.Step7),
    spotlightClicks: false,
  },
  {
    ...defaultStepConfigs,
    title: (
      <Typography fontWeight={'bold'}>
        {localizedInterpolation('TutorialLastStepTitle', { item: localized('Location').toLowerCase() })}
      </Typography>
    ),
    content: <Typography>{localized('TutorialLastStepContent')}</Typography>,
    target: asIdRef(CreateANewLocationStepTargets.LastStep),
    spotlightClicks: false,
    placement: 'center',
  },
];
