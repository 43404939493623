import { Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { localized } from '../../../../../i18n/i18n';
import { toggleDebugLogging } from '../../../../../state/features/admin-device/operation';
import {
  resetActiveDevice,
  selectActiveDeviceState,
  selectDevicesPending,
} from '../../../../../state/features/devices/devices-slice';
import { getDeviceTwinState } from '../../../../../state/features/devices/operation';
import { FlexRow } from '../../../default-components/flex-row';
import { LargeSwitch } from '../../large-switch';

interface Props {
  connectId: string;
}

export const EnableDebuggingButtons: FC<Props> = React.memo(({ connectId }) => {
  const dispatch = useDispatch();
  const deviceLoading = useSelector(selectDevicesPending);
  const deviceState = useSelector(selectActiveDeviceState);
  const disabled = deviceState === null;

  const [selectChecked, setSelectChecked] = useState(deviceState?.extendedGeneralLogging ?? false);

  useEffect(() => {
    dispatch(getDeviceTwinState(connectId));

    return () => {
      dispatch(resetActiveDevice());
    };
  }, [connectId, dispatch]);

  useEffect(() => {
    if (deviceState?.extendedGeneralLogging === undefined) return;

    setSelectChecked(deviceState?.extendedGeneralLogging);
  }, [deviceState?.extendedGeneralLogging]);

  const sendEnableDebugLogging = useCallback(() => {
    if (deviceState === undefined) return;

    dispatch(toggleDebugLogging({ connectId, enableLogging: !selectChecked }));
  }, [connectId, deviceState, dispatch, selectChecked]);
  return (
    <FlexRow alignItems={'center'} justifyContent={'space-between'}>
      <Typography color={'white'} fontSize={'16px'} fontWeight={200}>
        {localized('DebugLogging')}
      </Typography>
      {deviceLoading && <CircularProgress sx={{ marginLeft: 'auto', marginRight: '8px' }} />}
      <LargeSwitch checked={selectChecked} onClick={sendEnableDebugLogging} disabled={disabled} />
    </FlexRow>
  );
});
