import React, { FC } from 'react';
import { FlexRow } from '../../../../default-components/flex-row';
import { DownloadLogButton } from '../../debug-logging/download-log-button';
import { DataDump } from '../../debug-logging/data-dump';
import { IDeviceInfoDTO } from '../../../../../../api/api';

interface Props {
  device: IDeviceInfoDTO;
}

export const AdminIconButtons: FC<Props> = React.memo(({ device }) => {
  return (
    <FlexRow alignItems={'center'} justifyContent={'space-evenly'}>
      <DownloadLogButton connectId={device.connectId} disabled={false} />
      <DataDump connectId={device.connectId} databaseId={device.id} />
    </FlexRow>
  );
});
