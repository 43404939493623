import { SxProps } from '@mui/material';
import React, { FC, SetStateAction, useCallback, useMemo, useState } from 'react';
import { OrganizationJob } from '../../../../api/api';
import { localized } from '../../../../i18n/i18n';
import { getOrganizationJobDropdownOptions } from '../../../../utilities/platform-helpers/enum-helper';
import { DropdownOption } from '../../default-components/basic-dropdown/basic-search-dropdown';
import { StyledSelect } from '../styled-select';
import { useTutorial } from '../../../../utilities/providers/tutorial-provider';
import { AddAndManageUsersStepTargets } from '../../../../utilities/enums/tutorial-steps-target';

interface Props {
  job: OrganizationJob;
  setJob: (job: SetStateAction<OrganizationJob>) => void;
}

const selectStyle: SxProps = {
  marginTop: '30px',
};
export const JobSelect: FC<Props> = React.memo((props) => {
  const { job, setJob } = props;
  const {
    setTutorialState,
    tutorialState: { tourActive },
  } = useTutorial();
  const organizationJobOptions = useMemo(() => getOrganizationJobDropdownOptions(), []);
  const [localJobDropdownOption, setLocalJob] = useState(
    tourActive
      ? undefined
      : organizationJobOptions.find((o) => o.value === job) ?? {
          label: localized('Other'),
          value: OrganizationJob.Other,
        },
  );

  const jobChange = useCallback(
    (e: DropdownOption) => {
      setLocalJob(organizationJobOptions.find((o) => o.value === e.value));
      setJob(e.value);
    },
    [organizationJobOptions, setJob],
  );

  const onSelectorOpen = useCallback(() => {
    if (!tourActive) return;
    setTimeout(() => {
      setTutorialState({ stepIndex: 6 });
    }, 300);
  }, [setTutorialState, tourActive]);

  const onSelectorClose = useCallback(() => {
    if (!tourActive) return;
      setTimeout(() => {
        setTutorialState({ stepIndex: 7 });
      }, 300);
  }, [setTutorialState, tourActive]);

  return (
    <StyledSelect
      id={AddAndManageUsersStepTargets.Step6}
      label={localized('ServiceArea')}
      options={organizationJobOptions}
      value={localJobDropdownOption}
      handleChange={jobChange}
      onOpen={onSelectorOpen}
      onClose={onSelectorClose}
      sx={selectStyle}
      whitebackground={true}
    />
  );
});
