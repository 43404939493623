import { Message, ValidationRule } from 'react-hook-form';
import { localized, localizedInterpolation } from '../../i18n/i18n';

export interface Validators {
  required?: Message | ValidationRule<boolean>;
  min?: ValidationRule<number | string>;
  max?: ValidationRule<number | string>;
  maxLength?: ValidationRule<number>;
  minLength?: ValidationRule<number>;
  pattern?: ValidationRule<RegExp>;
  valueAsNumber?: boolean;
  valueAsDate?: boolean;
  setValueAs?: (value: any) => any;
  shouldUnregister?: boolean;
  onChange?: (event: any) => void;
  onBlur?: (event: any) => void;
  disabled?: boolean;
}

export const requiredValidator: Validators = { required: { value: true, message: localized('FieldIsRequired') } };
export const numberValidator: Validators = {
  pattern: { value: new RegExp('^[0-9]*$'), message: localized('FieldOnlyNumber') },
};

export const maxLength20Validator: Validators = {
  maxLength: { value: 20, message: localizedInterpolation('FieldHasMaxLength', { value: '20' }) },
};

export const emailValidator: Validators = {
  pattern: { value: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/, message: localized('InvalidEmailFormat') },
};
export const basicPhoneNumberValidator: Validators = {
  pattern: { value: /^[+].*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s/0-9]*$/, message: localized('InvalidPhoneNumber') },
};
