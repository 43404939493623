import { KeyboardArrowDown } from '@mui/icons-material';
import { FormControl, InputLabel, MenuItem, Select, SelectProps, SxProps } from '@mui/material';
import React, { FC, useCallback, useMemo } from 'react';
import { blackColor, whiteColor } from '../../../styles/color-constants';
import { theme } from '../../../styles/theme';
import { DropdownOption } from '../default-components/basic-dropdown/basic-search-dropdown';

interface Props extends SelectProps {
  label?: string;
  options: DropdownOption[];
  value: DropdownOption | null | undefined;
  handleChange?: (item: DropdownOption) => void;
  onOpen?: (event: React.SyntheticEvent) => void;
  sx?: SxProps;
  // Cannot use the sx prop to set the height correctly, so it needs its own prop
  height?: string;
  disabled?: boolean;
  whitebackground?: boolean;
}

export const StyledSelect: FC<Props> = (props) => {
  const { label, options, value, handleChange, onOpen, onClose, sx, height, ...other } = props;
  const formControlStyle: SxProps = useMemo(
    () => ({
      justifyContent: 'center',
      '& .MuiSelect-filled:focus': {
        backgroundColor: 'transparent',
      },
      '& .MuiFilledInput-root': {
        backgroundColor: props.whitebackground ? whiteColor : theme.colors.dropdown.backgroundColor,
        overflow: 'hidden',
        height: height ?? '70px',
        alignItems: 'center',
      },
      '& .MuiFilledInput-root:hover': {
        backgroundColor: props.whitebackground ? whiteColor : theme.colors.dropdown.backgroundColor,
      },
      '& .MuiFilledInput-root.Mui-focused': {
        backgroundColor: props.whitebackground ? whiteColor : theme.colors.dropdown.backgroundColor,
      },
      '& .MuiFilledInput-input': {
        fontSize: '20px',
        marginBottom: label ? '0px' : '8px',
        color: props.whitebackground ? blackColor : theme.colors.dropdown.textColor,
      },
      '& .MuiInputLabel-root': {
        marginTop: '5px',
        fontSize: '20px',
        color: props.whitebackground ? blackColor : theme.colors.dropdown.textColor,
      },
      '& .MuiFormLabel-filled': {
        marginTop: '0px',
        transform: 'translate(12px, 7px) scale(0.5)',
        color: props.whitebackground ? blackColor : theme.colors.dropdown.textColor,
      },
      '& .MuiInputLabel-root.Mui-focused': {
        marginTop: '0px',
        transform: 'translate(12px, 7px) scale(0.5)',
        color: props.whitebackground ? blackColor : theme.colors.dropdown.textColor,
      },
    }),
    [height, label, props.whitebackground],
  );

  const localHandleChange = useCallback(
    (e) => {
      if (handleChange) {
        const option = options.find((o) => o.value === e.target.value);
        if (option) {
          handleChange(option);
        }
      }
    },
    [handleChange, options],
  );

  return (
    <FormControl variant="filled" sx={{ ...formControlStyle, ...sx }} disabled={props.disabled}>
      {label && <InputLabel>{label}</InputLabel>}
      <Select
        IconComponent={KeyboardArrowDown}
        disableUnderline
        onChange={localHandleChange}
        onOpen={onOpen}
        onClose={onClose}
        value={value?.value ?? ''}
        {...other}>
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
