import { Box, SxProps, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { ReactComponent as AdministratorIcon } from '../../../assets/icons/administrator-icon.svg';
import { ReactComponent as LocationsIcon } from '../../../assets/icons/locations-icon.svg';
import { ReactComponent as MaintenanceIcon } from '../../../assets/icons/maintenance-icon.svg';
import { ReactComponent as ManagerIcon } from '../../../assets/icons/manager-icon.svg';
import { useAuth } from '../../../auth/auth-provider';
import { localized } from '../../../i18n/i18n';
import { getLocationsByOrganizationId } from '../../../state/features/locations/operations';
import { getNotificationSubscriptionsByOrganization } from '../../../state/features/notification-subscriptions/operation';
import { selectOrganization } from '../../../state/features/settings/settings-slice';
import { getUsers } from '../../../state/features/users/operation';
import { routes } from '../../../state/routes';
import { theme } from '../../../styles/theme';
import { ManageNotificationsStepTargets } from '../../../utilities/enums/tutorial-steps-target';
import { FlexRow } from '../../components/default-components/flex-row';
import { PageContentContainer } from '../../components/default-components/page-container/page-content-container';
import { PageHeader } from '../../components/default-components/page-header.tsx/page-header';
import { PageTitle } from '../../components/default-components/page-title/page-title';
import { LargeButton } from '../../components/specific-components/buttons/large-button';
import { MainPageContainer } from '../../components/specific-components/containers/main-page-container';
import { RightSideBar } from '../../components/specific-components/right-side-bar';

export const styles: { [key: string]: SxProps } = {
  metaText: {
    fontSize: theme.typography.body1,
  },
};

const iconHeight: number = 50;
const iconWidth: number = 85;
export const notificationTableMaxHeight = 'calc(100vh - 400px)';
export const Notifications = React.memo(() => {
  const dispatch = useDispatch();
  const selectedOrganization = useSelector(selectOrganization);
  const { isAdmin, isManager, isWorker } = useAuth();
  const locationBaseString = location.pathname.split('/')[2];

  // Instantiating this makes the button border update correctly
  const navigate = useNavigate();

  useEffect(() => {
    if (locationBaseString === undefined) {
      if (isAdmin) {
        navigate(routes.notificationsAdministrators, { replace: true });
      } else if (isManager) {
        navigate(routes.notificationsManagers, { replace: true });
      }
    }
  }, [locationBaseString, isAdmin, isManager, navigate]);

  useEffect(() => {
    if (selectedOrganization) {
      dispatch(getLocationsByOrganizationId(selectedOrganization.id));
      dispatch(getNotificationSubscriptionsByOrganization(selectedOrganization.id));
      dispatch(getUsers(selectedOrganization.id));
    }
  }, [selectedOrganization, dispatch]);

  return (
    <RightSideBar>
      <PageTitle title={localized('Notifications')}>
        <PageHeader area="notifications" title={localized('Notifications')}></PageHeader>
        <PageContentContainer>
          <MainPageContainer>
            <Typography sx={styles.metaText} whiteSpace={'pre-line'}>
              {localized('MetaTextNotifications')}
            </Typography>
            <FlexRow marginTop={'30px'} marginBottom={'25px'}>
              <Box display={'flex'} id={ManageNotificationsStepTargets.Step2}>
                {isAdmin && (
                  <LargeButton
                    linkTo={routes.notificationsAdministrators}
                    focus={locationBaseString === routes.notificationsAdministrators.split('/')[2]}
                    icon={<AdministratorIcon height={iconHeight} width={iconWidth} />}
                    text={localized('Administrators')}
                  />
                )}
                {isManager && (
                  <LargeButton
                    linkTo={routes.notificationsManagers}
                    focus={locationBaseString === routes.notificationsManagers.split('/')[2]}
                    icon={<ManagerIcon height={iconHeight} width={iconWidth} />}
                    text={localized('Managers')}
                  />
                )}
                <LargeButton
                  linkTo={routes.notificationsMaintenanceStaff}
                  focus={locationBaseString === routes.notificationsMaintenanceStaff.split('/')[2]}
                  icon={<MaintenanceIcon height={iconHeight} width={iconWidth} />}
                  text={localized('Maintenance')}
                />
              </Box>
              <LargeButton
                linkTo={routes.notificationsLocations}
                focus={locationBaseString === routes.notificationsLocations.split('/')[2]}
                icon={<LocationsIcon height={iconHeight} width={iconWidth} />}
                text={localized('Locations')}
                id={ManageNotificationsStepTargets.Step12}
              />
            </FlexRow>
            <Outlet />
          </MainPageContainer>
        </PageContentContainer>
      </PageTitle>
    </RightSideBar>
  );
});
