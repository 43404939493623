import { Action, configureStore, Reducer, ThunkAction } from '@reduxjs/toolkit';
import { PersistConfig, persistReducer } from 'redux-persist';
import { PersistPartial } from 'redux-persist/lib/persistReducer';
import storage from 'redux-persist/lib/storage';
import authReducerBase from './features/auth/auth-slice';
import deviceCategoriesReducer from './features/device-categories/device-category-slice';
import adminDeviceReducer from './features/admin-device/admin-device-slice';
import deviceModelsReducer from './features/device-models/device-models-slice';
import deviceSetupWizardReducer from './features/device-setup-wizard/device-setup-wizard-slice';
import devicesReducer from './features/devices/devices-slice';
import locationsReducer from './features/locations/locations-slice';
import notificationSubscriptionsReducer from './features/notification-subscriptions/notification-subscriptions-slice';
import organizationReducer from './features/organization/organization-slice';
import pageSettingReducerBase from './features/page-header-setting/page-header-setting-slice';
import settingsReducerBase from './features/settings/settings-slice';
import snackbarReducer from './features/snackbar/snackbar-slice';
import timeZoneReducer from './features/timezone/timezone-slice';
import usersReducer from './features/users/users-slice';
import webAppReducerBase from './features/webapp/webapp-slice';

import { setStore } from './store-container';
export type AppState = ReturnType<typeof store.getState>;

const createPersistReducer = <T extends Reducer<any, Action<any>>>(config: PersistConfig<any>, reducer: T) => {
  return persistReducer(config, reducer) as typeof reducer & PersistPartial;
};

// Create persisted reducers
const webappPersistConfig: PersistConfig<any> = {
  key: 'webapp',
  storage,
  whitelist: ['open'],
  blacklist: ['showOverlaySpinner'],
};
const webAppReducer = createPersistReducer(webappPersistConfig, webAppReducerBase);

const settingsPersistConfig: PersistConfig<any> = {
  key: 'settingsReducer',
  storage,
  whitelist: ['selectedOrganization'],
};
const settingsReducer = createPersistReducer(settingsPersistConfig, settingsReducerBase);

const pageSettingPersistConfig: PersistConfig<any> = {
  key: 'pageSettingReducer',
  storage,
  whitelist: ['pageSettings'],
};

const pageSettingReducer = createPersistReducer(pageSettingPersistConfig, pageSettingReducerBase);

const authPersistConfig: PersistConfig<any> = {
  key: 'authReducer',
  storage,
  whitelist: ['token'],
};
const authReducer = createPersistReducer(authPersistConfig, authReducerBase);

export const store = configureStore({
  reducer: {
    snackbarReducer,
    authReducer,
    settingsReducer,
    webAppReducer,
    pageSettingReducer,
    usersReducer,
    organizationReducer,
    devicesReducer,
    adminDeviceReducer,
    deviceCategoriesReducer,
    deviceModelsReducer,
    deviceSetupWizardReducer,
    notificationSubscriptionsReducer,
    locationsReducer,
    timeZoneReducer,
  },
  // Middleware declard here now as old method was deprecated. Auth interceptor no longer used as functionality is handled elsewere.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // Needed as Date object is considered un-serializable by redux
      serializableCheck: false,
    }),
});
setStore(store);
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppState, unknown, Action<string>>;
