import {createSlice} from '@reduxjs/toolkit';
import {AppState} from '../../store';
import { setOrganizationSetting } from '../settings/settings-slice';
import {getDeviceCategories} from './operation';
import {DeviceCategoriesState} from './types';

const initialState: DeviceCategoriesState = {
  deviceCategories: [] as any,
  pending: false,
  isSuccess: false,
};

export const deviceCategorySlice = createSlice({
  name: 'deviceCategories',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get
    builder.addCase(getDeviceCategories.fulfilled, (state, action) => {
      state.pending = false;
      state.deviceCategories = action.payload;
    });

    builder.addCase(getDeviceCategories.pending, (state, _) => {
      state.pending = true;
    });

    builder.addCase(getDeviceCategories.rejected, (state, _) => {
      state.pending = false;
    });

    // Resets when new organization is selected
    builder.addCase(setOrganizationSetting, (state) => {
      state.deviceCategories = initialState.deviceCategories;
    });
  },
});

export const selectDeviceCategories = (store: AppState) => store.deviceCategoriesReducer.deviceCategories;

// eslint-disable-next-line
export const {} = deviceCategorySlice.actions;

export default deviceCategorySlice.reducer;
