import { Box, SxProps, Typography } from '@mui/material';
import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { localized } from '../../../../i18n/i18n';
import { updateActiveUser } from '../../../../state/features/users/operation';
import { AppState } from '../../../../state/store';
import { sidebarIconColor } from '../../../../styles/color-constants';
import { theme } from '../../../../styles/theme';
import { FlexRow } from '../../default-components/flex-row';
import { StyledCheckbox } from '../StyledCheckbox';

const styles: { [key: string]: SxProps } = {
  text: {
    fontSize: theme.typography.body1,
    marginLeft: 2,
  },
  helperText: {
    fontSize: theme.typography.body1,
    marginLeft: 7,
    marginTop: -1,
    color: sidebarIconColor,
  },
  spacer: {
    marginBottom: 5,
  },
};

export const KnowMyWayAround: FC = React.memo(() => {
  const dispatch = useDispatch();
  const activeUser = useSelector((store: AppState) => store.usersReducer.activeUser);

  const checkChanged = useCallback(() => {
    if (activeUser) {
      dispatch(updateActiveUser({ ...activeUser, ignoreTutorial: !activeUser.ignoreTutorial }));
    }
  }, [activeUser, dispatch]);

  return (
    <Box sx={styles.spacer} id="test">
      <FlexRow alignItems={'center'}>
        <StyledCheckbox checked={activeUser?.ignoreTutorial} onChange={checkChanged} border={true} />
        <Typography sx={styles.text}>{localized('KnowMyWayAround')}</Typography>
      </FlexRow>
      <Typography sx={styles.helperText}>{localized('KnowMyWayAroundHelperText')}</Typography>
    </Box>
  );
});
