import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, { FC } from 'react';
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import { OrganizationRole } from './api/api';
import { AppLayout } from './app-layout';
import { Callback } from './auth/callback';
import { PrivateRoute } from './auth/private-route';
import { routes } from './state/routes';
import { Help } from './view/pages/help/help';
import { HelpFAQ } from './view/pages/help/help-faq';
import { Insights } from './view/pages/insights/insights';
import { Locations } from './view/pages/locations/locations';
import { NotificationsLocationsRooms } from './view/pages/notifications/for-locations/notifications-locations-rooms';
import { NotificationsUsersOptions } from './view/pages/notifications/for-users/notifications-users-options';
import { Notifications } from './view/pages/notifications/notifications';
import { Settings } from './view/pages/settings/settings';
import { UnitsRoute } from './view/pages/units/units-route';
import { Users } from './view/pages/users/users';
import { UsersAdministrators } from './view/pages/users/users-administrators';
import { UsersMaintenance } from './view/pages/users/users-maintenance';
import { UsersManagers } from './view/pages/users/users-managers';
import { LoggedOut } from './auth/logged-out';

export const useLayoutStyles = makeStyles((theme: Theme) =>
  createStyles({
    mobileOnly: {
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    desktopOnly: {
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    appContent: {
      flex: 1,
    },
  }),
);

export const AppRoutes: FC = React.memo(() => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/" element={<AppLayout />}>
          <Route element={<PrivateRoute />}>
            <Route path={routes.insights} element={<Insights />} />
            <Route path={routes.users} element={<Users />}>
              <Route path={routes.usersAdministrators} element={<UsersAdministrators />} />
              <Route path={routes.usersManagers} element={<UsersManagers />} />
              <Route path={routes.usersMaintenanceStaff} element={<UsersMaintenance />} />
            </Route>
            <Route path={routes.locations} element={<Locations />} />
            <Route path={routes.units} element={<UnitsRoute />} />
            <Route path={routes.notifications} element={<Notifications />}>
              <Route path={routes.notificationsLocations} element={<NotificationsLocationsRooms />} />
              <Route
                path={routes.notificationsAdministrators}
                element={<NotificationsUsersOptions role={OrganizationRole.Admin} />}
              />
              <Route
                path={routes.notificationsManagers}
                element={<NotificationsUsersOptions role={OrganizationRole.Manager} />}
              />
              <Route
                path={routes.notificationsMaintenanceStaff}
                element={<NotificationsUsersOptions role={OrganizationRole.Maintenance} />}
              />
            </Route>
            <Route path={routes.settings} element={<Settings />} />
            <Route path={routes.help} element={<Help />} />
            <Route path={routes.helpFAQ} element={<HelpFAQ />} />
          </Route>
        </Route>
        <Route path={routes.loggedOut} element={<LoggedOut />} />
        <Route path={routes.callback} element={<Callback />} />
      </>,
    ),
  );

  return <RouterProvider router={router} />;
});
