import { Typography } from '@mui/material';
import React from 'react';
import { ACTIONS, CallBackProps, Step } from 'react-joyride';
import { NavigateFunction } from 'react-router-dom';
import { Dispatch } from 'redux';
import { localized, localizedInterpolation } from '../../../../../i18n/i18n';
import { setCurrentStage } from '../../../../../state/features/device-setup-wizard/device-setup-wizard-slice';
import {
  ConnectAndAddNewUnitStepTargets,
  asClassnameRef,
  asIdRef,
} from '../../../../../utilities/enums/tutorial-steps-target';
import {
  defaultStepConfigs,
  defaultTutorialState,
  resetTriggered,
  successfulBackward,
  successfulForward
} from '../../../../../utilities/helpers/tutorial-helper';
import { TutorialState } from '../../../../../utilities/providers/tutorial-provider';

export const connectAndAddNewUnitLogic = (
  data: CallBackProps,
  navigate: NavigateFunction,
  dispatch: Dispatch<any>,
  setState: (patch: Partial<TutorialState> | ((prevState: TutorialState) => Partial<TutorialState>)) => void,
) => {
  const { status, action, index, lifecycle } = data;
  const nextStep = action === ACTIONS.NEXT ? index + 1 : index - 1;
  let buttonStatus = { showContinue: true, showBack: true };

  if (successfulForward(action, lifecycle)) {
    switch (nextStep) {
      case 1:
        buttonStatus.showContinue = false;
        buttonStatus.showBack = false;
        break;
      case 2:
        buttonStatus.showContinue = false;
        dispatch(setCurrentStage('ConnectId'));
        break;
      case 3:
        buttonStatus.showContinue = false;
        dispatch(setCurrentStage('Product'));
        break;
      case 4:
        buttonStatus.showContinue = false;
        dispatch(setCurrentStage('Model'));
        break;
      case 5:
        buttonStatus.showContinue = false;
        dispatch(setCurrentStage('Location'));
        break;
      case 6:
        break;
      case 7:
        dispatch(setCurrentStage('Settings'));
        break;
      case 8:
        dispatch(setCurrentStage('Settings'));
        break;
      case 9:
        dispatch(setCurrentStage('Settings'));
        break;
      case 10:
        buttonStatus.showContinue = false;
        break;
      case 11:
        break;
      case 12:
        break;
    }

    setState({
      stepIndex: nextStep,
      buttonStatus: buttonStatus,
    });
  } else if (successfulBackward(action, lifecycle)) {
    switch (nextStep) {
      case 1:
        buttonStatus.showBack = false;
        break;
      case 2:
        dispatch(setCurrentStage('ConnectId'));
        buttonStatus.showBack = false;
        buttonStatus.showContinue = false;
        break;
      case 3:
        dispatch(setCurrentStage('Product'));
        buttonStatus.showContinue = false;
        break;
      case 4:
        dispatch(setCurrentStage('Model'));
        buttonStatus.showContinue = false;
        break;
      case 5:
        break;
      case 6:
        dispatch(setCurrentStage('Location'));
        // Breaks if we go back to selector list, because it's not mountet
        setState({
          stepIndex: 5,
          buttonStatus: {
            showContinue: false,
            showBack: true,
          },
        });
        return;
      case 7:
        dispatch(setCurrentStage('Settings'));
        break;
      case 8:
        dispatch(setCurrentStage('Settings'));
        break;
      case 9:
        dispatch(setCurrentStage('Settings'));
        break;
      case 10:
        break;
      case 11:
        buttonStatus.showBack = false;
        break;
      case 12:
        break;
    }

    setState({
      stepIndex: nextStep,
      buttonStatus: buttonStatus,
    });
  } else if (resetTriggered(status, action, lifecycle)) {
    setState(defaultTutorialState);
  }
};

export const connectAndAddNewUnitSteps: Step[] = [
  {
    ...defaultStepConfigs,
    title: (
      <Typography fontWeight={'bold'}>
        {localizedInterpolation('TutorialStep1Title', { menuItem: localized('Units') })}
      </Typography>
    ),
    target: asIdRef(ConnectAndAddNewUnitStepTargets.Step1),
  },
  {
    ...defaultStepConfigs,
    title: <Typography fontWeight={'bold'}>{localized('ConnectAndAddNewUnitStep2Title')}</Typography>,
    target: asIdRef(ConnectAndAddNewUnitStepTargets.Step2),
  },
  {
    ...defaultStepConfigs,
    title: <Typography fontWeight={'bold'}>{localized('ConnectAndAddNewUnitStep3Title')}</Typography>,
    content: <Typography>{localized('ConnectAndAddNewUnitStep3Content')}</Typography>,
    target: asIdRef(ConnectAndAddNewUnitStepTargets.Step3),
  },
  {
    ...defaultStepConfigs,
    title: <Typography fontWeight={'bold'}>{localized('ConnectAndAddNewUnitStep4Title')}</Typography>,
    target: asIdRef(ConnectAndAddNewUnitStepTargets.Step4),
  },
  {
    ...defaultStepConfigs,
    title: <Typography fontWeight={'bold'}>{localized('ConnectAndAddNewUnitStep5Title')}</Typography>,
    content: <Typography>{localized('ConnectAndAddNewUnitStep5Content')}</Typography>,
    target: asIdRef(ConnectAndAddNewUnitStepTargets.Step5),
  },
  {
    ...defaultStepConfigs,
    title: <Typography fontWeight={'bold'}>{localized('ConnectAndAddNewUnitStep6Title')}</Typography>,
    target: asIdRef(ConnectAndAddNewUnitStepTargets.Step6),
  },
  {
    ...defaultStepConfigs,
    title: <Typography fontWeight={'bold'}>{localized('ConnectAndAddNewUnitStep6Title')}</Typography>,
    // Classname because we want multiple targets in selector',
    target: asClassnameRef(ConnectAndAddNewUnitStepTargets.Step7),
  },
  {
    ...defaultStepConfigs,
    title: <Typography fontWeight={'bold'}>{localized('ConnectAndAddNewUnitStep8Title')}</Typography>,
    content: <Typography>{localized('ConnectAndAddNewUnitStep8Content')}</Typography>,
    target: asIdRef(ConnectAndAddNewUnitStepTargets.Step8),
  },
  {
    ...defaultStepConfigs,
    content: <Typography>{localized('ConnectAndAddNewUnitStep9Content')}</Typography>,
    target: asIdRef(ConnectAndAddNewUnitStepTargets.Step9),
  },
  {
    ...defaultStepConfigs,
    title: <Typography fontWeight={'bold'}>{localized('ConnectAndAddNewUnitStep10Title')}</Typography>,
    content: <Typography>{localized('ConnectAndAddNewUnitStep10Content')}</Typography>,
    target: asIdRef(ConnectAndAddNewUnitStepTargets.Step10),
  },
  {
    ...defaultStepConfigs,
    title: <Typography fontWeight={'bold'}>{localized('ConnectAndAddNewUnitStep11Title')}</Typography>,
    target: asIdRef(ConnectAndAddNewUnitStepTargets.Step11),
  },
  {
    ...defaultStepConfigs,
    title: <Typography fontWeight={'bold'}>{localized('ConnectAndAddNewUnitStep12Title')}</Typography>,
    target: asIdRef(ConnectAndAddNewUnitStepTargets.Step12),
    spotlightClicks: false,
  },
  {
    ...defaultStepConfigs,
    title: (
      <Typography fontWeight={'bold'}>
        {localizedInterpolation('TutorialLastStepTitle', { item: localized('Unit').toLowerCase() })}
      </Typography>
    ),
    content: <Typography>{localized('TutorialLastStepContent')}</Typography>,
    target: asIdRef(ConnectAndAddNewUnitStepTargets.LastStep),
    spotlightClicks: false,
    placement: 'center',
  },
];
