import { FilledTextFieldProps, OutlinedInputProps, SxProps, TextField } from '@mui/material';
import React, { FC, useCallback, useMemo } from 'react';
import { primaryTextColor } from '../../../styles/color-constants';

interface Props extends Omit<FilledTextFieldProps, 'variant'> {
  name: string;
  label?: string;
  placeholder?: string;
  height?: string;
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  inputProps?: { min: number };
}

export const LargeTextField: FC<Props> = React.memo((props) => {
  const { label, name, value, handleChange, sx, height, disabled, multiline } = props;
  const textFieldStyle: SxProps = useMemo(
    () => ({
      backgroundColor: 'transparent !important',
      justifyContent: 'center',
      '& .MuiFilledInput-root': {
        borderRadius: '4px',
        backgroundColor: 'white',
        overflow: 'hidden',
        height: height ?? '70px',
      },
      '& .MuiFilledInput-root:hover': {
        backgroundColor: 'white',
      },
      '& .MuiFilledInput-root.Mui-focused': {
        backgroundColor: 'white',
      },
      '& .MuiFilledInput-input': {
        fontSize: '20px',
        marginBottom: label ? '0px' : '14px',
      },
      '& .MuiInputLabel-root': {
        color: primaryTextColor,
        marginTop: '5px',
        fontSize: '20px',
      },
      '& .MuiFormLabel-filled': {
        marginTop: '0px',
        transform: 'translate(12px, 7px) scale(0.5)',
      },
      '& .MuiInputLabel-root.Mui-focused': {
        marginTop: '0px',
        transform: 'translate(12px, 7px) scale(0.5)',
      },
    }),
    [height, label],
  );

  const localHandleChange = useCallback(
    (e) => {
      if (handleChange) {
        handleChange(e);
      }
    },
    [handleChange],
  );

  return (
    <TextField
      {...props}
      sx={{ ...textFieldStyle, ...sx }}
      label={label}
      placeholder={props.placeholder}
      name={name}
      value={value}
      onChange={localHandleChange}
      variant="filled"
      disabled={disabled}
      multiline={multiline}
      InputProps={{ disableUnderline: true, ...props.inputProps } as Partial<OutlinedInputProps>}
    />
  );
});
