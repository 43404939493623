import {Theme, Typography, useMediaQuery} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, {FC, useContext, useEffect, useState} from 'react';
import {headerBackgroundColor, primaryTextColor} from '../../../../styles/color-constants';
import {FlexColumn} from '../../../components/default-components/flex-column';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: headerBackgroundColor,
      padding: '66px 48px 8px 68px',
      height: 145,
      justifyContent: 'space-between',
      [theme.breakpoints.down(602)]: {
        paddingTop: 0,
      },
    },
    slim: {
      width: '100%',
      backgroundColor: headerBackgroundColor,
      padding: '66px 48px 8px 14px',
      height: 145,
      justifyContent: 'space-between',
      [theme.breakpoints.down(602)]: {
        paddingTop: 0,
      },
    },
    title: {
      color: primaryTextColor,
      fontWeight: 'bold',
      marginLeft: theme.spacing(4),
      fontSize: '50px',
    },
  }),
);

interface PropsFromParent {
  title: string;
  area: string;
}

export const PageHeaderContext = React.createContext({} as any);
export const usePageHeader = () => useContext(PageHeaderContext);

export const PageHeader: FC<PropsFromParent> = React.memo(({title, area, ...props}) => {
  const classes = useStyles();
  const wide = useMediaQuery('(min-width:1500px)');
  const [areaId, setAreaId] = useState<string>();
  useEffect(() => {
    setAreaId(area);
  }, [area]);

  return (
    <PageHeaderContext.Provider value={{areaId}}>
      <FlexColumn className={wide ? classes.root : classes.slim}>
        <Typography variant="h6" className={classes.title}>
          {title}
        </Typography>
        {props.children}
      </FlexColumn>
    </PageHeaderContext.Provider>
  );
});
