import React, { FC } from 'react';
import { Divider, Stack } from '@mui/material';
import { sideBarDividerColor } from '../../../styles/color-constants';

export const SideBarStack: FC = React.memo((props) => {
  return (
    <Stack spacing={'20px'} divider={<Divider sx={{ backgroundColor: sideBarDividerColor }} />}>
      {props.children}
    </Stack>
  );
});
