import { Typography } from '@mui/material';
import React from 'react';
import { ACTIONS, CallBackProps, Step } from 'react-joyride';
import { NavigateFunction } from 'react-router-dom';
import { Dispatch } from 'redux';
import { localized, localizedInterpolation } from '../../../../../i18n/i18n';
import { ManageNotificationsStepTargets, asIdRef } from '../../../../../utilities/enums/tutorial-steps-target';
import {
  defaultStepConfigs,
  defaultTutorialState,
  resetTriggered,
  successfulBackward,
  successfulForward,
} from '../../../../../utilities/helpers/tutorial-helper';
import { TutorialState } from '../../../../../utilities/providers/tutorial-provider';

export const manageNotificationsLogic = (
  data: CallBackProps,
  navigate: NavigateFunction,
  dispatch: Dispatch<any>,
  setState: (patch: Partial<TutorialState> | ((prevState: TutorialState) => Partial<TutorialState>)) => void,
) => {
  const { status, action, index, lifecycle } = data;
  const nextStep = action === ACTIONS.NEXT ? index + 1 : index - 1;
  let buttonStatus = { showContinue: true, showBack: true };

  if (successfulForward(action, lifecycle)) {
    switch (nextStep) {
      case 1:
        buttonStatus.showBack = false;
        break;
      case 2:
        buttonStatus.showContinue = false;
        break;
      case 3:
        buttonStatus.showBack = false;
        break;
      case 4:
        buttonStatus.showContinue = false;
        break;
      case 5:
        buttonStatus.showBack = false;
        break;
      case 6:
      case 7:
      case 8:
      case 9:
      case 10:
        break;
      case 11:
        buttonStatus.showContinue = false;
        break;
      case 12:
        return;
      case 13:
        buttonStatus.showBack = false;
        break;
      case 14:
        buttonStatus.showContinue = false;
        break;
      case 15:
        setState({
          stepIndex: 16,
          buttonStatus: {
            showContinue: true,
            showBack: false,
          },
        });
        return;
      case 16:
        buttonStatus.showBack = false;
        break;
    }

    setState({
      stepIndex: nextStep,
      buttonStatus: buttonStatus,
    });
  } else if (successfulBackward(action, lifecycle)) {
    switch (nextStep) {
      case 1:
      case 2:
      case 3:
      case 5:
      case 13:
      case 15:
      case 16:
        buttonStatus.showBack = false;
        break;
    }

    setState({
      stepIndex: nextStep,
      buttonStatus: buttonStatus,
    });
  } else if (resetTriggered(status, action, lifecycle)) {
    setState(defaultTutorialState);
  }
};

export const manageNotificationsStep: Step[] = [
  {
    ...defaultStepConfigs,
    title: (
      <Typography fontWeight={'bold'}>
        {localizedInterpolation('TutorialStep1Title', { menuItem: localized('Notifications') })}
      </Typography>
    ),
    target: asIdRef(ManageNotificationsStepTargets.Step1),
  },
  {
    ...defaultStepConfigs,
    title: <Typography fontWeight={'bold'}>{localized('ManageNotificationsStep2Title')}</Typography>,
    content: <Typography>{localized('ManageNotificationsStep2Content')}</Typography>,
    target: asIdRef(ManageNotificationsStepTargets.Step2),
  },
  {
    ...defaultStepConfigs,
    title: <Typography fontWeight={'bold'}>{localized('ManageNotificationsStep3Title')}</Typography>,
    content: <Typography>{localized('ManageNotificationsStep3Content')}</Typography>,
    target: asIdRef(ManageNotificationsStepTargets.Step3),
  },
  {
    ...defaultStepConfigs,
    title: <Typography fontWeight={'bold'}>{localized('ManageNotificationsStep4Title')}</Typography>,
    content: <Typography>{localized('ManageNotificationsStep3Content')}</Typography>,
    target: asIdRef(ManageNotificationsStepTargets.Step4),
  },
  {
    ...defaultStepConfigs,
    title: <Typography fontWeight={'bold'}>{localized('ManageNotificationsStep5Title')}</Typography>,
    target: asIdRef(ManageNotificationsStepTargets.Step5),
  },
  {
    ...defaultStepConfigs,
    title: <Typography fontWeight={'bold'}>{localized('ManageNotificationsStep6Title')}</Typography>,
    content: <Typography>{localized('ManageNotificationsStep6Content')}</Typography>,
    target: asIdRef(ManageNotificationsStepTargets.Step6),
  },
  {
    ...defaultStepConfigs,
    title: <Typography fontWeight={'bold'}>{localized('ManageNotificationsStep5Title')}</Typography>,
    content: <Typography>{localized('ManageNotificationsStep7Content')}</Typography>,
    target: asIdRef(ManageNotificationsStepTargets.Step7),
    placement: 'bottom',
  },
  {
    ...defaultStepConfigs,
    title: <Typography fontWeight={'bold'}>{localized('ManageNotificationsStep5Title')}</Typography>,
    content: <Typography>{localized('ManageNotificationsStep7Content')}</Typography>,
    target: asIdRef(ManageNotificationsStepTargets.Step8),
    placement: 'bottom',
  },
  {
    ...defaultStepConfigs,
    title: <Typography fontWeight={'bold'}>{localized('ManageNotificationsStep5Title')}</Typography>,
    content: <Typography>{localized('ManageNotificationsStep7Content')}</Typography>,
    target: asIdRef(ManageNotificationsStepTargets.Step9),
    placement: 'bottom',
  },
  {
    ...defaultStepConfigs,
    title: <Typography fontWeight={'bold'}>{localized('ManageNotificationsStep5Title')}</Typography>,
    content: <Typography>{localized('ManageNotificationsStep7Content')}</Typography>,
    target: asIdRef(ManageNotificationsStepTargets.Step10),
    placement: 'bottom',
  },
  {
    ...defaultStepConfigs,
    title: <Typography fontWeight={'bold'}>{localized('ManageNotificationsStep5Title')}</Typography>,
    content: <Typography>{localized('ManageNotificationsStep7Content')}</Typography>,
    target: asIdRef(ManageNotificationsStepTargets.Step11),
    placement: 'bottom',
  },
  {
    ...defaultStepConfigs,
    title: <Typography fontWeight={'bold'}>{localized('ManageNotificationsStep12Title')}</Typography>,
    content: <Typography>{localized('ManageNotificationsStep12Content')}</Typography>,
    target: asIdRef(ManageNotificationsStepTargets.Step12),
  },
  {
    ...defaultStepConfigs,
    content: <Typography fontWeight={'bold'}>{localized('ManageNotificationsStep13Content')}</Typography>,
    target: asIdRef(ManageNotificationsStepTargets.Step13),
  },
  {
    ...defaultStepConfigs,
    content: <Typography fontWeight={'bold'}>{localized('ManageNotificationsStep14Content')}</Typography>,
    target: asIdRef(ManageNotificationsStepTargets.Step14),
  },
  {
    ...defaultStepConfigs,
    title: <Typography fontWeight={'bold'}>{localized('ManageNotificationsStep15Title')}</Typography>,
    target: asIdRef(ManageNotificationsStepTargets.Step15),
  },
  {
    ...defaultStepConfigs,
    title: <Typography fontWeight={'bold'}>{localized('ManageNotificationsStep16Title')}</Typography>,
    content: <Typography>{localized('ManageNotificationsStep16Content')}</Typography>,
    target: asIdRef(ManageNotificationsStepTargets.Step16),
    placement: 'center',
    spotlightClicks: false,
  },
  {
    ...defaultStepConfigs,
    title: <Typography fontWeight={'bold'}>{localized('ManageNotificationsStep17Title')}</Typography>,
    content: <Typography>{localized('TutorialLastStepContent')}</Typography>,
    target: asIdRef(ManageNotificationsStepTargets.LastStep),
    spotlightClicks: false,
    placement: 'center',
  },
];
