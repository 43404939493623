import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IDeviceModel } from '../../../../../api/api';
import CT4000Img from '../../../../../assets/ct-4000-img-small.png';
import SCT1000Img from '../../../../../assets/sct-1000-img-small.png';
import SCT2000Img from '../../../../../assets/sct-2000-img-small.png';
import SCT3000Img from '../../../../../assets/sct-3000-img-small.png';
import { selectDeviceModels } from '../../../../../state/features/device-models/device-models-slice';
import { getDeviceModelsByCategory } from '../../../../../state/features/device-models/operation';
import { setDeviceModel } from '../../../../../state/features/device-setup-wizard/device-setup-wizard-slice';
import { AppState } from '../../../../../state/store';
import { FlexColumn } from '../../../default-components/flex-column';
import { SelectionButton } from '../../buttons/selection-button';
import { useTutorial } from '../../../../../utilities/providers/tutorial-provider';

export const ModelSelect: FC = React.memo(() => {
  const dispatch = useDispatch();
  const {
    setTutorialState,
    tutorialState: { tourActive },
  } = useTutorial();
  const deviceCategory = useSelector((store: AppState) => store.deviceSetupWizardReducer.category);
  const deviceModels = useSelector(selectDeviceModels);
  const imgDictionary: { [key: string]: JSX.Element } = useMemo(() => {
    return {
      'SCT 1000': <img src={SCT1000Img} alt={'SCT1000'} height={'60px'} />,
      'SCT 2000 / SCT 2100': <img src={SCT2000Img} alt={'SCT2000'} height={'60px'} />,
      'SCT 3000': <img src={SCT3000Img} alt={'SCT3000'} height={'60px'} />,
      'CT 4000 / CT 4100': <img src={CT4000Img} alt={'CT4100'} height={'60px'} />,
    };
  }, []);

  useEffect(() => {
    if (deviceCategory) {
      dispatch(getDeviceModelsByCategory(deviceCategory.id));
    }
  }, [deviceCategory, dispatch]);

  const handleModelPicked = useCallback(
    (deviceModel: IDeviceModel) => () => {
      dispatch(setDeviceModel(deviceModel));
      if (!tourActive) return;

      setTimeout(() => {
        setTutorialState({ stepIndex: 5 });
      }, 200);
    },
    [dispatch, setTutorialState, tourActive],
  );

  const getFirstNumberInString = useCallback((text: string) => {
    const index = text.search(/[0-9]/);
    return Number(text[index]);
  }, []);

  return (
    <FlexColumn sx={{ marginBottom: 4 }}>
      {deviceModels
        .slice()
        .sort((a, b) => (getFirstNumberInString(a.modelCode!) > getFirstNumberInString(b.modelCode!) ? 1 : -1))
        .map((model) => (
          <SelectionButton
            onClick={handleModelPicked(model)}
            key={model.id}
            icon={imgDictionary[model.modelCode ?? '']}>
            {model.modelCode}
          </SelectionButton>
        ))}
    </FlexColumn>
  );
});
