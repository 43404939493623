import { createTheme } from '@mui/material/styles';
import { SimplePaletteColorOptions } from '@mui/material/styles/createPalette';
import { CSSProperties } from 'react';
import {
  basicCardBoxShadow,
  blackColor,
  buttonColor,
  checkboxFilterBg,
  headerBackgroundColor,
  infoItem2BackgroundColor,
  primaryColor,
  primaryColorDarker,
  primaryTextColor,
  searchBgColor,
  secondaryColor,
  secondaryTextColor,
  sidebarBackgroundColor,
  sidebarBackgroundSelectedColor,
  sideBarProfileBackgroundColor,
  sidebarSelectedBorder,
  whiteColor,
} from './color-constants';
import { DefaultPalette } from './color-palette';

declare module '@mui/material/styles' {
  interface Palette {
    Example: SimplePaletteColorOptions;
  }
  interface PaletteOptions {
    Example?: SimplePaletteColorOptions;
  }

  interface TypographyVariants {
    body3: CSSProperties;
  }
  interface Theme {
    colors: {
      header: {
        headerBackgroundColor: CSSProperties['color'];
        headerIconColor: CSSProperties['color'];
        headerTextColor: CSSProperties['color'];
        headerSelectedTextColor: CSSProperties['color'];
        headerSelectedIconColor: CSSProperties['color'];
      };
      sideBar: {
        sidebarBackgroundColor: CSSProperties['color'];
        sidebarIconColor: CSSProperties['color'];
        sidebarIconSelectedColor: CSSProperties['color'];
        sidebarTextColor: CSSProperties['color'];
        sidebarBackgroundSelectedColor: CSSProperties['color'];
        sidebarSelectedBorder: CSSProperties['color'];
        sideBarProfileBackgroundColor: CSSProperties['color'];
      };
      contentWrapper: {
        contentWrapperBackgroundColor: CSSProperties['color'];
        contentWrapperTextColor: CSSProperties['color'];
      };
      basicCard: {
        basicCardBgColor: CSSProperties['color'];
        basicCardBoxShadow: CSSProperties['boxShadow'];
        basicCardTextColor: CSSProperties['color'];
      };
      button: {
        btnBackgroundColor: CSSProperties['color'];
        btnTextColor: CSSProperties['color'];
      };
      inputTextColor: CSSProperties['color'];
      toggle: {
        toggleButtonColor: CSSProperties['color'];
        toggleButtonColorSelected: CSSProperties['color'];
        toggleButtonTextColor: CSSProperties['color'];
      };
      switch: {
        switchThumbOnColor: CSSProperties['color'];
        switchThumbOffColor: CSSProperties['color'];
        switchTrackOnColor: CSSProperties['color'];
        switchTrackOffColor: CSSProperties['color'];
        switchBorderOnColor: CSSProperties['color'];
        switchBorderOffColor: CSSProperties['color'];
      };
      infoItem: {
        infoItemBackgroundColorPrimary: CSSProperties['color'];
        infoItemBackgroundColorSecondary: CSSProperties['color'];
        infoItemTextColor: CSSProperties['color'];
      };
      textField: {
        textFieldInputColor: CSSProperties['color'];
        textFieldLabelColor: CSSProperties['color'];
        textFieldSuffixColor: CSSProperties['color'];
        textFieldPrefixColor: CSSProperties['color'];
      };
      checkBoxFilter: {
        checkboxFilterBg: CSSProperties['color'];
        checkboxChecked: CSSProperties['color'];
        checkBoxUnChecked: CSSProperties['color'];
      };
      searchInput: {
        searchBgColor: CSSProperties['color'];
        searchTextColor: CSSProperties['color'];
      };
      listTable: {
        ListTableBgColor: CSSProperties['color'];
        ListTableTextColor: CSSProperties['color'];
      };
      dropdown: {
        backgroundColor: CSSProperties['color'];
        textColor: CSSProperties['color'];
      };
      datePicker?: {
        backgroundColor?: CSSProperties['color'];
        textColor?: CSSProperties['color'];
      };
      largeButton: {
        backgroundColor: CSSProperties['color'];
        textColor: CSSProperties['color'];
        selectedBottomColor: CSSProperties['color'];
        selectedBackgroundColor: CSSProperties['color'];
        selectedTextColor: CSSProperties['color'];
        hoverBorderColor: CSSProperties['color'];
        hoverBackgroundColor: CSSProperties['color'];
        hoverTextColor: CSSProperties['color'];
      };
      textButton: {
        backgroundColor: CSSProperties['color'];
        textColor: CSSProperties['color'];
        specialTextColor: CSSProperties['color'];
      };
      checkBox: {
        backgroundColor: CSSProperties['color'];
        boarderColor: CSSProperties['color'];
        checkmarkColor: CSSProperties['color'];
      };
      unitsSearchbar: {
        backgroundColor: CSSProperties['color'];
        textColor: CSSProperties['color'];
      };
      danger: CSSProperties['color'];
    };
  }
  interface ThemeOptions {
    colors: {
      header?: {
        headerBackgroundColor?: CSSProperties['color'];
        headerIconColor?: CSSProperties['color'];
        headerTextColor?: CSSProperties['color'];
        headerSelectedTextColor?: CSSProperties['color'];
        headerSelectedIconColor?: CSSProperties['color'];
      };
      sideBar?: {
        sidebarBackgroundColor?: CSSProperties['color'];
        sidebarIconColor?: CSSProperties['color'];
        sidebarIconSelectedColor?: CSSProperties['color'];
        sidebarTextColor?: CSSProperties['color'];
        sidebarBackgroundSelectedColor?: CSSProperties['color'];
        sidebarSelectedBorder?: CSSProperties['color'];
        sideBarProfileBackgroundColor?: CSSProperties['color'];
      };
      contentWrapper?: {
        contentWrapperBackgroundColor?: CSSProperties['color'];
        contentWrapperTextColor?: CSSProperties['color'];
      };
      basicCard?: {
        basicCardBgColor?: CSSProperties['color'];
        basicCardBoxShadow?: CSSProperties['boxShadow'];
        basicCardTextColor?: CSSProperties['color'];
      };
      button?: {
        btnBackgroundColor?: CSSProperties['color'];
        btnTextColor?: CSSProperties['color'];
      };
      inputTextColor?: CSSProperties['color'];
      toggle?: {
        toggleButtonColor?: CSSProperties['color'];
        toggleButtonColorSelected?: CSSProperties['color'];
        toggleButtonTextColor?: CSSProperties['color'];
      };
      switch?: {
        switchThumbOnColor?: CSSProperties['color'];
        switchThumbOffColor?: CSSProperties['color'];
        switchTrackOnColor?: CSSProperties['color'];
        switchTrackOffColor?: CSSProperties['color'];
        switchBorderOnColor?: CSSProperties['color'];
        switchBorderOffColor?: CSSProperties['color'];
      };
      infoItem?: {
        infoItemBackgroundColorPrimary?: CSSProperties['color'];
        infoItemBackgroundColorSecondary?: CSSProperties['color'];
        infoItemTextColor?: CSSProperties['color'];
      };
      textField?: {
        textFieldInputColor?: CSSProperties['color'];
        textFieldLabelColor?: CSSProperties['color'];
        textFieldSuffixColor?: CSSProperties['color'];
        textFieldPrefixColor?: CSSProperties['color'];
      };
      checkBoxFilter?: {
        checkboxFilterBg?: CSSProperties['color'];
        checkboxChecked?: CSSProperties['color'];
        checkBoxUnChecked?: CSSProperties['color'];
      };
      searchInput?: {
        searchBgColor?: CSSProperties['color'];
        searchTextColor?: CSSProperties['color'];
      };
      listTable?: {
        ListTableBgColor?: CSSProperties['color'];
        ListTableTextColor?: CSSProperties['color'];
      };
      dropdown?: {
        backgroundColor?: CSSProperties['color'];
        textColor?: CSSProperties['color'];
      };
      datePicker?: {
        backgroundColor?: CSSProperties['color'];
        textColor?: CSSProperties['color'];
      };
      largeButton: {
        backgroundColor: CSSProperties['color'];
        textColor: CSSProperties['color'];
        selectedBottomColor: CSSProperties['color'];
        selectedBackgroundColor: CSSProperties['color'];
        selectedTextColor: CSSProperties['color'];
        hoverBorderColor: CSSProperties['color'];
        hoverBackgroundColor: CSSProperties['color'];
        hoverTextColor: CSSProperties['color'];
      };
      textButton: {
        backgroundColor: CSSProperties['color'];
        textColor: CSSProperties['color'];
        specialTextColor: CSSProperties['color'];
      };
      checkBox: {
        backgroundColor: CSSProperties['color'];
        boarderColor: CSSProperties['color'];
        checkmarkColor: CSSProperties['color'];
      };
      unitsSearchbar: {
        backgroundColor: CSSProperties['color'];
        textColor: CSSProperties['color'];
      };
      danger?: CSSProperties['color'];
    };
  }
  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    body3?: CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body3: true;
  }
}

export const theme = createTheme({
  colors: {
    header: {
      headerBackgroundColor: headerBackgroundColor,
      headerIconColor: primaryTextColor,
      headerTextColor: primaryTextColor,
      headerSelectedTextColor: primaryColor,
      headerSelectedIconColor: primaryColor,
    },
    sideBar: {
      sidebarBackgroundColor: sidebarBackgroundColor,
      sidebarIconColor: secondaryColor,
      sidebarTextColor: secondaryTextColor,
      sidebarBackgroundSelectedColor: sidebarBackgroundSelectedColor,
      sidebarSelectedBorder: sidebarSelectedBorder,
      sideBarProfileBackgroundColor: sideBarProfileBackgroundColor,
    },
    contentWrapper: {
      contentWrapperBackgroundColor: secondaryColor,
      contentWrapperTextColor: secondaryTextColor,
    },
    basicCard: {
      basicCardBgColor: whiteColor,
      basicCardBoxShadow: basicCardBoxShadow,
      basicCardTextColor: secondaryTextColor,
    },
    button: {
      btnBackgroundColor: primaryColor,
      btnTextColor: primaryTextColor,
    },
    inputTextColor: secondaryTextColor,
    toggle: {
      toggleButtonColor: primaryTextColor,
      toggleButtonColorSelected: primaryColor,
      toggleButtonTextColor: whiteColor,
    },
    switch: {
      switchThumbOnColor: whiteColor,
      switchThumbOffColor: primaryTextColor,
      switchTrackOnColor: primaryColor,
      switchTrackOffColor: whiteColor,
      switchBorderOnColor: primaryColor,
      switchBorderOffColor: primaryTextColor,
    },
    infoItem: {
      infoItemBackgroundColorPrimary: secondaryColor,
      infoItemBackgroundColorSecondary: infoItem2BackgroundColor,
      infoItemTextColor: secondaryTextColor,
    },
    textField: {
      textFieldInputColor: secondaryTextColor,
      textFieldLabelColor: primaryTextColor,
      textFieldSuffixColor: primaryTextColor,
      textFieldPrefixColor: primaryTextColor,
    },
    checkBoxFilter: {
      checkboxFilterBg: checkboxFilterBg,
      checkboxChecked: primaryColor,
      checkBoxUnChecked: primaryTextColor,
    },
    searchInput: {
      searchBgColor: searchBgColor,
      searchTextColor: secondaryTextColor,
    },
    listTable: {
      ListTableBgColor: secondaryColor,
      ListTableTextColor: secondaryTextColor,
    },
    dropdown: {
      backgroundColor: secondaryTextColor,
      textColor: sidebarBackgroundColor,
    },
    datePicker: {
      backgroundColor: secondaryTextColor,
      textColor: sidebarBackgroundColor,
    },
    largeButton: {
      backgroundColor: primaryColor,
      textColor: primaryTextColor,
      selectedBottomColor: buttonColor,
      selectedBackgroundColor: buttonColor,
      selectedTextColor: whiteColor,
      hoverBorderColor: buttonColor,
      hoverBackgroundColor: primaryColorDarker,
      hoverTextColor: primaryTextColor,
    },
    textButton: {
      backgroundColor: whiteColor,
      textColor: primaryTextColor,
      specialTextColor: buttonColor,
    },
    checkBox: {
      backgroundColor: whiteColor,
      boarderColor: buttonColor,
      checkmarkColor: blackColor,
    },
    unitsSearchbar: {
      backgroundColor: primaryColor,
      textColor: primaryTextColor,
    },
  },
  palette: DefaultPalette,
  typography: {
    fontFamily: ['Poppins', 'Arial'].join(','),
    fontSize: 14,
    body3: {
      fontSize: 12,
    },
  },
});
