import {Theme} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import {TransitionProps} from '@mui/material/transitions';
import React, {FC} from 'react';
import {headerBackgroundColor} from '../../../../styles/color-constants';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {children: React.ReactElement<any, any>},
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface PropsFromParent {
  open: boolean;
}

const StyledDialog = withStyles((theme: Theme) => ({
  root: {},
  paper: {
    minWidth: 400,
    padding: theme.spacing(3, 8, 3, 8),
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 'unset',
  },
}))(Dialog);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      minWidth: 600,
    },
  }),
);

export const BasicModal: FC<PropsFromParent> = React.memo(({open, children}) => {
  const classes = useStyles();
  return (
    <StyledDialog
      BackdropProps={{style: {background: 'transparent'}}}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      PaperProps={{sx: {backgroundColor: headerBackgroundColor}}}
      className={classes.container}>
      {children}
    </StyledDialog>
  );
});
