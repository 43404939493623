import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getUserByAuthId } from '../state/features/users/operation';
import { routes } from '../state/routes';
import { AppState } from '../state/store';
import { useAuth } from './auth-provider';

export const Callback: FC = () => {
  const { isAuthenticated } = useAuth();

  const activeUser = useSelector((store: AppState) => store.usersReducer.activeUser);
  const { initLogin, initLoginSilent, pending, accessToken } = useSelector((store: AppState) => store.authReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getUserByAuthId());
    } else {
      // Something must have gone wrong after reading the hash - navigate to trigger a new login
      if (location.hash === '') {
        navigate('/');
      }
    }
  }, [dispatch, isAuthenticated, navigate]);

  useEffect(() => {
    if (isAuthenticated && activeUser) {
      navigate(activeUser.ignoreTutorial ? routes.insights : routes.help);
    }
  }, [activeUser, isAuthenticated, navigate]);

  // This page is shown after login redirect and before the hash is processed
  return null;
};
