import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, IconButton, SxProps, Typography } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { StoreHelpers, TooltipRenderProps } from 'react-joyride';
import { localized } from '../../../../i18n/i18n';
import { secondaryColor, sidebarBackgroundColor, sidebarIconColor } from '../../../../styles/color-constants';
import { theme } from '../../../../styles/theme';
import { TutorialState } from '../../../../utilities/providers/tutorial-provider';
import { FlexRow } from '../../default-components/flex-row';
import { NavigateFunction } from 'react-router-dom';
import { routes } from '../../../../state/routes';

const styles: { [key: string]: SxProps } = {
  tooltipBox: {
    backgroundColor: sidebarBackgroundColor,
    padding: 1,
    borderRadius: 2,
    color: secondaryColor,
    minWidth: '350px',
    maxWidth: '500px',
  },
  contentBox: {
    paddingX: 1,
    paddingBottom: 1,
  },
  contentTextBox: {
    marginBottom: 5,
  },
  title: {
    fontSize: '11px',
    fontWeight: 'bold',
  },
  buttonsBox: {
    justifyContent: 'space-between',
  },
  buttonBox: {
    justifyContent: 'flex-end',
  },
  fowardButton: {
    textTransform: 'none',
    fontWeight: 'bold',
    color: theme.colors.textButton.specialTextColor,
    paddingRight: 0,
  },
  backButton: {
    textTransform: 'none',
    fontWeight: 'bold',
    color: sidebarIconColor,
    paddingLeft: 0,
  },
  closeBox: {
    justifyContent: 'flex-end',
  },
  closeButton: {
    color: sidebarIconColor,
  },
};

export const TutorialTooltip = (
  {
    backProps,
    continuous,
    index,
    isLastStep,
    primaryProps,
    closeProps,
    skipProps,
    step,
    tooltipProps,
  }: TooltipRenderProps,
  tutorialState: TutorialState,
  storeHelpers: StoreHelpers,
  navigate: NavigateFunction,
) => {
  const closeAndRedirect = useCallback(() => {
    storeHelpers.close();
    navigate(routes.help);
  }, [navigate, storeHelpers]);

  const escFunction = useCallback(
    (event) => {
      if (event.key === 'Escape') {
        storeHelpers.close();
      }
    },
    [storeHelpers],
  );

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, [escFunction, isLastStep]);

  return (
    <Box sx={styles.tooltipBox} {...tooltipProps}>
      <FlexRow sx={styles.closeBox}>
        <IconButton id="close" size="small" sx={styles.closeButton} {...closeProps}>
          <CloseIcon />
        </IconButton>
      </FlexRow>
      <Box sx={styles.contentBox}>
        <Box sx={styles.contentTextBox} whiteSpace={'pre-line'}>
          {step.title && (
            <>
              {step.title}
              <br />
            </>
          )}
          {step.content && step.content}
        </Box>
        <FlexRow sx={styles.buttonsBox}>
          {tutorialState.buttonStatus.showBack && (
            <Button sx={styles.backButton} {...backProps}>
              <ArrowBackIosIcon />
              <Typography id={'back'}>{localized('Back')} </Typography>
            </Button>
          )}
          {/*div always pushing the buttons to the side, when one is missing */}
          <div></div>
          {isLastStep ? (
            <Button sx={styles.fowardButton} onClick={closeAndRedirect}>
              <Typography id="close" fontWeight={'bold'}>
                {localized('ExploreMore')}
              </Typography>
              <ArrowForwardIosIcon />
            </Button>
          ) : (
            tutorialState.buttonStatus.showContinue && (
              <Button sx={styles.fowardButton} {...primaryProps}>
                <Typography fontWeight={'bold'} id={'next'}>
                  {localized('Continue')}
                </Typography>
                <ArrowForwardIosIcon />
              </Button>
            )
          )}
        </FlexRow>
      </Box>
    </Box>
  );
};
