import { StyledEngineProvider, Theme, ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { SnackbarProvider } from 'notistack';
import React, { FC, useCallback } from 'react';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { AppRoutes } from './app-routes';
import { GeneralAuthProvider } from './auth/auth-provider';
import { store } from './state/store';
import { theme } from './styles/theme';
import { GoogleMapsProvider } from './utilities/google-maps-provider';
import { DialogProvider } from './utilities/providers/dialog-provider';
import { Snackbar } from './view/components/default-components/snackbar/snackbar';
import { SnackbarCloseButton } from './view/components/default-components/snackbar/snackbar-close-button';
import './app.css';
declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

// Persist the whole store
const persistor = persistStore(store);

export const App: FC = () => {
  const getCloseButton = useCallback((key: number | string) => {
    return <SnackbarCloseButton snackKey={key as number} />;
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <DialogProvider>
              <GoogleMapsProvider>
                <GeneralAuthProvider>
                  <SnackbarProvider maxSnack={4} action={getCloseButton}>
                    <CssBaseline />
                    <Snackbar />
                    <AppRoutes />
                  </SnackbarProvider>
                </GeneralAuthProvider>
              </GoogleMapsProvider>
            </DialogProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </PersistGate>
    </Provider>
  );
};
