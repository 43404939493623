import { IconButton, TableCell, TableRow } from '@mui/material';
import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IUser, OrganizationRole, User } from '../../../../../api/api';
import { DeleteIcon } from '../../../../../assets/icons/delete-icon/delete-icon';
import { EditIcon } from '../../../../../assets/icons/edit-icon/edit-icon';
import { useAuth } from '../../../../../auth/auth-provider';
import { localized, localizedInterpolation } from '../../../../../i18n/i18n';
import { selectOrganization } from '../../../../../state/features/settings/settings-slice';
import { deleteFromOrganization } from '../../../../../state/features/users/operation';
import { selectUserByAuthId } from '../../../../../state/features/users/users-slice';
import { buttonColor, primaryColor } from '../../../../../styles/color-constants';
import { getJob } from '../../../../../utilities/helpers/organization-enum-to-string';
import { useDialog } from '../../../../../utilities/providers/dialog-provider';
import { EditUserProfile } from '../../../../pages/users/side-content/edit-user-profile';
import { useRightSidebar } from '../../right-side-bar';
import { tableStyles } from '../table-list';

interface Props {
  user: IUser;
  id?: string;
}

export const UserMaintenanceTableRow: FC<Props> = React.memo((props) => {
  const { user, id } = props;
  const dispatch = useDispatch();
  const { openDeleteDialog } = useDialog();
  const { isManager, authId } = useAuth();
  const setSidebarContent = useRightSidebar();
  const selectedOrganization = useSelector(selectOrganization);
  const activeUser = useSelector(selectUserByAuthId(authId));

  const editUser = useCallback(
    (userId: number) => () => {
      setSidebarContent(<EditUserProfile userId={userId} role={OrganizationRole.Maintenance} />);
    },
    [setSidebarContent],
  );
  const onDelete = useCallback(() => {
    if (!selectedOrganization) return;

    openDeleteDialog(
      localizedInterpolation('ConfirmDeleteInt', { entityName: localized('User').toLocaleLowerCase() }),
      () => {
        dispatch(deleteFromOrganization({ user: user as User, organizationId: selectedOrganization.id }));
      },
    );
  }, [dispatch, openDeleteDialog, user, selectedOrganization]);

  const canEdit = isManager || user.id === activeUser?.id;

  return (
    <TableRow key={user.phoneNumber}>
      <TableCell component="th" scope="row" sx={tableStyles.rowCell}>
        {user.name}
      </TableCell>
      <TableCell align="left" sx={tableStyles.rowCell}>
        {user.email}
      </TableCell>
      <TableCell align="left" sx={tableStyles.rowCell}>
        {user.phoneNumber}
      </TableCell>
      <TableCell align="left" sx={tableStyles.rowCell}>
        {getJob(user, selectedOrganization?.id)}
      </TableCell>
      <TableCell align="center" padding="none" sx={tableStyles.rowCell} id={id}>
        <IconButton onClick={editUser(user.id)} disabled={!canEdit}>
          <EditIcon color={!canEdit ? primaryColor : buttonColor} />
        </IconButton>
      </TableCell>
      <TableCell align="center" padding="none" sx={tableStyles.rowCell}>
        <IconButton onClick={onDelete} disabled={!canEdit}>
          <DeleteIcon color={!canEdit ? primaryColor : buttonColor} />
        </IconButton>
      </TableCell>
    </TableRow>
  );
});
