import { Box, SxProps, TableCell, TableRow, Typography } from '@mui/material';
import React, { FC, memo, useCallback } from 'react';
import { CallBackProps, Step } from 'react-joyride';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Dispatch } from 'redux';
import { localized } from '../../../../i18n/i18n';
import { primaryColorDarker } from '../../../../styles/color-constants';
import { theme } from '../../../../styles/theme';
import { TutorialState, useTutorial } from '../../../../utilities/providers/tutorial-provider';
import { FlexRow } from '../../default-components/flex-row';
import { GoToButton } from '../buttons/go-to-button';
export const styles: { [key: string]: SxProps } = {
  combinedNumberAndTextCell: {
    alignItems: 'center',
  },
  tutorialNumberBox: {
    backgroundColor: primaryColorDarker,
    borderRadius: 40,
    minWidth: 50,
    minHeight: 50,
    margin: 1,
    marginRight: 5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    fontSize: theme.typography.body1,
    fontWeight: 'bold',
  },
};

export interface ITutorial {
  name: string;
  steps: Step[];
  tutorialLogic(
    data: CallBackProps,
    navigate: NavigateFunction,
    dispatch: Dispatch<any>,
    setTutorialState: (patch: Partial<TutorialState> | ((prevState: TutorialState) => Partial<TutorialState>)) => void,
  ): void;
}

interface PropsFromParent {
  number: number;
  tutorial: ITutorial;
}

export const TutorialListItem: FC<PropsFromParent> = memo(({ tutorial, number }) => {
  const navigate = useNavigate();
  const { setTutorialState } = useTutorial();

  const startTourPressed = useCallback(() => {

    setTutorialState({
      run: true,
      tourActive: true,
      steps: tutorial.steps,
      tutorialLogic: tutorial.tutorialLogic,
    });
  }, [setTutorialState, tutorial.tutorialLogic, tutorial.steps]);

  return (
    <>
      <TableRow>
        <TableCell align="left" padding="none">
          <FlexRow sx={styles.combinedNumberAndTextCell}>
            <Box sx={styles.tutorialNumberBox}>
              <Typography sx={styles.text}>{number}</Typography>
            </Box>
            <Typography sx={styles.text}>{tutorial.name}</Typography>
          </FlexRow>
        </TableCell>
        <TableCell align="right" padding="none">
          <GoToButton onClick={startTourPressed}>{localized('StartTutorial')}</GoToButton>
        </TableCell>
      </TableRow>
    </>
  );
});
