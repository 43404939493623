import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  DeviceClient,
  DeviceConfigClient,
  DeviceConfigDTO,
  DeviceInfoDTO,
  IDeviceConfigDTO
} from '../../../api/api';
import { localized } from '../../../i18n/i18n';
import { AsyncOperationHandler } from '../../../utilities/platform-helpers/api-helper';
import { deviceClient, deviceConfigClient } from '../../api-clients';
import { dispatchAction } from '../../store-container';
import { showErrorSnackbar } from '../snackbar/snackbar-slice';
import { FirmwareUpdateData, GetDeviceByConnectIdParams } from './types';

export const getDevicesFull = createAsyncThunk('devices/getDevicesFull', (organizationId: number) => {
  return AsyncOperationHandler((client) => (client as DeviceClient).getDevicesFull(organizationId), deviceClient);
});

export const getDeviceByConnectId = createAsyncThunk(
  'devices/getDeviceByConnectId',
  async (params: GetDeviceByConnectIdParams) => {
    const { connectId, forSetup } = params;
    const result = await AsyncOperationHandler(
      (client) => (client as DeviceClient).getByConnectId(connectId, forSetup),
      deviceClient,
    );
    if (!result) {
      dispatchAction(showErrorSnackbar(localized('NoDevice')));
    }
    return result;
  },
);

export const updateDeviceFirmware = createAsyncThunk('devices/updateFirmware', (updateData: FirmwareUpdateData) => {
  const { connectId, firmwareVersion } = updateData;
  return AsyncOperationHandler(
    (client) => (client as DeviceClient).updateFirmware(connectId, firmwareVersion),
    deviceClient,
  );
});

export const configureDevice = createAsyncThunk('devices/configureDevice', (device: DeviceInfoDTO) => {
  return AsyncOperationHandler((client) => (client as DeviceClient).configureDevice(device), deviceClient, true);
});

export const removeConfiguration = createAsyncThunk('devices/removeConfiguration', (deviceId: number) => {
  return AsyncOperationHandler((client) => (client as DeviceClient).removeConfiguration(deviceId), deviceClient);
});

export const getDeviceTwinState = createAsyncThunk('devices/getDeviceTwinState', (connectId: string) => {
  return AsyncOperationHandler((client) => (client as DeviceClient).getDeviceTwinState(connectId), deviceClient);
});

export const insertDeviceConfig = createAsyncThunk('device/insertDeviceConfig', (deviceConfig: IDeviceConfigDTO) =>
  AsyncOperationHandler(
    (client) => (client as DeviceConfigClient).insert(deviceConfig as DeviceConfigDTO),
    deviceConfigClient,
    true,
  ),
);

export const updateDeviceConfig = createAsyncThunk('device/updateDeviceConfig', (deviceConfig: IDeviceConfigDTO) =>
  AsyncOperationHandler(
    (client) => (client as DeviceConfigClient).update(deviceConfig as DeviceConfigDTO),
    deviceConfigClient,
    true,
  ),
);
