import { Tooltip, styled, tooltipClasses } from '@mui/material';
import React, { ReactElement, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { INotificationSubscriptionDTO } from '../../../../../../api/api';
import { localized } from '../../../../../../i18n/i18n';
import { selectUserById } from '../../../../../../state/features/users/users-slice';
import { sidebarBackgroundColor, whiteColor } from '../../../../../../styles/color-constants';
const emptyString: string = '';

interface Props {
  notificationSubscription: INotificationSubscriptionDTO;
  toolTipKey: 'sms' | 'email' | 'acute' | 'prolongedUseAlert' | 'overload' | 'impact' | 'report';
  children: ReactElement<any, any>;
  className?: string;
}
export const NotificationToolTip = styled((props: Props) => {
  const { notificationSubscription, toolTipKey: key, children, className } = props;
  const user = useSelector(selectUserById(notificationSubscription.userId));
  const noLocations = useMemo(
    () => notificationSubscription.locationIds?.length === 0,
    [notificationSubscription.locationIds?.length],
  );

  const toolTip = useMemo((): string => {
    if (noLocations) return localized('UserHasNoLocationsAssigned');
    if (
      !notificationSubscription.notifySms &&
      !notificationSubscription.notifyEmail &&
      key !== 'email' &&
      key !== 'sms'
    ) {
      return localized('UserHasNoNotificationTypeAssigned');
    }

    switch (key) {
      case 'sms':
        return !user?.phoneNumber ? localized('UserHasNoPhonenumber') : emptyString;
      case 'email':
        return emptyString;
      case 'acute':
        return localized('ToolTipAcute');
      case 'prolongedUseAlert':
        return localized('ToolTipProlongedUseAlert');
      case 'overload':
        return localized('ToolTipOverload');
      case 'impact':
        return localized('ToolTipImpact');
      case 'report':
        return localized('ToolTipReport');
      default:
        return emptyString;
    }
  }, [key, noLocations, notificationSubscription.notifyEmail, notificationSubscription.notifySms, user?.phoneNumber]);

  return (
    <Tooltip
      arrow
      placement="top"
      classes={{ popper: className }}
      disableInteractive
      title={toolTip}
      children={children}
    />
  );
})(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: sidebarBackgroundColor,
    color: whiteColor,
    boxShadow: theme.shadows[1],
    fontSize: 20,
    padding: 16,
    textAlign: 'center',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: sidebarBackgroundColor,
  },
}));
