import { createSelector, createSlice } from '@reduxjs/toolkit';
import { OrganizationJob } from '../../../api/api';
import { AppState } from '../../store';
import { setOrganizationSetting } from '../settings/settings-slice';
import { deleteFromOrganization, deleteUser, getUserByAuthId, getUsers, saveUser, updateActiveUser, updateUser } from './operation';
import { UsersState } from './types';

const initialState: UsersState = {
  users: [] as any,
  activeUser: undefined,
  pending: false,
  isSuccess: false,
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get
    builder.addCase(getUsers.fulfilled, (state, action) => {
      state.pending = false;
      state.users = action.payload;
    });

    builder.addCase(getUsers.pending, (state) => {
      state.pending = true;
    });

    builder.addCase(getUsers.rejected, (state) => {
      state.pending = false;
    });

    // Get by auth id
    builder.addCase(getUserByAuthId.fulfilled, (state, action) => {
      state.pending = false;
      state.activeUser = action.payload;
    });

    builder.addCase(getUserByAuthId.pending, (state) => {
      state.pending = true;
    });

    builder.addCase(getUserByAuthId.rejected, (state) => {
      state.pending = false;
    });
    // Save
    builder.addCase(saveUser.fulfilled, (state, action) => {
      state.pending = false;
      state.isSuccess = true;
      state.users.push(action.payload);
    });

    builder.addCase(saveUser.pending, (state) => {
      state.isSuccess = false;
      state.pending = true;
    });

    builder.addCase(saveUser.rejected, (state) => {
      state.isSuccess = false;
      state.pending = false;
    });

    // Update
    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.pending = false;
      state.isSuccess = true;
      const index = state.users.findIndex((user) => user.id === action.payload.id);
      if (index > -1) {
        state.users[index] = action.payload;
      }
    });

    builder.addCase(updateUser.pending, (state) => {
      state.pending = true;
      state.isSuccess = false;
    });

    builder.addCase(updateUser.rejected, (state) => {
      state.pending = false;
      state.isSuccess = false;
    });
    
    // Update Active
    builder.addCase(updateActiveUser.fulfilled, (state, action) => {
      state.pending = false;
      state.isSuccess = true;
      state.activeUser = action.payload
    });

    builder.addCase(updateActiveUser.pending, (state) => {
      state.pending = true;
      state.isSuccess = false;
    });

    builder.addCase(updateActiveUser.rejected, (state) => {
      state.pending = false;
      state.isSuccess = false;
    });

    // Delete
    builder.addCase(deleteUser.fulfilled, (state, action) => {
      state.pending = false;
      const index = state.users.findIndex((user) => user.id === action.meta.arg.id);
      if (index > -1) {
        state.users.splice(index, 1);
      }
    });

    builder.addCase(deleteUser.pending, (state) => {
      state.pending = true;
    });

    builder.addCase(deleteUser.rejected, (state) => {
      state.pending = false;
    });

    // Delete organization user
    builder.addCase(deleteFromOrganization.fulfilled, (state, action) => {
      state.pending = false;
      state.isSuccess = true;
      const index = state.users.findIndex((user) => user.id === action.meta.arg.user.id);
      if (index > -1) {
        state.users.splice(index, 1);
      }
    });

    builder.addCase(deleteFromOrganization.pending, (state) => {
      state.pending = true;
      state.isSuccess = false;
    });

    builder.addCase(deleteFromOrganization.rejected, (state) => {
      state.pending = false;
      state.isSuccess = false;
    });

    // Resets when new organization is selected
    builder.addCase(setOrganizationSetting, (state) => {
      state.users = initialState.users;
    });
  },
});

export const selectUsers = (store: AppState) =>
  store.usersReducer.users.slice().sort((a, b) => (a.name && b.name ? a.name.localeCompare(b.name) : 0));

export const selectUserById = (id: number | undefined) =>
  createSelector(selectUsers, (users) => {
    return users.find((user) => user.id === id);
  });

export const selectUserByAuthId = (authId: string) =>
  createSelector(selectUsers, (users) => {
    return users.find((user) => user.authId === authId);
  });

export const selectOrganizationJob = (userId: number | undefined, organizationId: number | undefined) =>
  createSelector(selectUsers, (users) => {
    if (userId === undefined || organizationId === undefined) return OrganizationJob.Other;

    const validUser = users.find((u) => u.id === userId);
    return validUser?.organizationUser?.find((oU) => oU.organizationId === organizationId)?.organizationJob;
  });

export const selectUserNameLookupMap = (store: AppState) => {
  return new Map(store.usersReducer.users.map((u) => [u.id, u.name]));
};
// eslint-disable-next-line
export const {} = usersSlice.actions;

export default usersSlice.reducer;
