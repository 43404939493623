import CircleIcon from '@mui/icons-material/Circle';
import { IconButton, TableCell, TableRow } from '@mui/material';
import React, { FC, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { IDeviceInfoDTO } from '../../../../../api/api';
import { DeleteIcon } from '../../../../../assets/icons/delete-icon/delete-icon';
import { SettingsIconSmall } from '../../../../../assets/icons/settings-icon-small/settings-icon-small';
import { useAuth } from '../../../../../auth/auth-provider';
import { localized, localizedDynamic, localizedInterpolation } from '../../../../../i18n/i18n';
import { removeConfiguration } from '../../../../../state/features/devices/operation';
import { buttonColor, offlineColor, onlineColor, primaryColor } from '../../../../../styles/color-constants';
import { locationToString } from '../../../../../utilities/helpers/location-helper';
import { useDialog } from '../../../../../utilities/providers/dialog-provider';
import { useRightSidebar } from '../../right-side-bar';
import { UnitsSettings } from '../../units/units-settings';
import { tableStyles } from '../table-list';

interface Props {
  device: IDeviceInfoDTO;
  id?: string;
}

export const UnitTableRow: FC<Props> = React.memo((props) => {
  const { device } = props;
  const dispatch = useDispatch();
  const { openDeleteDialog } = useDialog();
  const { isManager } = useAuth();
  const setSidebarContent = useRightSidebar();

  const handleOpenSettings = useCallback(() => {
    setSidebarContent(<UnitsSettings device={device} />);
  }, [device, setSidebarContent]);

  const onDelete = useCallback(() => {
    openDeleteDialog(
      localizedInterpolation('ConfirmDeleteInt', { entityName: localized('Unit').toLocaleLowerCase() }),
      () => {
        dispatch(removeConfiguration(device.id));
      },
    );
  }, [device, dispatch, openDeleteDialog]);

  const locationString = useMemo(() => locationToString(device.locationDTO), [device]);

  return (
    <TableRow >
      <TableCell component="th" scope="row" sx={tableStyles.rowCell}>
        {device.connectId}
      </TableCell>
      <TableCell align="left" sx={tableStyles.rowCell}>
        {localizedDynamic(device?.deviceCategoryNameTranslationKey ?? '')}
      </TableCell>
      <TableCell align="left" sx={tableStyles.rowCell}>
        {device?.deviceModelCode ?? ''}
      </TableCell>
      <TableCell align="left" sx={tableStyles.rowCell}>
        {locationString}
      </TableCell>
      <TableCell align="center">
        <CircleIcon
          htmlColor={device.deviceState?.isConnected ? onlineColor : offlineColor}
          sx={{ fontSize: '28px' }}
        />
      </TableCell>
      <TableCell align="center" sx={tableStyles.rowCell}>
        <IconButton disabled={!isManager} onClick={handleOpenSettings} id={props.id}>
          <SettingsIconSmall color={!isManager ? primaryColor : buttonColor} />
        </IconButton>
      </TableCell>
      <TableCell align="center" padding="none" sx={tableStyles.rowCell}>
        <IconButton onClick={onDelete} disabled={!isManager}>
          <DeleteIcon color={!isManager ? primaryColor : buttonColor} />
        </IconButton>
      </TableCell>
    </TableRow>
  );
});
