import { SvgIcon } from '@mui/material';
import React from 'react';

export function DeleteIcon(props: any) {
  const color = props.color ?? '#F37021';
  return (
    <SvgIcon {...props}>
      <g id="Group_215" data-name="Group 215" transform="translate(-6229 -7866)">
        <path
          id="Path_200"
          data-name="Path 200"
          d="M13.81,35.789H8.926a4.21,4.21,0,0,1-4.2-3.928L4,21.134A1.063,1.063,0,0,1,5.062,20H17.685a1.052,1.052,0,0,1,1.049,1.123l-.723,10.739A4.211,4.211,0,0,1,13.81,35.789Z"
          transform="translate(6229 7852.21)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <line
          id="Line_146"
          data-name="Line 146"
          y1="7.368"
          transform="translate(6238.242 7876.421)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <line
          id="Line_147"
          data-name="Line 147"
          y2="7.368"
          transform="translate(6242.494 7876.421)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <line
          id="Line_148"
          data-name="Line 148"
          x2="15"
          transform="translate(6233 7869)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <line
          id="Line_149"
          data-name="Line 149"
          y1="1"
          transform="translate(6240 7868)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </SvgIcon>
  );
}
