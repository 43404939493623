import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Button, SxProps } from '@mui/material';
import React, { FC } from 'react';
import { theme } from '../../../../styles/theme';

interface PropsFromParent {
  onClick?(): void;
}

const styles: { [key: string]: SxProps } = {
  box: {
    marginRight: 'auto',
  },
  button: {
    textTransform: 'none',
    fontSize: '20px',
    fontWeight: 'normal',
    color: theme.colors.textButton.specialTextColor,
  },
  icon: {
    marginLeft: '10px',
  },
};

export const GoToButton: FC<PropsFromParent> = React.memo((props) => {
  return (
    <Box sx={styles.box}>
      <Button sx={styles.button} disableRipple onClick={props.onClick}>
        {props.children}
        <ArrowForwardIosIcon sx={styles.icon} />
      </Button>
    </Box>
  );
});
