import { SxProps } from '@mui/material';
import React, { FC, memo, useMemo } from 'react';
import { useAuth } from '../../../../auth/auth-provider';
import { localized } from '../../../../i18n/i18n';
import { HeadCell, TableList } from '../table-list/table-list';
import { ITutorial, TutorialListItem } from './tutorial-list-item';
import { addAndManageUsersLogic, addAndManageUsersSteps } from './tutorials.tsx/add-and-manage-users';
import { connectAndAddNewUnitLogic, connectAndAddNewUnitSteps } from './tutorials.tsx/connect-and-add-new-unit';
import { createANewLoactionLogic, createNewLocationSteps } from './tutorials.tsx/create-a-new-location';
import { downloadReportLogic, downloadReportStep } from './tutorials.tsx/download-report';
import { manageNotificationsLogic, manageNotificationsStep } from './tutorials.tsx/manage-notifications';

const headCells: HeadCell[] = [
  {
    id: '1',
    label: '',
    align: 'left',
  },
  {
    id: '2',
    label: '',
    align: 'right',
  },
];

const tutorials: ITutorial[] = [
  {
    name: localized('CreateANewLocation'),
    steps: createNewLocationSteps,
    tutorialLogic: createANewLoactionLogic,
  },
  {
    name: localized('AddAndManageUsers'),
    steps: addAndManageUsersSteps,
    tutorialLogic: addAndManageUsersLogic,
  },
  {
    name: localized('ConnectAndSetupANewUnit'),
    steps: connectAndAddNewUnitSteps,
    tutorialLogic: connectAndAddNewUnitLogic,
  },
  {
    name: localized('ManageNotifications'),
    steps: manageNotificationsStep,
    tutorialLogic: manageNotificationsLogic,
  },
  {
    name: localized('DownloadReport'),
    steps: downloadReportStep,
    tutorialLogic: downloadReportLogic,
  },
];

export const styles: { [key: string]: SxProps } = {
  tableListSpacing: {
    maxWidth: '800px',
    marginBottom: 6,
  },
};

export const TutorialList: FC = memo(() => {
  const { isAdmin } = useAuth();
  const listItems = useMemo(() => {
    const filteredTutorials = isAdmin
      ? tutorials
      : tutorials.filter((t) => t.name !== localized('ConnectAndSetupANewUnit'));

    return filteredTutorials.map((tutorial, i) => <TutorialListItem tutorial={tutorial} number={i + 1} key={i} />);
  }, [isAdmin]);

  return (
    <TableList headCells={headCells} sx={styles.tableListSpacing}>
      {listItems}
    </TableList>
  );
});
