import React, { createContext, memo, useCallback, useContext, useMemo } from 'react';
import Joyride, { CallBackProps, Step, StoreHelpers, TooltipRenderProps } from 'react-joyride';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { useSetState } from 'react-use';
import { buttonColor, secondaryColor, sidebarBackgroundColor } from '../../styles/color-constants';
import { TutorialTooltip } from '../../view/components/specific-components/tutorial/tutorial-tooltip';
import { defaultTutorialState } from '../helpers/tutorial-helper';
import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';

export interface TutorialButtonState {
  showContinue: boolean;
  showBack: boolean;
}

export interface TutorialState {
  run: boolean;
  stepIndex: number;
  steps: Step[];
  tutorialLogic: (
    data: CallBackProps,
    navigate: NavigateFunction,
    dispatch: Dispatch<any>,
    setTutorialState: (patch: Partial<TutorialState> | ((prevState: TutorialState) => Partial<TutorialState>)) => void,
  ) => void;
  tourActive: boolean;
  buttonStatus: TutorialButtonState;
}

const styles = {
  options: {
    backgroundColor: sidebarBackgroundColor,
    arrowColor: sidebarBackgroundColor,
    primaryColor: buttonColor,
    textColor: secondaryColor,
    zIndex: 10000,
  },
};

export const TutorialContext = createContext({
  tutorialState: defaultTutorialState,
  setTutorialState: () => undefined,
});

TutorialContext.displayName = 'TutorialContext';

export const TutorialProvider = memo((props: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [tutorialState, setTutorialState] = useSetState(defaultTutorialState);
  const [storeHelpers, setStoreHelpers] = useSetState<StoreHelpers>();

  const handleJoyrideCallback = useCallback((data: Joyride.CallBackProps) => {
    tutorialState.tutorialLogic(data, navigate, dispatch, setTutorialState);
  },[dispatch, navigate, setTutorialState, tutorialState]);

  const value = useMemo(
    () => ({
      tutorialState: tutorialState,
      setTutorialState,
    }),
    [setTutorialState, tutorialState],
  );

  const tooltip = useCallback(
    (props: TooltipRenderProps) => {
      return TutorialTooltip(props, tutorialState, storeHelpers, navigate);
    },
    [navigate, storeHelpers, tutorialState],
  );

  return (
    <>
      <Joyride
        callback={handleJoyrideCallback}
        stepIndex={tutorialState.stepIndex}
        run={tutorialState.run}
        steps={tutorialState.steps}
        styles={styles}
        disableCloseOnEsc={true}
        continuous
        tooltipComponent={tooltip}
        hideClose={true}
        getHelpers={setStoreHelpers}
      />
      <TutorialContext.Provider value={value} {...props}>
        {props.children}
      </TutorialContext.Provider>
    </>
  );
});

export function useTutorial(): {
  setTutorialState: (
    patch: Partial<TutorialState> | ((previousState: TutorialState) => Partial<TutorialState>),
  ) => void;
  tutorialState: TutorialState;
} {
  const context = useContext(TutorialContext);

  if (!context) {
    throw new Error('useTutorialContext must be used within a TutorialProvider');
  }

  return context;
}
