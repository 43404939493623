import { SxProps } from '@mui/material';
import React, { FC, useEffect } from 'react';
import { OrganizationJob, OrganizationRole } from '../../../../api/api';
import { localized } from '../../../../i18n/i18n';
import { AddAndManageUsersStepTargets } from '../../../../utilities/enums/tutorial-steps-target';
import {
  basicPhoneNumberValidator,
  emailValidator,
  requiredValidator,
} from '../../../../utilities/platform-helpers/validator-helper';
import { useTutorial } from '../../../../utilities/providers/tutorial-provider';
import { FlexColumn } from '../../default-components/flex-column';
import { LargeTextInput } from '../form/large-text-input';
import { JobSelect } from './job-select';

interface Props {
  role: OrganizationRole | undefined;
  job: OrganizationJob;
  setJob: (job: React.SetStateAction<OrganizationJob>) => void;
  createMode?: boolean;
}

const styles: { [key: string]: SxProps } = {
  textField: {
    marginTop: '30px',
  },
};

export const UserFormFields: FC<Props> = React.memo((props) => {
  const {
    setTutorialState,
    tutorialState: { tourActive },
  } = useTutorial();

  useEffect(() => {
    if (!tourActive) return;
    setTimeout(() => {
      setTutorialState({ stepIndex: 3 });
    }, 400);
  }, [setTutorialState, tourActive]);

  return (
    <>
      <FlexColumn id={AddAndManageUsersStepTargets.Step4}>
        <LargeTextInput label={localized('Name')} name="name" sx={styles.textField} validators={requiredValidator} />
        {props.createMode && (
          <LargeTextInput
            label={localized('Email')}
            name="email"
            sx={styles.textField}
            validators={
              props.role === OrganizationRole.Maintenance ? emailValidator : { ...requiredValidator, ...emailValidator }
            }
          />
        )}
      </FlexColumn>
      <FlexColumn id={AddAndManageUsersStepTargets.Step5}>
        <LargeTextInput
          label={localized('PhoneNr')}
          name="phoneNumber"
          sx={styles.textField}
          validators={basicPhoneNumberValidator}
        />
      </FlexColumn>
      <JobSelect job={props.job} setJob={props.setJob} />
    </>
  );
});
