import CheckIcon from '@mui/icons-material/Check';
import SquareIcon from '@mui/icons-material/Square';
import { Checkbox, SxProps } from '@mui/material';
import React, { FC, useCallback, useMemo } from 'react';
import { disabledColor } from '../../../styles/color-constants';
import { theme } from '../../../styles/theme';

interface Props {
  checked?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  sx?: SxProps;
  disabled?: boolean;
  name?: string;
  border?: boolean;
}

export const StyledCheckbox: FC<Props> = React.memo((props) => {
  const { onChange } = props;
  const backgroundColor = theme.colors.checkBox.backgroundColor;

  const localOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(e);
      }
    },
    [onChange],
  );

  const checkboxStyle = useMemo(
    () => ({
      fontSize: '30px',
      backgroundColor: backgroundColor,
      border: props.border ? `2px solid ${props.disabled ? disabledColor : theme.colors.checkBox.boarderColor}` : '',
      marginTop: '5px',
      marginBottom: '5px',
    }),
    [backgroundColor, props.border, props.disabled],
  );

  return (
    <Checkbox
      name={props.name}
      sx={props.sx}
      disableRipple
      disabled={props.disabled}
      icon={<SquareIcon htmlColor={backgroundColor} sx={checkboxStyle} />}
      checkedIcon={<CheckIcon htmlColor={theme.colors.checkBox.checkmarkColor} sx={checkboxStyle} />}
      checked={props.checked}
      onChange={localOnChange}
    />
  );
});
