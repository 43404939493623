import {createAsyncThunk} from '@reduxjs/toolkit';
import {IUser, User, UserClient} from '../../../api/api';
import {AsyncOperationHandler} from '../../../utilities/platform-helpers/api-helper';
import {userClient} from '../../api-clients';
import {DeleteUserData} from './types';

export const getUsers = createAsyncThunk('users/getUsers', (organizationId: number) => {
  return AsyncOperationHandler((client) => (client as UserClient).getByOrganizationId(organizationId), userClient);
});

export const getUserById = createAsyncThunk('users/getUserById', (userId: number) => {
  return AsyncOperationHandler((client) => (client as UserClient).getById(userId), userClient);
});

export const getUserByAuthId = createAsyncThunk('users/getUserByAuthId', () => {
  return AsyncOperationHandler((client) => (client as UserClient).getByAuthId(), userClient);
});

export const saveUser = createAsyncThunk('users/saveUser', (user: IUser) => {
  return AsyncOperationHandler((client) => (client as UserClient).post(user as User), userClient, true);
});

export const updateUser = createAsyncThunk('users/updateUser', (user: IUser) => {
  return AsyncOperationHandler((client) => (client as UserClient).put(user as User), userClient, true);
});

export const updateActiveUser = createAsyncThunk('users/updateActiveUser', (user: IUser) => {
  return AsyncOperationHandler((client) => (client as UserClient).put(user as User), userClient, true);
});

export const deleteUser = createAsyncThunk('users/deleteUser', (user: IUser) => {
  return AsyncOperationHandler((client) => (client as UserClient).put(user as User), userClient);
});

export const deleteFromOrganization = createAsyncThunk(
  'users/deleteFromOrganization',
  (deleteUserData: DeleteUserData) => {
    const {user, organizationId} = deleteUserData;
    return AsyncOperationHandler(
      (client) => (client as UserClient).deleteFromOrganization(user as User, organizationId),
      userClient,
    );
  },
);
