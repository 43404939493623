import {createSlice} from '@reduxjs/toolkit';
import {AppState} from '../../store';
import { setOrganizationSetting } from '../settings/settings-slice';
import {getDeviceModelsByCategory} from './operation';
import {DeviceModelsState} from './types';

const initialState: DeviceModelsState = {
  deviceModels: [] as any,
  pending: false,
};

export const deviceModelsSlice = createSlice({
  name: 'deviceModels',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //GetByType
    builder.addCase(getDeviceModelsByCategory.fulfilled, (state, action) => {
      state.pending = false;
      state.deviceModels = action.payload;
    });

    builder.addCase(getDeviceModelsByCategory.pending, (state, _) => {
      state.pending = true;
    });

    builder.addCase(getDeviceModelsByCategory.rejected, (state, _) => {
      state.pending = false;
    });

    // Resets when new organization is selected
    builder.addCase(setOrganizationSetting, (state) => {
      state.deviceModels = initialState.deviceModels;
    });
  },
});

export const selectDeviceModels = (store: AppState) => store.deviceModelsReducer.deviceModels;

// eslint-disable-next-line
export const {} = deviceModelsSlice.actions;

export default deviceModelsSlice.reducer;
