import { IconButton, TableCell, TableRow } from '@mui/material';
import React, { FC, memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { ILocationDTO } from '../../../../api/api';
import { DeleteIcon } from '../../../../assets/icons/delete-icon/delete-icon';
import { SettingsIconSmall } from '../../../../assets/icons/settings-icon-small/settings-icon-small';
import { useAuth } from '../../../../auth/auth-provider';
import { localized, localizedInterpolation } from '../../../../i18n/i18n';
import { deleteLocation } from '../../../../state/features/locations/operations';
import { buttonColor, primaryColor } from '../../../../styles/color-constants';
import { locationToString } from '../../../../utilities/helpers/location-helper';
import { useDialog } from '../../../../utilities/providers/dialog-provider';
import { useRightSidebar } from '../../../components/specific-components/right-side-bar';
import { tableStyles } from '../../../components/specific-components/table-list/table-list';
import { CreateLocation } from '../create-location/create-location';

interface PropsFromParent {
  id?: string;
  location: ILocationDTO;
}

export const LocationsListItem: FC<PropsFromParent> = memo(({ location, id }) => {
  const { isAdmin, authId } = useAuth();
  const dispatch = useDispatch();
  const { openDeleteDialog } = useDialog();
  const setSidebarContent = useRightSidebar();

  const onEdit = useCallback(() => {
    setSidebarContent(<CreateLocation existingLocation={location} />);
  }, [location, setSidebarContent]);
  const onDelete = useCallback(() => {
    openDeleteDialog(
      localizedInterpolation('ConfirmDeleteInt', { entityName: localized('Location').toLocaleLowerCase() }),
      () => {
        dispatch(deleteLocation(location));
      },
    );
  }, [dispatch, location, openDeleteDialog]);

  return (
    <TableRow id={id}>
      <TableCell component="th" scope="row" sx={tableStyles.rowCell}>
        {locationToString(location)}
      </TableCell>
      <TableCell align="center" padding="none" id={id+'-edit'}>
        <IconButton onClick={onEdit} disabled={!isAdmin}>
          <SettingsIconSmall color={!isAdmin ? primaryColor : buttonColor} />
        </IconButton>
      </TableCell>
      <TableCell align="center" padding="none">
        <IconButton onClick={onDelete} disabled={!isAdmin}>
          <DeleteIcon color={!isAdmin ? primaryColor : buttonColor} />
        </IconButton>
      </TableCell>
    </TableRow>
  );
});
