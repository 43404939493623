import { AdminDeviceState } from './types';
import { createSlice, isAnyOf, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import {
  getDataDumpData,
  getDeviceFirmwareVersion,
  getFirmwareUpdateTimestamp,
  getNewOrEqualFirmwaresForDevice,
  requestDataDump,
} from './operation';
import { AppState } from '../../store';
import dayjs from 'dayjs';

const initialState: AdminDeviceState = {
  dataDumpData: undefined,
  firmwareTimestamp: undefined,
  pending: false,
  currentFirmware: '',
  requestedFirmware: '',
  availableFirmwares: [],
};

export const adminDeviceSlice = createSlice({
  name: 'adminDevice',
  initialState,
  reducers: {
    resetDataDump: (state) => {
      state.dataDumpData = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDataDumpData.fulfilled, (state, action) => {
      state.dataDumpData = action.payload;
    });

    builder.addCase(getFirmwareUpdateTimestamp.fulfilled, (state, action) => {
      state.firmwareTimestamp = !action.payload ? 'Never' : dayjs(action.payload).format('DD.MM.YYYY - HH:mm:ss');
    });

    builder.addCase(getDeviceFirmwareVersion.fulfilled, (state, action) => {
      const firmware = action.payload;
      if (firmware.installedFirmwareVersion) {
        state.currentFirmware = firmware.installedFirmwareVersion;
      }

      if (
        firmware.requestedFirmwareVersion &&
        firmware.installedFirmwareVersion !== firmware.requestedFirmwareVersion
      ) {
        state.requestedFirmware = firmware.requestedFirmwareVersion;
      } else {
        state.requestedFirmware = '';
      }
    });

    builder.addCase(getNewOrEqualFirmwaresForDevice.fulfilled, (state, action) => {
      state.availableFirmwares = action.payload;
    });

    builder.addMatcher(
      isAnyOf(
        isRejected(
          requestDataDump,
          getDataDumpData,
          getDeviceFirmwareVersion,
          getFirmwareUpdateTimestamp,
          getNewOrEqualFirmwaresForDevice,
        ),
        isFulfilled(
          requestDataDump,
          getDataDumpData,
          getDeviceFirmwareVersion,
          getFirmwareUpdateTimestamp,
          getNewOrEqualFirmwaresForDevice,
        ),
      ),
      (state) => {
        state.pending = false;
      },
    );
    builder.addMatcher(
      isPending(
        requestDataDump,
        getDataDumpData,
        getDeviceFirmwareVersion,
        getFirmwareUpdateTimestamp,
        getNewOrEqualFirmwaresForDevice,
      ),
      (state) => {
        state.pending = true;
      },
    );
  },
});

export const selectDataDumpData = (state: AppState) => state.adminDeviceReducer.dataDumpData;
export const selectFirmwareUpdateTimestamp = (state: AppState) => state.adminDeviceReducer.firmwareTimestamp;
export const selectRequestedFirmware = (state: AppState) => state.adminDeviceReducer.requestedFirmware;
export const selectCurrentFirmware = (state: AppState) => state.adminDeviceReducer.currentFirmware;
export const selectAvailableFirmwares = (state: AppState) => state.adminDeviceReducer.availableFirmwares;

export const { resetDataDump } = adminDeviceSlice.actions;

export default adminDeviceSlice.reducer;
