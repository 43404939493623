import { Box, SxProps, Typography } from '@mui/material';
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { localized } from '../../../i18n/i18n';
import { getLocationsByOrganizationId } from '../../../state/features/locations/operations';
import { selectOrganization } from '../../../state/features/settings/settings-slice';
import { AppState } from '../../../state/store';
import { sideBarTextFieldBackgroundColor } from '../../../styles/color-constants';
import { theme } from '../../../styles/theme';
import { useTutorial } from '../../../utilities/providers/tutorial-provider';
import { PageHeader } from '../../components/default-components/page-header.tsx/page-header';
import { PageTitle } from '../../components/default-components/page-title/page-title';
import { BasicSpinner } from '../../components/default-components/spinner/basic-spinner';
import { MainPageContainer } from '../../components/specific-components/containers/main-page-container';
import { RightSideBar } from '../../components/specific-components/right-side-bar';
import { LocationsList } from './locations-list/locations-list';

export const styles: { [key: string]: SxProps } = {
  searchField: {
    '& .MuiInput-input': {
      fontSize: '20px',
    },
  },
  textField: {
    backgroundColor: sideBarTextFieldBackgroundColor,
    '& .MuiInput-input': {
      fontSize: '20px',
    },
    marginTop: '44px',
  },
  textFieldNoTop: {
    backgroundColor: sideBarTextFieldBackgroundColor,
    '& .MuiInput-input': {
      fontSize: '20px',
    },
  },
  metaText: {
    fontSize: theme.typography.body1,
    marginBottom: 1,
  },
};

export const Locations: FC = React.memo(() => {
  const selectedOrganization = useSelector(selectOrganization);
  const dispatch = useDispatch();
  const {
    setTutorialState,
    tutorialState: { tourActive },
  } = useTutorial();
  const pending = useSelector(
    (store: AppState) => store.locationsReducer.pending && store.locationsReducer.locations.length < 1,
  );

  useEffect(() => {
    if (!tourActive) return;
    setTimeout(() => {
      setTutorialState({ stepIndex: 1 });
    }, 300);
  }, [setTutorialState, tourActive]);

  useEffect(() => {
    if (selectedOrganization) {
      dispatch(getLocationsByOrganizationId(selectedOrganization.id));
    }
  }, [dispatch, selectedOrganization]);

  return (
    <RightSideBar>
      <PageTitle title={localized('Locations')}>
        <PageHeader area="locations" title={localized('Locations')}></PageHeader>
        <MainPageContainer>
          <Typography sx={styles.metaText} whiteSpace={'pre-line'}>
            {localized('MetaTextLocations')}
          </Typography>
          {pending ? (
            <Box justifyContent={'flex-start'} display={'flex'}>
              <BasicSpinner size={'50px'} />
            </Box>
          ) : (
            <LocationsList />
          )}
        </MainPageContainer>
      </PageTitle>
    </RightSideBar>
  );
});
