import { SxProps, useMediaQuery } from '@mui/material';
import React, { FC } from 'react';
import { FlexColumn } from '../../default-components/flex-column';
import { PageContentContainer } from '../../default-components/page-container/page-content-container';

const styles: { [key: string]: SxProps } = {
  containerFull: {
    display: 'flex',
    ml: '104px',
    mr: '104px',
  },
  containerSmall: {
    display: 'flex',
    ml: '52px',
    mr: '52px',
  },
};

export const MainPageContainer: FC = React.memo((props) => {
  const wide = useMediaQuery('(min-width:1500px)');
  return (
    <PageContentContainer sx={wide ? styles.containerFull : styles.containerSmall}>
      <FlexColumn marginTop={'6px'} width={'100%'}>
        {props.children}
      </FlexColumn>
    </PageContentContainer>
  );
});
