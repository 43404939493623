import { Box, SxProps, Typography } from '@mui/material';
import React, { FC, memo, useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { ILocationDTO } from '../../../../api/api';
import { localized, localizedInterpolation } from '../../../../i18n/i18n';
import { AppState } from '../../../../state/store';
import { CreateANewLocationStepTargets } from '../../../../utilities/enums/tutorial-steps-target';
import { useGoogleMaps } from '../../../../utilities/google-maps-provider';
import { PlaceResult } from '../../../../utilities/google-types';
import { nameof } from '../../../../utilities/platform-helpers/nameof-helper';
import { requiredValidator } from '../../../../utilities/platform-helpers/validator-helper';
import { useTutorial } from '../../../../utilities/providers/tutorial-provider';
import { FlexRow } from '../../../components/default-components/flex-row';
import { MapsAutocompleteInput } from '../../../components/default-components/form/maps-autocomplete-input';
import { TextInput } from '../../../components/default-components/form/text-input';
import { StyledButton } from '../../../components/specific-components/buttons/styled-button';
import { SidebarCancelButton } from '../../../components/specific-components/sidebar-cancel-button';

const localStyles: { [key: string]: SxProps } = {
  inputLine: {
    marginTop: 1,
    marginBottom: 1,
  },
};

interface PropsFromParent {
  existingLocation?: ILocationDTO;
}

export const LocationForm: FC<PropsFromParent> = memo(({ existingLocation }) => {
  const {
    setTutorialState,
    tutorialState: { tourActive },
  } = useTutorial();
  const { placesService } = useGoogleMaps();
  const { pending } = useSelector((store: AppState) => store.locationsReducer);
  const { watch, setValue, reset } = useFormContext();
  const streetPlaceId = watch('street_placeId');

  useEffect(() => {
    if (!tourActive) return;
    setTimeout(() => {
      setTutorialState({ stepIndex: 2 });
    }, 400);
  }, [setTutorialState, tourActive]);

  useEffect(() => {
    reset(existingLocation);
  }, [existingLocation, reset]);

  const handlePlaceDetails = useCallback(
    (details: PlaceResult) => {
      details.address_components?.forEach((component) => {
        if (component.types.includes('postal_code')) {
          // Zip
          setValue(nameof<ILocationDTO>('zipCode'), component.short_name);
        }
        if (component.types.includes('sublocality')) {
          // City
          setValue(nameof<ILocationDTO>('city'), component.short_name);
        }
        if (component.types.includes('country')) {
          // Country
          setValue(nameof<ILocationDTO>('country'), component.long_name);
        }
      });
    },
    [setValue],
  );

  useEffect(() => {
    if (!streetPlaceId) return;

    placesService.current?.getDetails(
      { placeId: streetPlaceId, fields: ['address_components'] }, // Important to limit the fields to the only one we need.
      (result: PlaceResult | null) => {
        if (result) {
          handlePlaceDetails(result);
        }
      },
    );
  }, [streetPlaceId, placesService, handlePlaceDetails]);

  return (
    <>
      <h1>
        {localizedInterpolation(existingLocation ? 'EditInt' : 'AddNewInt', {
          area: localized('Location').toLocaleLowerCase(),
        })}
      </h1>
      <Box id={CreateANewLocationStepTargets.Step3}>
        <Box sx={localStyles.inputLine}>
          <Box sx={localStyles.inputLine}>
            <MapsAutocompleteInput
              name={nameof<ILocationDTO>('street')}
              placeHolder="Street & house number"
              searchType="address"
              validators={requiredValidator}
              defaultValue={existingLocation?.street}
            />
          </Box>
          <FlexRow>
            <Box sx={{ maxWidth: 180 }}>
              <TextInput
                name={nameof<ILocationDTO>('zipCode')}
                placeholder={localized('Zip') + ' *'}
                validators={requiredValidator}
              />
            </Box>
            <Box sx={{ marginLeft: 2, width: '100%' }}>
              <TextInput
                name={nameof<ILocationDTO>('city')}
                placeholder={localized('City') + ' *'}
                validators={requiredValidator}
              />
            </Box>
          </FlexRow>
        </Box>
        <Box sx={localStyles.inputLine}>
          <TextInput
            name={nameof<ILocationDTO>('country')}
            placeholder={localized('Country') + ' *'}
            validators={requiredValidator}
          />
        </Box>
      </Box>
      <Box id={CreateANewLocationStepTargets.Step4}>
        <Typography sx={{ marginTop: 2 }}>{localized('MetaTextAddFloor')}</Typography>
        <Box sx={localStyles.inputLine}>
          <TextInput
            name={nameof<ILocationDTO>('floor')}
            placeholder={localized('Floor') + ' *'}
            validators={requiredValidator}
          />
        </Box>
        <Box sx={localStyles.inputLine}>
          <TextInput
            name={nameof<ILocationDTO>('room')}
            placeholder={localized('Room') + ' *'}
            validators={requiredValidator}
          />
        </Box>
      </Box>
      <StyledButton type="submit" bottomStuck disabled={pending} id={CreateANewLocationStepTargets.Step5}>
        {localizedInterpolation('SaveInt', { area: localized('Location').toLocaleLowerCase() })}
      </StyledButton>
      <SidebarCancelButton />
    </>
  );
});
