import React, { FC, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { localized } from '../../../../i18n/i18n';
import { selectLocations } from '../../../../state/features/locations/locations-slice';
import { getLocationsByOrganizationId } from '../../../../state/features/locations/operations';
import { selectOrganization } from '../../../../state/features/settings/settings-slice';
import { AppState } from '../../../../state/store';
import { useTutorial } from '../../../../utilities/providers/tutorial-provider';
import { useRightSidebar } from '../../../components/specific-components/right-side-bar';
import { ByLocationTableRow } from '../../../components/specific-components/table-list/notifications/for-locations/by-location-table-row';
import { HeadCell, TableList } from '../../../components/specific-components/table-list/table-list';
import { notificationTableMaxHeight } from '../notifications';

const headCells: HeadCell[] = [
  {
    id: 'room',
    label: localized('Room'),
    align: 'left',
  },
  {
    id: 'floor',
    label: localized('Floor'),
    align: 'left',
  },
  {
    id: 'address',
    label: localized('Address'),
    align: 'left',
  },
  {
    id: 'zip',
    label: localized('Zip&City'),
    align: 'left',
  },
  {
    id: 'country',
    label: localized('Country'),
    align: 'left',
  },
  {
    id: 'users',
    label: localized('Users'),
    align: 'left',
    width: '60px',
  },
];

export const NotificationsLocationsRooms: FC = React.memo(() => {
  const dispatch = useDispatch();
  const {
    setTutorialState,
    tutorialState: { tourActive },
  } = useTutorial();
  const setSidebarContent = useRightSidebar();
  const selectedOrganization = useSelector(selectOrganization);
  const locations = useSelector(selectLocations);
  const showSpinner = useSelector(
    (store: AppState) => store.locationsReducer.pending && store.locationsReducer.locations.length < 1,
  );

  useEffect(() => {
    if (!tourActive) return;
    setTimeout(() => {
      setTutorialState({
        stepIndex: locations.length > 0 ? 12 : 15,
        buttonStatus: {
          showBack: false,
          showContinue: locations.length > 0 ? false : true,
        },
      });
    }, 300);
  }, [setTutorialState, locations.length, tourActive]);

  useEffect(() => {
    // Call if notifications changed
    if (selectedOrganization?.id) {
      dispatch(getLocationsByOrganizationId(selectedOrganization.id));
    }

    return () => {
      setSidebarContent(null);
    };
  }, [setSidebarContent, dispatch, selectedOrganization]);

  const sortedRooms = useMemo(() => {
    return locations.slice().sort((a, b) => {
      const locationInfoA = a;
      const locationInfoB = b;
      return (
        locationInfoA?.country?.localeCompare(locationInfoB?.country ?? '', 'en', { numeric: true }) ||
        locationInfoA?.city?.localeCompare(locationInfoB?.city ?? '', 'en', { numeric: true }) ||
        locationInfoA?.street?.localeCompare(locationInfoB?.street ?? '', 'en', { numeric: true }) ||
        locationInfoA?.floor?.localeCompare(locationInfoB?.floor ?? '', 'en', { numeric: true }) ||
        locationInfoA?.room?.localeCompare(locationInfoB?.room ?? '', 'en', { numeric: true }) ||
        1
      );
    });
  }, [locations]);

  return (
    <TableList headCells={headCells} showSpinner={showSpinner} maxHeight={notificationTableMaxHeight}>
      {sortedRooms.map((location, i) => (
        <ByLocationTableRow location={location} key={location.id} firstInTable={i === 0} />
      ))}
    </TableList>
  );
});
