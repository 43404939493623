import React, { FC } from 'react';
import { AdminIconButtons } from './admin-icon-buttons';
import { FirmwareUpdater } from '../firmware-updater';
import { IDeviceInfoDTO } from '../../../../../../api/api';
import { EnableDebuggingButtons } from '../../debug-logging/enable-debugging-buttons';
import { DataDumpDownload } from '../../debug-logging/data-dump-download';
import { useSelector } from 'react-redux';
import { selectDataDumpData } from '../../../../../../state/features/admin-device/admin-device-slice';
import { SideBarStack } from '../../../side-bar-stack';

interface Props {
  device: IDeviceInfoDTO;
}

export const AdminUnitSettings: FC<Props> = React.memo(({ device }) => {
  const dataDumpData = useSelector(selectDataDumpData);

  return (
    <>
      <SideBarStack>
        <FirmwareUpdater connectId={device.connectId} />
        <EnableDebuggingButtons connectId={device.connectId} />
        <AdminIconButtons device={device} />
      </SideBarStack>
      {dataDumpData !== undefined && <DataDumpDownload dataDumpData={dataDumpData} />}
    </>
  );
});
