import {createSelector, createSlice} from '@reduxjs/toolkit';
import {DropdownOption} from '../../../view/components/default-components/basic-dropdown/basic-search-dropdown';
import {AppState} from '../../store';
import {deleteOrganization, getOrganizations, saveOrganization, updateOrganization} from './operation';
import {OrganizationState} from './types';

const initialState: OrganizationState = {
  organizations: [] as any,
  pending: false,
  isSuccess: false,
};

export const organizationsSlice = createSlice({
  name: 'organizations',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get
    builder.addCase(getOrganizations.fulfilled, (state, action) => {
      state.pending = false;
      state.organizations = action.payload;
    });

    builder.addCase(getOrganizations.pending, (state, action) => {
      state.pending = true;
    });

    builder.addCase(getOrganizations.rejected, (state, action) => {
      state.pending = false;
    });

    // Save
    builder.addCase(saveOrganization.fulfilled, (state, action) => {
      state.pending = true;
      state.isSuccess = true;
      state.organizations.push(action.payload);
    });

    builder.addCase(saveOrganization.pending, (state, action) => {
      state.pending = true;
      state.isSuccess = false;
    });

    builder.addCase(saveOrganization.rejected, (state, action) => {
      state.pending = false;
      state.isSuccess = false;
    });

    // Update
    builder.addCase(updateOrganization.fulfilled, (state, action) => {
      state.pending = true;
      state.isSuccess = true;
      const index = state.organizations.findIndex((org) => org.id === action.payload.id);
      if (index > -1) {
        state.organizations[index] = action.payload;
      }
    });

    builder.addCase(updateOrganization.pending, (state, action) => {
      state.pending = true;
      state.isSuccess = false;
    });

    builder.addCase(updateOrganization.rejected, (state, action) => {
      state.pending = false;
      state.isSuccess = false;
    });

    // Delete
    builder.addCase(deleteOrganization.fulfilled, (state, action) => {
      state.pending = false;
      const index = state.organizations.findIndex((org) => org.id === action.meta.arg.id);
      if (index > -1) {
        state.organizations.splice(index, 1);
      }
    });

    builder.addCase(deleteOrganization.pending, (state, action) => {
      state.pending = true;
    });

    builder.addCase(deleteOrganization.rejected, (state, action) => {
      state.pending = false;
    });
  },
});

export const selectOrganisations = (store: AppState) => store.organizationReducer.organizations;

export const selectOrganisationById = (id: number | undefined) =>
  createSelector(selectOrganisations, (organizations) => {
    return organizations.find((org) => org.id === id);
  });

export const selectOrganiszationDropdownOptions = createSelector(selectOrganisations, (organizations) => {
  return organizations.map((org) => new DropdownOption(org.name!, org.id));
});

// eslint-disable-next-line
export const {} = organizationsSlice.actions;

export default organizationsSlice.reducer;
