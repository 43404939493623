import CheckIcon from '@mui/icons-material/Check';
import RemoveIcon from '@mui/icons-material/Remove';
import SquareIcon from '@mui/icons-material/Square';
import { Checkbox, SxProps, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { FC, useCallback } from 'react';
import { buttonColor } from '../../../../styles/color-constants';
import { BasicSpinner } from '../../default-components/spinner/basic-spinner';

export interface HeadCell {
  id: string;
  label: string;
  align: 'left' | 'right' | 'inherit' | 'center' | 'justify' | undefined;
  width?: string;
  span?: number;
  borderRight?: boolean;
}

interface Props {
  headCells: HeadCell[];
  entities?: string[];
  checked?: string[];
  setChecked?: (newChecked: React.SetStateAction<string[]>) => void;
  sx?: SxProps;
  maxHeight?: string;
  showSpinner?: boolean;
}

export const tableStyles: { [key: string]: SxProps } = {
  table: {
    width: '100%',
  },
  checkbox: {
    fontSize: '30px',
  },
  headCell: {
    fontSize: '12px',
  },
  rowCell: {
    fontSize: '20px',
  },
};

export const TableList: FC<Props> = React.memo((props) => {
  const { setChecked, entities } = props;

  const localSetChecked = useCallback(
    (newChecked: React.SetStateAction<string[]>) => {
      if (setChecked) setChecked(newChecked);
    },
    [setChecked],
  );

  const handleSelectAll = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        localSetChecked(entities ?? []);
        return;
      }
      localSetChecked([]);
    },
    [entities, localSetChecked],
  );

  return (
    <>
      <TableContainer sx={{ ...props.sx, overflow: 'unset' }}>
        <Table sx={tableStyles.table} stickyHeader>
          <TableHead>
            <TableRow>
              {props.checked ? (
                <TableCell padding="checkbox">
                  <Checkbox
                    disableRipple
                    icon={<SquareIcon htmlColor="white" sx={tableStyles.checkbox} />}
                    checkedIcon={<CheckIcon htmlColor={buttonColor} sx={tableStyles.checkbox} />}
                    indeterminateIcon={<RemoveIcon htmlColor={buttonColor} sx={tableStyles.checkbox} />}
                    tabIndex={-1}
                    checked={props.checked.length === props?.entities?.length}
                    indeterminate={
                      props.checked.length > 0 && props.entities && props.checked.length < props.entities.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
              ) : (
                <></>
              )}
              {props.headCells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  align={headCell.align}
                  sx={{
                    ...tableStyles.headCell,
                    width: headCell.width,
                    borderRight: headCell.borderRight ? '1px solid #E0E0E0' : '',
                  }}
                  colSpan={headCell.span}>
                  {headCell.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{props.children}</TableBody>
        </Table>
      </TableContainer>
      {props.showSpinner ? <BasicSpinner color={buttonColor} /> : null}
    </>
  );
});
